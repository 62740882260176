import React from 'react';

const EmptyComp = ({ title }) => {
 return (
  <div className="heading-bx text-center">
   <h3 className="title">{title}</h3>
  </div>
 );

}

export default EmptyComp;
