export const POST_DOCTORS = 'POST_DOCTORS'
export const POST_DOCTORS_SUCCESS = 'POST_DOCTORS_SUCCESS'

export const postDoctors = (data) => ({
 type: POST_DOCTORS,
 value: data
})




