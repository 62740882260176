export const GET_SELF_ASSESSMENT_QUESTIONS = 'GET_SELF_ASSESSMENT_QUESTIONS'
export const GET_SELF_ASSESSMENT_QUESTIONS_SUCCESS = 'GET_SELF_ASSESSMENT_QUESTIONS_SUCCESS'
export const ANSWER_SELF_ASSESSMENT_QUESTIONS = 'ANSWER_SELF_ASSESSMENT_QUESTIONS'
export const ANSWER_SELF_ASSESSMENT_QUESTIONS_SUCCESS = 'ANSWER_SELF_ASSESSMENT_QUESTIONS_SUCCESS'

export const getQuestions = (behalfOn) => ({
 type: GET_SELF_ASSESSMENT_QUESTIONS,
 value: behalfOn
})


export const answerQuestion = (data) => ({
 type: ANSWER_SELF_ASSESSMENT_QUESTIONS,
 value: data
})
