import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";


const ServiceDetail = () => {
	const [hospital, setHospital] = useState(null)
	const dataTitles = [
		{ name: 'ACCROSSAID', _id: 0 },
		{ name: 'ADEPR MUSANZE', _id: 2 },
		{ name: 'AEE RWANDA', _id: 3 },
		{ name: 'AEGIS TRUST', _id: 4 },
		{ name: 'AERG', _id: 5 },
		{ name: 'African Humanitarian Action', _id: 6 },
		{ name: 'AGAHOZO-Shalom Youth Village', _id: 7 },
		{ name: 'AGHR (ASSOCIATION GENERALE DES HANDICAPES DU RWANDA - AGHR)', _id: 8 },
		{ name: 'AIMR- IHORERE MUNYARWANDA', _id: 9 },
		{ name: 'ALIGHT (ARC)', _id: 10 },
		{ name: 'AMEGERWA', _id: 11 },
		{ name: 'ANTI DRUG ABUSE ORGANIZATION', _id: 12 },
		{ name: 'ARCT-RUHUKA', _id: 13 },
		{ name: 'ASOFERWA (ASSOCIATION DE SOLIDARITE DES FEMME)', _id: 13 },
		{ name: 'ASPIRE RWANDA', _id: 14 },
		{ name: 'ASSOCIATION GARUKA', _id: 15 },
		{ name: 'ASSOCIATION MABAWA', _id: 16 },
		{ name: 'ASSOCIATION MODESTE ET INNOCENT (AMI)', _id: 17 },
		{ name: 'AVEGA AGAHOZO', _id: 18 },
		{ name: 'CARITAS  DIOCESAINE DE BYUMBA', _id: 19 },
		{ name: 'CARITAS RWANDA', _id: 20 },
		{ name: "CENTRE INSHUTI ZACU (Soeurs Inshuti z'Abakene) ", _id: 21 },
		{ name: 'CENTRE MAREMBO', _id: 22 },
		{ name: 'Children and Youth Sports Organisation', _id: 23 },
		{ name: 'CLINICAL PSYCHOLOGY STUDENTS ASSOCIATION', _id: 24 },
		{ name: 'COMMISSION EPISCOPALE JUSTICE & PAIX', _id: 25 },
		{ name: 'COMMUNITY BASED  SOCIOTHERAPY', _id: 26 },
		{ name: 'COMPASSION INTERNATIONALE RWANDA', _id: 27 },
		{ name: 'FAITH VICTORY ASSOCIATION', _id: 28 },
		{ name: 'FAMILLE ESPERANCE', _id: 29 },
		{ name: 'Family Circle Love Lab Organization ( FCLLO)', _id: 30 },
		{ name: 'FXB RWANDA ( Francois Xavier Bagnoud Rwanda)', _id: 31 },
		{ name: 'GAERG', _id: 32 },
		{ name: 'GALLAGHER TUBITEHO', _id: 33 },
		{ name: 'GERUKA HEALING CENTER', _id: 34 },
		{ name: 'GLOBAL EPILEPTIC CONNECTION', _id: 35 },
		{ name: 'GREEN HEALTH ORGANIZATION', _id: 36 },
		{ name: 'HAGURUKA', _id: 37 },
		{ name: 'HEALING AND REBUILDING OUR COMMUNITY', _id: 38 },
		{ name: 'HOPE AND HOMES FOR CHILDREN', _id: 39 },
		{ name: 'HOPE FOR LIVING ASSOCIATION', _id: 40 },
		{ name: 'HOPETHIOPIA RWANDA', _id: 41 },
		{ name: 'HOPITAL NEURO-PSYCHIATRIQUE (CARAES NDERA)', _id: 42 },
		{ name: 'HUMANITY INCLUSION', _id: 43 },
		{ name: 'INTERNATIONAL ALERT', _id: 44 },
		{ name: 'ITABWEHO', _id: 45 },
		{ name: 'IZERE MUBYEYI ORGANIZATION', _id: 46 },
		{ name: 'LES ENFANTS DE DIEU', _id: 47 },
		{ name: 'LES NAZAREENS', _id: 48 },
		{ name: 'LIWOHA- Life Wounds Healing Association', _id: 49 },
		{ name: 'Mental Health Diginity foundation', _id: 50 },
		{ name: 'MENTAL HEALTH NURSE STUDENTS ASSOCIATION LED ORGANISATION', _id: 51 },
		{ name: 'MIZERO CARE ORGANISATION', _id: 52 },
		{ name: 'MOUCECORE', _id: 53 },
		{ name: 'MUTIMAWURUGO CLARE', _id: 54 },
		{ name: 'NATIONAL REHABILITATION SERVICE', _id: 55 },
		{ name: 'NEVER AGAIN RWANDA', _id: 56 },
		{ name: 'OPPROMAMER', _id: 57 },
		{ name: "OSSARAU ( Organisation socio-culturelle des Sans Autrui au Rwanda D'Aujourd'hui)", _id: 58 },
		{ name: 'OYES (ORGANIZATION FOR THE YOUTH EMPOWERMENT AND STRIVE)', _id: 59 },
		{ name: 'PARTNERS IN HEALTH/INSHUTI MU BUZIMA', _id: 60 },
		{ name: 'PRISON FELLOWSHIP RWANDA', _id: 61 },
		{ name: 'PsyRWanda Organisation', _id: 62 },
		{ name: 'REBEJO ORGANISATION', _id: 63 },
		{ name: 'Réseaux de Développement de femmes pauvres (RDFP)', _id: 64 },
		{ name: 'Rwanda Demobolisation and Re-Intergration Commission (RDRC)', _id: 65 },
		{ name: 'RWANDA INITIATIVE AGAINST DRUG ABUSE AND RELATED CRIMES (RIDARC)', _id: 66 },
		{ name: 'RWANDA LEGACY OF HOPE', _id: 67 },
		{ name: 'RWANDA PALLIATIVE CARE AND HOSPICE ORGANIZATION', _id: 68 },
		{ name: 'RWANDA PSYCHOLOGISTS SOCIETY', _id: 69 },
		{ name: 'Rwanda Red-Cross Society', _id: 70 },
		{ name: 'RWANDA VILLAGE COMMUNITY PROMOTERS', _id: 71 },
		{ name: 'Rwanda Youths Action for Development (RYAD)', _id: 72 },
		{ name: 'Rwandan Society Psychiatrist Mental Health Nurses', _id: 73 },
		{ name: 'SAVE GENERATION ORGANISATION', _id: 74 },
		{ name: 'Save the children International', _id: 75 },
		{ name: 'SEXUAL HEALTH EDUCATION CENTER', _id: 76 },
		{ name: "SOCIETE DE L'APOSTOLAT CATHOLIQUE of 8th June 1973. All activities related to Mental Health are done under PALLOTTI CHILDREN HOPE CENTER of 1st January 2011 located in Gisagara district, Southern Province with Postal adress Po Box 700- Butare", _id: 77 },
		{ name: 'SOLID AFRICA', _id: 78 },
		{ name: 'SOLID MINDS', _id: 79 },
		{ name: 'Solidarity for the development of widows and orphans to promote self-sufficiency and livelihoods (SEVOTA)', _id: 80 },
		{ name: 'SOS', _id: 81 },
		{ name: 'STRIVE FOUNDATION Rwanda', _id: 82 },
		{ name: 'UMBRELLA OF ORGANIZATIONS OF PERSONS WITH DISABILITIES IN THE FIGHT AGAINST HIV&AIDS AND FOR HEALTH PROMOTION (UPHLS)', _id: 83 },
		{ name: 'UMURAGE UHEBUJE', _id: 84 },
		{ name: 'UMUZABIBU MWIZA ORGANIZATION', _id: 85 },
		{ name: 'UR-SUPPORT ORGANIZATION (URUGERO RWIZA SUPPORT ORGANIZATION)', _id: 86 },
		{ name: 'UYISENGA NI IMANZI', _id: 87 },
		{ name: 'WORLD VISION', _id: 88 },
	]




	const data = [
    {
      "PVP ID": "A38",
      "Organization Name": "ACCROSSAID",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Community outreach to empower vulnerable children and young people through educational and socio-economic opportunities. ",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 57,
      "Total employees (including volunteers)": 16,
      "Full time employees": 6.4,
      "2. What is your organization’s street address?": "KN87 ST NYARUGENGE",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address, Please contact us via email address: accrossaid@gmail",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Nyarugenge",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To effectively impact the lives of vulnerable children and young people through educational and socio-economic opportunities for sustainable future generations",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2013-09-10",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 16,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 40,
      "17. # Full time (Calculated)": 6.4,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 30,
      "17. % Volunteers": 30,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 57,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (4) Domestic Abuse Survivors (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (11)  Orphans (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(2) Depression, bipolar disorder, and other mood disorders (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "88",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "12",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "65",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "35",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "85",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Community outreach",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Community outreach",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Feedback and testimonies from their families, Behavior change that lead to socialization",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (8) Family psycho education (12)  Peer-support groups (13) Psychosocial rehabilitation services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 70,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 30,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "30, Self-Generation, 70, from CARITAS Rwanda, Mission Austria",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We don't",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The project with CARITAS will end in 2022 the same to the project with Mission Austria",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations as we are limited in funds,",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigmasation is high so they fear it and hide.",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work closely work with them because they help us in locating our clients",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ACROSSAID, KN87 ST NYARUGENGE district, MUHIMA sector, AKABEZA cell, UBWIZA village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.acrossaid.org",
      "13. What is your phone number?": "+250788813783",
      "14. Names of the contact persons": "Byiringiro Joel",
      "15. Email address of contact persons": "joelmph@gmail.com"
    },
    {
      "PVP ID": "A72",
      "Organization Name": "ADEPR MUSANZE",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "Substance abuse prevention and management",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 8,
      "Total employees (including volunteers)": 4,
      "Full time employees": 4,
      "2. What is your organization’s street address?": "Musanze District, Muhoza Sector,  Mpenge cell, Rukoro village, RN4 Rue Muhabura",
      "2. City": "Musanze",
      "2. Province  ": "nothern province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O.BOX 21 Musanze",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Musanze",
      "8. What other districts do you cover?": "Burera Gicumbi Gakenke Musanze Rurindo",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To preach the Gospel of Jesus-Christ and teach the word of God according to the holy bible, To publish and distribute Christian literature and other books in compliance with the law. To promote the social welfare with focus on health services, education and other social activities aiming at development.",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1964-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "We use Church hall to provide counseling and psychosocial services",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with Musanze District, Hospitals, and a Health Center in Burera, Rwerere. We also work with INES institution.  We work with the mentioned hospitals in case of referrals. We collaborate with INES institution as we have got student members and they give a space to practice our prayers",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "INES institution",
      "16. How many total people including volunteers does your organization employ?": 4,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 4,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 8,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Hearing unusual sounds,Stomachache, Insomnia, Nightmare.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "24",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "46",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "10",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We are guided by evangelism of ADEPR",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "guided by evangelism of ADEPR",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We consider that a beneficiary has improved when he/she has changed the behaviors eg: If he/she couldn't come for services at church and he/she can now come on his/her own i.e. we evaluate behavior change. we also consider the level of socialization",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (9) Housing services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 80,
      "31. Please characterize how your organization is funded? % NGOs": 10,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 10,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "10 Internal generation, Funderising, Members contribution, 80, Offertories and tithes, 20, COMPASSION RWANDA",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "N/A",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "PROJECT ENDED TO RESUME SOON",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "To be resumed",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Lack of knowledge about Mental health, Lack of budgets to help such people, Finacial Constraints. Lack of awareness about Mental illiness.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 1,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Private Clinics and Health Centers",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Through training them because spiritual leaders do meet so many people.",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Community Heath Workers  help them through linking them with mental health patients and Also they train them especially towards Genocide commemoration period",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ADEPR MUSANZE, \"Musanze District, Muhoza Sector , Mpenge cell, Rukoro village, RN4 Rue Muhabura\"",
      " 2. Which of the following best describes your organization?": "Other (Church)",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250785427220",
      "14. Names of the contact persons": "Ngendambizi Jean Damascene",
      "15. Email address of contact persons": "muhozadepr@gmail.com"
    },
    {
      "PVP ID": "A69",
      "Organization Name": "AEE RWANDA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Provide psychosocial service through OVC (Orphan & Vulnerable Children) project",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 48016,
      "Total employees (including volunteers)": 812,
      "Full time employees": 203,
      "2. What is your organization’s street address?": "KK 37 Ave",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "We are moving next year and we shall move to KG 220 st",
      "4. What is the mailing address of your organization (if different than Q2)?": "aee@aeerwanda.ngo",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Bugesera Gatsibo Kayonza Ngoma Nyagatare Rwagamana Gicumbi Rurindo Karongi Nyamasheke Rubavu Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Facilitating holistic transformation of Rwandans especially the most vulnerable",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1984-04-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Home and community based outreach",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We have partnership with Hong-Kong Polytechnic university for Technical support We work with District Health centers whereby we involve them in our community based activities",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Hong-Kong Polytechnic university for Technical support",
      "16. How many total people including volunteers does your organization employ?": 812,
      "(Total employees & volunteers coded)": 6,
      "17. % Full time ": 25,
      "17. # Full time (Calculated)": 203,
      "17. (# Full time employees coded)": 5,
      "17. % Part time": 0,
      "17. % Volunteers": 75,
      "18. Of your total staff, how many are health care workers?": 12,
      "18. (# health care workers coded)": 2,
      "19. How many total people does your organization currently provide mental health services for?": 48016,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (4) Domestic Abuse Survivors (8) Persons with HIV or AIDS (14)  Persons with post-traumatic stress disorder",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "95",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "5",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "25",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We follow protocols of Ministry of Health, 2. We only use volunteers whom we have trained on symptoms manifested by people of our interest that need to be referred to health facilities",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Actually, provision of mental health services is secondary to us but in case we have helped the patient we refer him/her to hospital or health centre and they are the one who have metrics to assess the progress/ recovery as the HCPs.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "refer him/her to hospital or health centre and they are the one who have metrics to assess the progress/ recovery as the HCPs.",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (7) Education services (8) Family psycho education (12)  Peer-support groups (17) Vocational rehabilitation services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Provision of school materials to pupils from vulnerable families.",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 90,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 10,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "10 from AEE members and friends contribution 90 by USAID, KNH German, HELP A CHILD",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Project funded by USAID \"Umwana ku isonga\" will end in 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financials limitations comparing to high demand, Skills limitations",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "Nothing",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "Psychoeducation sessions at HCs, Community meetings(Inteko z'abaturage), Local Leaders involvement in mobilization, etc)",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "we trained them and encourage them to referto MH Service a patients in need of MH Service support",
      "38. Meeting/ training with Traditional Healers": 1,
      "38. NO interaction with Traditional Healers": 0,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "We train church leaders and give them knowledge about MH illiness and a MH community social support coordinator communicates with them regulary if there is a case which needs MH Service support. Churches also work with MH Program by  MH awareness in the community where communications are shared in churches, services provided at HCs and Hospitals",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "MH Program work with community health workin first by providing different trainings related to MH illiness, and CHWs, help in case identification in the community, accompaniment and patients follow up in the community through home visits, phone calls etc.",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "AEE RWANDA, KG 230 ST",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo,/Kicukiro/ Nyarugenge",
      "8. In which districts do you have offices in the southern province?": "Kamonyi/Muhanga, Ruhango/ Nyanza/ Nyaruguru/ Nyamagabe/ Gisagara/ Huye ",
      "9. In which districts do you have offices in the western province?": "Karongi /Ngororero /Nyabihu/ Nyamasheke /Rubavu /Rusizi/ Rutsiro",
      " 10. In which districts do you have offices in the northern province?": "Gicumbi /Rulindo /Gakenke ",
      "11. In which districts do you have offices in the Eastern province?": "Bugesera/ Gatsibo /Kayonza/ Rwamagana/ Ngoma/ Nyagatare ",
      "12. What is your website?": "www.aeerwanda.ngo",
      "13. What is your phone number?": "+250788541586",
      "14. Names of the contact persons": "Niyibigira Ancille",
      "15. Email address of contact persons": "aniyibigira@aeerwanda.ngo"
    },
    {
      "PVP ID": "A83",
      "Organization Name": "AEGIS TRUST",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide counseling and psychosocial support",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 500,
      "Total employees (including volunteers)": 65,
      "Full time employees": 52,
      "2. What is your organization’s street address?": "KG. 689 st, KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o.box 7152 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "The organisation works to prevent genocide and help individuals and societies recover in its aftermath. Aegis is committed to remembering the atrocities of the past, investing in the peace-builders of tomorrow and advocating for those at risk of genocide today.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2001-02-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Genocide Memorial site",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We refer patients to Kacyiru Police Hospital",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 65,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 80,
      "17. # Full time (Calculated)": 52,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 10,
      "17. % Volunteers": 10,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 500,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (14)  Persons with post-traumatic stress disorder",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(2) Depression, bipolar disorder, and other mood disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Emotional disturbances related to the consequences of Genocide against Tutsi",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "20",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Our target population is people  with 12+ years of age 2. Linkage between people and health centres, 3. Give first aid, counseling",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Give first aid, counseling",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We have got a team incharge of narrative counselling and they are the one who can determine the progress reading from their faces.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "narrative counselling",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (8) Family psycho education (9) Housing services (12)  Peer-support groups (14) Supported employment (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 30,
      "31. Please characterize how your organization is funded? % NGOs": 70,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "50 Cooperative mission 20, SWEDEN EMBASSY",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, We provide financial Support for the patients to pay for prescribed medicines and we provide monthly stipend for paying rent",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "provide monthly stipend for paying rent",
      "33. How long will the funded project last?": "JUNE 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are limited in Funds.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "3. Additional space to administer mental health services",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "AEGIS TRUST, KG. 689 st, KIGALI",
      " 2. Which of the following best describes your organization?": "An international organization funding a local organization or institution to provide mental health services in the community",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.aegistrust.org",
      "13. What is your phone number?": "+250788307666",
      "14. Names of the contact persons": "Mutanguha Freddy",
      "15. Email address of contact persons": "freddy.mutanguha@aegistrust.org.rw"
    },
    {
      "PVP ID": "A10",
      "Organization Name": "AERG",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial services to the genocide survivors",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingh. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 150,
      "Total employees (including volunteers)": 23,
      "Full time employees": 14.03,
      "2. What is your organization’s street address?": "KK 30 Av",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Connect and represent all students survivors from universities, high learning institutions and secondary schools",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1996-10-20",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to all universities in Rwanda whereby we train counsellors from there and during commemoration, universities support us during counselling in any way and provides us with logistics. We are also affiliated to all hospitals because with a student card and with document that shows that you are a survivor you get facilitated to access medication",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 23,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 61,
      "17. # Full time (Calculated)": 14.03,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 39,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 150,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "70",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "30",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Incurable headache, Insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "32",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "68",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "33",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "2",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Advocacy  2. School visit with the main topic on Counselling  3. Community visit with the main topic of trauma   4. We have got a Toll Free which can be used with any member who would like to be assisted  5. Contract or Agreement for a patient to accept the terms and conditions and the means that we use to treat them.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Advocacy  2. School visit with the main topic on Counselling  3. Community visit with the main topic of trauma   4. We have got a Toll Free which can be used with any member who would like to be assisted  5. Contract or Agreement for a patient to accept the terms and conditions and the means that we use to treat them.",
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Test of PTSD  2. Anxiety disorder testing   3. Follow up on rising and decrease of symptoms",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "Test of PTSD, Anxiety disorder testing",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (7) Education services (8) Family psycho education (11)  Legal Advocacy (14) Supported employment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We provide free lawyers",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 80,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 20,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The project that is called Counselling Helpline Project is ending this coming year 2021 but renewable. The other project called AERG Humura Nturi Wenyine is supposed to end in 2021 but we have agreed to renew each year.",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations and limited professional skills and training",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "no interaction",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Involves local church leaders in all its areas of intervention who will then reffer people who need out intervetion to our socialtherapist volonteers to be recruited in socialtherapy session",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "we work with some of the Community Health Workers as volunteers in our organisation. They also play a role during the recruitment of participant",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "AERG, KK 30 Ave",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.aerg.org.rw",
      "13. What is your phone number?": "+250788687205",
      "14. Names of the contact persons": "Muneza Emmanuel",
      "15. Email address of contact persons": "aergcoordination@gmail.com"
    },
    {
      "PVP ID": "A70",
      "Organization Name": "African Humanitarian Action",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They work in the area of  mental health and psychosocial support.",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy",
      "Beneficiaries": 1171,
      "Total employees (including volunteers)": 120,
      "Full time employees": 45.6,
      "2. What is your organization’s street address?": "Street RN7. Located about 33 kilometres outside Kibuye town. Head office is located in Kigali, Kagugu KG 14 AV 357",
      "2. City": "Kigali",
      "2. Province  ": "Kigali",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "kizibahc@yahoo.com",
      "5. What province do you primarily operate in?": "Western Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Karongi",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "AHA will, without distinction to the root causes of the needs: a) Respond to crises, conflicts and disasters in Africa with regard to refugees, returnees, internally displaced persons, migrants, and their host communities; and make them better prepared to deal with their concerns themselves; b) Inform and advise the international community, governments, the civil society, and the private sector on humanitarian issues of concern to Africa; and promote collaboration among them accordingly. c) Support institutional and organisational development efforts within its own sphere of competence, prioritising countries where it operates or specifically has been solicited to do so. d) Study, explore and further develop its experiences and promote them to the benefit of other African organisations and civil society",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1994-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(3) Other residential treatment facility",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "In fact,In  kiziba refugee camp , AHA provide health services for refugees and host community.  So as a health center, we work in collaboration with Kibuye referral hospital as well as other tertiary health care providers such as CHUK, NDERA neuropsychiatric Hospital. Note that in Kiziba Camp, there is another organization named Humanity and Inclusion which we work with in everyday collaboration for psychosocial support for person with mental health illness.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": " Humanity and Inclusion ",
      "16. How many total people including volunteers does your organization employ?": 120,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 38,
      "17. # Full time (Calculated)": 45.6,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 2,
      "17. % Volunteers": 60,
      "18. Of your total staff, how many are health care workers?": 37,
      "18. (# health care workers coded)": 4,
      "19. How many total people does your organization currently provide mental health services for?": 1171,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "32",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "68",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "The most common issue or symptom that people with mental health concerns say they are suffering from are  somatic symptoms which  originate from  psychological distress such as  chronic headache, gastritis, generalized pain",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "46",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "54",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "27",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "38",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "32",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "3",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We provide mental health services by guideline of The mhGAP Humanitarian Intervention Guide (mhGAP-HIG)  of UNHCR and  protocol of  RBC for mental health care in health center",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Reduction of psychotic relapses 2. Number of person who come to seek mental health services 3. Reduction of presented mental health problems within community",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "1. Reduction of psychotic relapses 2. Number of person who come to seek mental health services 3. Reduction of presented mental health problems within community",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (10)  Illness management and recovery",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 4,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 96,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "1% is provided by Global  fund, 95. International medical corps, UNHCR, UNICEF, WFP, UNFPA, ECONOMIC COMMISSION FOR AFRICA,EU, AFRICAN UNION",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "As we provide primary mental health care for refugees in Kiziba camp, if a person with mental health illness needs to be referred to district or referral hospital,  AHA provide all payment assistance such as transport fees, feeding fees and  cover total  hospital bills for patients.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Unkwon but we have a renewable contract of 2 years",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "As an organization which deliver mental health services for refugees who are vulnerable people, there is a constraints of lack of funds for social support of people with mental, neurological and substance use conditions so that we would provide full of bio-psychosocial approach. In other words, we do clinical management only",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "We don't work with Tradional healers",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Yes, They are there to help our members with mental problem, they help them to go to the hospitals.",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work with community health workers in the villages in doing follow-up.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "African Humanitarian Action, Kigali,Kagugu KG 14 AV 357",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyamagabe /Gisagara",
      "9. In which districts do you have offices in the western province?": "Karongi",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.africanhumanitarian.org",
      "13. What is your phone number?": "+250788232742",
      "14. Names of the contact persons": "Dr. Mulugeta Tenna",
      "15. Email address of contact persons": "mulugetatenna59@gmail.com, rwanda@africahumanitarian.org"
    },
    {
      "PVP ID": "A67",
      "Organization Name": "AGAHOZO-Shalom Youth Village",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide holistic services including mental health services to its community of vulnerable and orphan youth across the country.",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 504,
      "Total employees (including volunteers)": 220,
      "Full time employees": 176,
      "2. What is your organization’s street address?": "RN3 , Rwamagana- Rubona, RWAMAGANA DISTRICT, RUBONA SECTOR, KARAMBI CELL, KARAMBI VILLAGE",
      "2. City": "Rwamagana",
      "2. Province  ": "Eastern Province ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "info@asyv.org",
      "5. What province do you primarily operate in?": "Eastern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Rwagamana",
      "8. What other districts do you cover?": "Rwagamana",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Through healing, education and love, Agahozo-Shalom Youth Village empowers orphaned and vulnerable Rwandan youth to build lives of dignity and contribute to a better world.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2008-12-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(7) Residential treatment center for children (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "A residential educational community that we can call a village that help orphaned and traumatized youth to heal their trauma through provision of improved healthcare services, family, love and education.",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Our Organization works with Rubona Health Center, Rwamagana District Hospital, Ndera Neuropsychiatric Hospital, Icyizere Clinic , other public and private hospitals given the severity of a clinical condition.",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 220,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 80,
      "17. # Full time (Calculated)": 176,
      "17. (# Full time employees coded)": 4,
      "17. % Part time": 18,
      "17. % Volunteers": 2,
      "18. Of your total staff, how many are health care workers?": 12,
      "18. (# health care workers coded)": 2,
      "19. How many total people does your organization currently provide mental health services for?": 504,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 5,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (11)  Orphans (12)  Refugees (15)  Children /adolescents with serious emotional disturbance",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide ",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "96",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "4",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Anxiety",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "33",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "67",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes,  CBT protocol, Confidentiality Policy, Medical and Pyscho-social Assessment",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "CBT protocol",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We have an impact tracking process through the medical and pyscho-social assessments.  When students arrive in the Village for the first, they go through an initial medical and psycho-social assessment and reassessed every two years to compare results and track healing outcomes. Furthermore, before kids leave the Village in their final year, they go through the last comprehensive exit assessment to finally measure their healing and provide guidance about transitioning to life after the Village based on those findings.",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100 L&R Uechtritz Foundation",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 1,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Until 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "•\tStaff shortage in the mental health department is the most outstanding challenge. 1 Psycho-social worker takes care of 128 kids. •\tIntake capacity is still low due to lack of funds and infrastructure to accommodate more kids. We recruit only 128 kids per year from all districts of the country.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Some patients face a serious barrier whereby they are rejected by their families",
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "We are recommended by families that we helped",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work with them as volunteers who help us to promote awareness",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "AGAHOZO-Shalom Youth Village, RN3 , Rwamagana- Rubona___RWAMAGANA DISTRICT, RUBONA SECTOR, KARAMBI CELL, KARAMBI VILLAGE",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.asyv.org",
      "13. What is your phone number?": "+250785710800",
      "14. Names of the contact persons": "Kabogo Janvier",
      "15. Email address of contact persons": "janvier@asyv.org / info@asyv.org"
    },
    {
      "PVP ID": "A32",
      "Organization Name": "AGHR (ASSOCIATION GENERALE DES HANDICAPES DU RWANDA - AGHR)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide mental health services across the country basically in psychotherapy",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "d. Palliative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 27,
      "Full time employees": 27,
      "2. What is your organization’s street address?": "KK 31 Ave 65",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "To Gasabo This will be in 2023 when we finish our office project",
      "4. What is the mailing address of your organization (if different than Q2)?": "We don't have a postal address rather you can contact us via email address that is: aghrw@yahoo.rw",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Kayonza Burera Karongi Rutsiro Rusizi Muhanga",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Serving people with different types of disabilities.",
      "10. What type of mental health services does your organization provide? ": "d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1979-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We don't have any partnership with any university but we work closely with all Health centers and Hospitals in case of referring patients.",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 27,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 27,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "35",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "65",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, body pain, Insomnia, Fatigue",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "75",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "25",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "47",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "18",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Follow up on our clients, family visits and observation, reports from family members and society",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We educate our clients about the UN rights on disabilities, we refer them to legal representatives",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": "educate clients about the UN rights on disabilities,  refer them to legal representatives",
      "31. Please characterize how your organization is funded? % Government": 10,
      "31. Please characterize how your organization is funded? % Private Donations": 5,
      "31. Please characterize how your organization is funded? % NGOs": 78,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 7,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "7 Internally generated ie. membership contributions and self found projects eg tailoring workshop, 78, we are sorry that we can't provide you with the names for confidentiality purposes",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. Mutuelle de sante",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "The projects we have range from 1-5 years",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "It is not easy to know our clients-Lack of know;ledge about mental health, Logistics and operational funds, Constraints met by people with disabilities that suffer from mental health, Qualified Human resource.",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "It is not easy to know our clients, Constraints met by people with disabilities that suffer from mental health, Qualified Human resource",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "Proffessional clinical supervision",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  3) Recommendations from Traditional Leaders 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "1",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Social media ( Face book, Twitter)",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They train community health about first aid, psychosocial support and about symptoms of mental health illnesses . The Community Helath workers in turn refer any siuspected cases to the organisation for further management",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "AGHR (ASSOCIATION GENERALE DES HANDICAPES DU RWANDA - AGHR), KICUKIRO, GIKONDO, KINUNGA CELL,KINUNGA VILLAGE, KK 31 Ave 65",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788899530",
      "14. Names of the contact persons": "UWINEZA LEATITIA",
      "15. Email address of contact persons": "hapidam06@yahoo.com, aghrw@yahoo.fr"
    },
    {
      "PVP ID": "A63",
      "Organization Name": "AIMR- IHORERE MUNYARWANDA",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They are keen on Gender Equality, Nutrition, GBV, HIV, Education, Environnent ,Peace Building ,Health Promotion, accountability, Advocacy and Networking in order to improve lives of IMRO’s beneficiaries at individual, family and, community levels for sustainable social economic development.",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 200,
      "Total employees (including volunteers)": 112,
      "Full time employees": 12.32,
      "2. What is your organization’s street address?": "KG 40 st, Kimironko, Kigali",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "http://imrorwanda.org,  info@imrorwanda.org,  P.O.BOX 2802Kigali, Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To serve the General population by implementing socio-economic interventions ,Advocacy,Networking and by promoting sustainable socio economic development.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Provide trainings to Community Helath Workers in mental health so that they can help in follow up of the beneficiaries",
      "11. What month and year was your organization founded?": "1999-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community out reach organization. Teh organisation;s peer edicators in the communities link identified patients with health facilities",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with Hospitals, and Health Centers and District referral  in All districts.,",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 112,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 11,
      "17. # Full time (Calculated)": 12.32,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 89,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 200,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  9) Substance abuse disorders (10) Neurological problems (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Victims of gender based based Violance and prostitutes",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Trauma, Insecurity, Depression, severe headache, insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "2",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "98",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We are guided by the Rwanda Health  Strategic Plan ( 2018-20124)",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Rwanda Health  Strategic Plan",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We get the results from M&E quaterly and annual report, refer to the data they have collected from the field work",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (7) Education services (8) Family psycho education (12)  Peer-support groups (15)  Suicide prevention services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "We use peer educators to identify patients and victims of Gender based violance and link them to health facilities for management . They work more or less as Community health workers. They also carry out patient advocacy",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 40,
      "31. Please characterize how your organization is funded? % Private Donations": 10,
      "31. Please characterize how your organization is funded? % NGOs": 50,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "50, USAID, Global Fund, FHI, NETHERLAND EMBASSY, GLOBAL FUNDS",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay Mutuel de Sante ( Medical Insurance) for them",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "2021. The fund is normally three  years renewable.",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited ressources, Limited tools; it needed to be updated and developed, There is no funding on this mental health service. h",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 1,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": " it needed to be updated and developed, There is no funding on this mental health service",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 3. Additional space to administer mental health services 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Assistance  and satisfaction of  the basic needs such as food and safety for beneficiaries of therapy and counselling. There is a plan to introduce  an ADVOCACY DAY to increase awareness on mental health issues so as to increase awareness",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NON",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NON",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "No direct collaboration. This is actually a challenge because when children are reintegrated in the community, there isn't anybody to make a follow up on how they are fairing in the communities",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "AIMR- IHORERE MUNYARWANDA, KG 40 st, Kimironko, Kigali",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.imrwa.com",
      "13. What is your phone number?": "+250788817801",
      "14. Names of the contact persons": "Jules Mugisha",
      "15. Email address of contact persons": "ihoreremunya@yahoo.fr"
    },
    {
      "PVP ID": "A56",
      "Organization Name": "ALIGHT (ARC)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support and provide mental health services ",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help",
      "Beneficiaries": 664,
      "Total employees (including volunteers)": 900,
      "Full time employees": 297,
      "2. What is your organization’s street address?": "KG 8 AVENUE, REMERA",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 2680 KIGALI RWANDA",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kirehe Gicumbi Karongi Huye Nyamagabe Nyanza",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Alright works with its partners and constituencies to provide opportunities and expertise to refugees, displaced people and host communities. We help people survive conflict and crisis and rebuild lives of dignity, health, security and self sufficiency. .",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  g. Empowerment/Self-Help",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1994-07-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(3) Other residential treatment facility",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, They have MOU with district hospitals in three camps i.e Gihembe, mahama and nyabiheke camp. They work with hospitals in case of referral for hospitalization",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 1,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 900,
      "(Total employees & volunteers coded)": 6,
      "17. % Full time ": 33,
      "17. # Full time (Calculated)": 297,
      "17. (# Full time employees coded)": 5,
      "17. % Part time": 0,
      "17. % Volunteers": 67,
      "18. Of your total staff, how many are health care workers?": 164,
      "18. (# health care workers coded)": 7,
      "19. How many total people does your organization currently provide mental health services for?": 664,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 5,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "20",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "80",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Insomnia, hallucinations, depression, sadness mood, derile of perfection and convulsion",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 1,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "35",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "65",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "24",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "68",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "7",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "1",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "They follow government protocols about refugee protection and health as mental health concerned 100%",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Balanced score Card: Internally we do quality and capacity building through training, human resource management and receive feedback from health facility users. We also utilize the supervision tool of Ministry of Health",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "also utilize the supervision tool of Ministry of Health",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "They advocate in any kind of violence, for example, sexual abuse. They support them financially like paying/Hiring the lawyers where need be, paying for the tests carried to check whether they were abused  and housing if they do not have",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 40,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 60,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "40, USA GOVERNMENT, 60, UNHCR",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, when the patient is being referred they make sure all the costs are paid",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "some will end this year 2020 and the rest next year 2021, but they usually get funds every year for almost 25 years of service",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. limited funds in mental Health. 2. lack of psycho-social support. 3. lack of psychologists in some camps. 4. lack of appropriate infrastructure to handle mental health issues",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "ALIGHT (ARC), KG 8 AVENUE, REMERA",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo/ Kicukiro/ Nyarugenge",
      "8. In which districts do you have offices in the southern province?": "Nyamagabe/ Gisagara",
      "9. In which districts do you have offices in the western province?": "Karongi",
      " 10. In which districts do you have offices in the northern province?": "Gicumbi",
      "11. In which districts do you have offices in the Eastern province?": "Gatsibo /Kirehe",
      "12. What is your website?": "www.wearelight.org",
      "13. What is your phone number?": "+250252580213, +250788318655",
      "14. Names of the contact persons": "Birasa Liliane",
      "15. Email address of contact persons": "lilianeb@wearealight.org"
    },
    {
      "PVP ID": "A60",
      "Organization Name": "AMEGERWA",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They deal with Gender promotion, Sexual and Reproductive health, hence fighting against GBV",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 200,
      "Total employees (including volunteers)": 7,
      "Full time employees": 2.03,
      "2. What is your organization’s street address?": "48 KN 14 ST",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "We plan to move on 1st September 2020, at Kimihurura",
      "4. What is the mailing address of your organization (if different than Q2)?": "Email: info@amegerwa.org Website: www.amegerwa.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Nyarugenge Bugesera Nyamasheke",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "The mission of AMEGERWA is to become the role model men in Rwanda through building alliances and synergies of men from Rwanda and the region in changing masculine threats to women participation in social and economic development and peaceful life.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2001-12-05",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community based mobilization (Schools, Community)",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with Community Health Workers who are affiliated to Health Centres. We also work with Isange One stop centre in the district that we work in and this relationship is based on when there are cases related to gender based violence",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Isange One stop centre and community health worker",
      "16. How many total people including volunteers does your organization employ?": 7,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 29,
      "17. # Full time (Calculated)": 2.03,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 29,
      "17. % Volunteers": 42,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 200,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (6) Suicide  9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "70",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Carry out training and awareness raising on conflicts management and dialogue based conflicts resolutions, 2. Carry out training and awareness raising campaigns on GBV prevention using Men engagement approaches, 3. Putting in place alliances where community role model couples are mentoring other couples on ending Gender based Violence and conflicts in the families, 4. Developing national and local resources with a focus on engaging men as partners of women in eradicating Gender Based    violence for stakeholders in gender equality promotion and sustainable peace building, 5. Contributing to dissemination of existing gendered laws and carry out advocacy for new ones based on the field experience, 6. Setting up men and women forums focussing on joint income generating activities and women economic confidence building and entrepreneurship, 7. Carry out training and awareness raising campaigns on GBV prevention using Men engagement approaches",
      "27. Ndera protocols": null,
      "27. Government/ MoH/ RBC Portocol": null,
      "27. WHO Protocols": null,
      "27. Psychologist developed protocols": null,
      "27. ARCR EHUKA Protocols": null,
      "27. No protocols": null,
      "27. Other": "1. Carry out training and awareness raising on conflicts management and dialogue based conflicts resolutions, 2. Carry out training and awareness raising campaigns on GBV prevention using Men engagement approaches, 3. Putting in place alliances where community role model couples are mentoring other couples on ending Gender based Violence and conflicts in the families, 4. Developing national and local resources with a focus on engaging men as partners of women in eradicating Gender Based    violence for stakeholders in gender equality promotion and sustainable peace building, 5. Contributing to dissemination of existing gendered laws and carry out advocacy for new ones based on the field experience, 6. Setting up men and women forums focussing on joint income generating activities and women economic confidence building and entrepreneurship, 7. Carry out training and awareness raising campaigns on GBV prevention using Men engagement approaches",
      "28. What metrics does your organization use to measure success for your mental health programs?": "No specific metrics as we do not treat rather direct people through mobilisation. There are no metrics as we just do mobilization but we do not make follow ups to see the impacts",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, From members contribution",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We don't",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The current project will end in 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. We lack enough space to administer our services, 2. Lack of a forum for Mental Health service providers, 3. Little knowledge and limited training on Mental Health, 4. Financial limitations",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "lack enough space to administer our services, Lack of a forum for Mental Health service providers",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "We work together with the National Police to find out about the Street children.",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "We don't work with them",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "We don't work with them",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We don't work with them",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "AMEGERWA, 48 KN 14 ST",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda ",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.amegerwa.org",
      "13. What is your phone number?": "+250788329398",
      "14. Names of the contact persons": "Harerimana Frederic",
      "15. Email address of contact persons": "freddyhar505@yahoo.fr"
    },
    {
      "PVP ID": "A62",
      "Organization Name": "ANTI DRUG ABUSE ORGANIZATION",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They fight against drug abuse",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy",
      "Beneficiaries": 12,
      "Total employees (including volunteers)": 6,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "KN3 RD KIGALI Pelikan  House , Third Floor Kicukiro Ziniya Kigali",
      "2. City": "Kigali ",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "adorwanda@gmail.com , www.ador.rw",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "Sensiting the Rwanda citizens against drug abuse and to a life free from drug abuse environment",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The organisation's curative activities are in the area of counselling",
      "11. What month and year was your organization founded?": "2012-06-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community Out reach Organisation. The organisation goes out to communities to sensitise against use of drugs. They also carry out psycho-social support  which is basically counselling s",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with Health Centers, We also work with international institute for trauma recovery in OHIO USA, We also partner with New Jerusalem church in Rwanda. The relationship with Health centers is for purposes of referrals",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": " international institute for trauma recovery in OHIO USA, New Jerusalem church in Rwanda",
      "16. How many total people including volunteers does your organization employ?": 6,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 12,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "We also meet LGBTQ(Lesbians, Gays,Bisexual Transgender un mentioned sexual orientation). The  root cause of such sexual orientaion being  mental health deviation",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, bloodpressure, anxiety, fear, appetite for  alcohol, appetite for smoking, sleeplessness, bad deams, lack of appetit, Eating disorder, eating too much, self isolotion, body pain, loss of interest in  work, dimensia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": "bloodpressure, apetite for alcohol, apetite for smoking, eating too much, loss of interest in work",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "35",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We use traume counselling protocols from IITR(Institute for trauma recovery in OHIO), Listing art skills, member care foundation protocol, debreifing, distoxication, affirmation",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": " traume counselling protocols from IITR",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Using evaluation form, Trauma recovery evaluation, visiting  them, interview and question, asking  family members, reports from society and other  groups.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We have a probational lawyer that helps our clients in courts.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "Member contributions which are meager",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints, Capacity building in mental health care, Technical Support",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "technical support",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  3) Recommendations from Traditional Leaders 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "1",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "They used to train them",
      "38. Meeting/ training with Traditional Healers": 1,
      "38. NO interaction with Traditional Healers": 0,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "they give them training in how to handle Unity and reconciliation among their believers",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "ANTI DRUG ABUSE ORGANIZATION, KN3 RD KIGALI  Pelikan  House , Third Floor  Kicukiro Ziniya Kigali",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788676512",
      "14. Names of the contact persons": "Mukabisanana Yvette",
      "15. Email address of contact persons": "bisananawurubibi@gmail.com"
    },
    {
      "PVP ID": "A08",
      "Organization Name": "ARCT-RUHUKA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Mental health and psychosocial support ",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  c. Curative  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Training g. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 5900,
      "Total employees (including volunteers)": 5317,
      "Full time employees": 53.17,
      "2. What is your organization’s street address?": "KG 672 st KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 717 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Nyarugenge Rwagamana Gakenke Musanze Rurindo Karongi Ngorero Rubavu Rutsiro Gisagara Nyamagabe",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To lead the development and application of innovative approaches and strengthen professional capacity in understanding, preventing and treating psychosocial problems",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1998-04-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "This organization is affiliated to University of Rwanda: They have joint activities, they work in preparation of national conferences in Mental Health domain, University of Rwanda provides technical support to professionals, they also work hand in hand in special events where they provide psychosocial support. They work hand in hand with University of Rwanda to conduct research. This organization works with Kibagabaga Hospital in case of refferal for hospitalization and Ndera and Ikizere center in same cases as of Kibagabaga and they also work with other District hospitals depending on their clients location.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 5317,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 1,
      "17. # Full time (Calculated)": 53.17,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 0,
      "17. % Volunteers": 99,
      "18. Of your total staff, how many are health care workers?": 7,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 5900,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "56",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "44",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "9",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "57",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "26",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "1",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Diagnosis, 2. Psychosocial support through individual counseling, Couples counseling (Psychosocial education), and Group counseling",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Diagnosis, 2. Psychosocial support through individual counseling, Couples counseling (Psychosocial education), and Group counseling",
      "28. What metrics does your organization use to measure success for your mental health programs?": "PTST Checklist",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "PTST Checklist",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We do this in partnership with other civil societies specialized entities in law such as HAGURUKA to advocate for our clients' rights",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 2,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 96,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 2,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "1 Members contribution 2. Rwanda Governance Board, 96: GIZ, TROCAIRE,",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Nope",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The first project will end in 2024 as well as the second one. the third is ending in 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are donor dependent, Poor perceptions of clients who are reluctant to pay for our services because they used to get them on free. We are financial limited to pay professionals",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Poor perceptions of clients who are reluctant to pay for our services because they used to get them on free",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "we work closely with community health workers who help us to identify those in need of our services.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ARCT-RUHUKA, KG 672 st KIGALI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Northern/ Districts",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyamagabe",
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": "Gicumbi",
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.arctruhuka.org.rw",
      "13. What is your phone number?": "+250788533628",
      "14. Names of the contact persons": "Abatoni Jane Gatete",
      "15. Email address of contact persons": "arct@rwanda1.rw / arctrwanda@gmail.com"
    },
    {
      "PVP ID": "A41",
      "Organization Name": "ASOFERWA (ASSOCIATION DE SOLIDARITE DES FEMME)",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "Key concerns are: Women's and children's rights, Fighting against GBV, Reproductive health and Mental health through counselling and advocacy",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 2690,
      "Total employees (including volunteers)": 94,
      "Full time employees": 5.64,
      "2. What is your organization’s street address?": "KG 682 ST 3 KIMUHURURA",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 565 KIGALI RWANDA",
      "5. What province do you primarily operate in?": "Eastern Province",
      "6. What other provinces do you cover?": "Northern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gicumbi Musanze Kamonyi",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To encourage the social-economic rehabilitation and healthier living conditions among vulnerable groups and to the Rwandan community in general.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1995-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "N/A",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, They have a relationship with all health centers and hospitals in all districts that they cover by referring the patients or beneficiaries there.",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 94,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 6,
      "17. # Full time (Calculated)": 5.64,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 94,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 2690,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Eye Pains, stomach pains and insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "eye pains",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "25",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, They follow Ministry of Health guidelines in place and other documents i.e OMS",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Behavior change (BCC). 2. Progress Report",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "1. They advocate for mental health patients through fighting stigma and discrimination in the community or in their families. 2. They help them in re-integration into their families or in the community  3. By orienting or supporting them to access special services like Cares Indera and other mental clinical specialists in health centers",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": "work hand in hand with the community policing",
      "31. Please characterize how your organization is funded? % Government": 20,
      "31. Please characterize how your organization is funded? % Private Donations": 15,
      "31. Please characterize how your organization is funded? % NGOs": 65,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "65, Global Fund, GFF( Global Financing Facility)",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, They proved them transport only to the mental health patients in order to go to where she/he can access mental health services",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Global Fund will end June 2021, GFF Dec 2020, RGB June 2021 and RODA Oct 2020 but renewable",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Capacity Building in Human resource, Lack of enough funds, lack of enough specialized mental health facilities in the country.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma from society make them hide",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work with them closely, they help us to identify any health issue that affects our client",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ASSOCIATION DE SOLIDARITE DES FEMME (ASOFERWA) KG 682 ST 3",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo",
      "8. In which districts do you have offices in the southern province?": "Kamonyi",
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": "Gicumbi",
      "11. In which districts do you have offices in the Eastern province?": "Bugesera",
      "12. What is your website?": "www.asoferwa.org",
      "13. What is your phone number?": "+250788301260 / +250788505510",
      "14. Names of the contact persons": "Nshimiyimana Appolinaire",
      "15. Email address of contact persons": "appolin4@gmail.com"
    },
    {
      "PVP ID": "A29",
      "Organization Name": "ASPIRE RWANDA",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "Rehabilitation of former sex workers. They provide psychosocial support",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy",
      "Beneficiaries": 320,
      "Total employees (including volunteers)": 15,
      "Full time employees": 15,
      "2. What is your organization’s street address?": "KG 685 st GISOZI",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 3012 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "The heart of our activities is to support vulnerable women and the youth through sustainable development programs. our range of programs include vocational skills training, Agriculture support and micro-loans. Additionally we offer to our beneficiaries social and health sensibilisation and childcare",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2010-06-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Kacyiru Hospital where we work with them in sensibilizing health issues and in promoting awareness for our clients",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 15,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 15,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 320,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (9) Persons who have experienced trauma (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "85",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "15",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "5",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "95",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Sensitisation out in the community and we receive people who come on their own or accompanied seeking for services the right one that we provide",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "receive people who come on their own or accompanied seeking for services the right one that we provide",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Feedback from families",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (8) Family psycho education (11)  Legal Advocacy (14) Supported employment",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We work with HAGURUKA which provide us with training and they can handle court cases free of charge",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 45,
      "31. Please characterize how your organization is funded? % NGOs": 55,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "55% was provided by Network for Africa, Humanity Unified and Global Giving",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We don't do that",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "we  still have two ongoing project and the first one is ending in 2021 and the second one is ending in 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited fund",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "The organisation is looked at as a detention facility because people are forcefully brought in by security organs after proving to be a burden to society.",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Community awareness is negative in sense that people associate it with detention facilities",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "Social Media, Government institutions,  security services,",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "No",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "No",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Not much. We call upon them to follow up on those that are reunited with families and give information on how they are doing",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ASPIRE RWANDA, KG 685 st GISOZI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.aspirerwanda.org",
      "13. What is your phone number?": "+250788859264",
      "14. Names of the contact persons": "Peace Ruzage",
      "15. Email address of contact persons": "aspirerwanda@gmail.com"
    },
    {
      "PVP ID": "A45",
      "Organization Name": "ASSOCIATION GARUKA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They operate a range of programs aimed at preventing, supporting and rehabilitating street and other vulnerable children and youth in Rwanda. ",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive d. Palliative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help",
      "Beneficiaries": 32,
      "Total employees (including volunteers)": 20,
      "Full time employees": 6,
      "2. What is your organization’s street address?": "Kicukiro District, Kigarama sector, Nyarurama cell, Kivu Village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O B0x 1553 Kigali Rwanda. www.garuka.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Rusizi",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To empower youth for their self reliance, Contribute to societies welfare, World without vulnerable children,  Seeing children enjoying equal rights,  See children in a family without social and economic problems, Help children grow socially and economically,  To fight against children's psycho-social difficulties,etc...",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. f. Training: Educating other mental health workers g. Empowerment/Self-Help",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2000-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with Gikondo Health  center for referral",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 20,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 30,
      "17. # Full time (Calculated)": 6,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 40,
      "17. % Volunteers": 30,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 32,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "70",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "30",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "80",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We work together with local authorities, we receive people from Kigali Rehabilitation center known as Kwa Kabuga.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "work together with local authorities",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We sum up the feedback from groups as in sharing their experiences",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We accompany them to the police to report the cases, we communicate to local authorities,",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": "accompany them to the police to report the cases, communicate to local authorities",
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 20,
      "31. Please characterize how your organization is funded? % NGOs": 70,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 10,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "10 Own income Generating activities (school fees) and membership contribution, 70 YWCA, Stay Alliance Foundation",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, We provide Mutuel de Sante( Medical insurance)",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "The current project is ending in 2021 with Stay Alliance Foundation and the other project with YWCA is ending in September, 2020",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Capacity building, we are financially limited, we don't have enough space to receive them",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "ASSOCIATION GARUKA, Kicukiro District, Kigarama sector, Nyarurama cell, Kivu Village.",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Western/Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": "Rusizi",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.garuka.org",
      "13. What is your phone number?": "+250788589826",
      "14. Names of the contact persons": "Nyirahabiyambere Lydie",
      "15. Email address of contact persons": "info@garuka.org"
    },
    {
      "PVP ID": "A05",
      "Organization Name": "ASSOCIATION MABAWA",
      "Category (RBC)": "Epilepsy",
      "Core business  / Basis for categorisation ": "They have a mental health nurse in charge to treat people with epilepsy. They also provide psychosocial support through activities to empower vulnerable people in community",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 340,
      "Total employees (including volunteers)": 8,
      "Full time employees": 4,
      "2. What is your organization’s street address?": "Mata Sector, Murambi cell, Nyamyumba village",
      "2. City": "Nyaruguru",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 6134 Kigali",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Nyaruguru",
      "8. What other districts do you cover?": "Nyaruguru",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Our strength lies in the specialist aspect of all our projects: the optimisation of social capital and best use made of human resources available in the village. Our main activity is education empowerment",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2006-09-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "The organisation works with Nyamyumba health centre where they have constructed a special room for handling epleptic cases  by a medical practitioner , who a staff of the organisation.",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes; The organisation is affiliated to Nyamyumba Health Centre where they build a special room for epleptic cases. The organisation works with Munini Hospital where they refer patients for hospitalisation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 50,
      "17. # Full time (Calculated)": 4,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 50,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 5,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 340,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (11)  Orphans (12)  Refugees (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "25",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "75",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Fainting . people say they faint because they have been bewitched. They also show high level of anxiety and panic and seem to have given up on life . Even when the organisation helps them finacially, they are reluctant to take the money because they say that they will never be cured after all.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 1,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "55",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "9",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "1",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Sensitization to find people with Eplepsy in the community 2. Checking whether they have medical insurance. 3. Internal transfer to Mental Health unit 4. Diagnosis ( Narrative Diagnosis) 4. Send examinations to the laboratory 5. Prescribtion of medicines",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Sensitization to find people with Eplepsy in the community 2. Checking whether they have medical insurance. 3. Internal transfer to Mental Health unit 4. Diagnosis ( Narrative Diagnosis) 4. Send examinations to the laboratory 5. Prescribtion of medicines",
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Analyze the number of crises per month eg: Fainting, 2. Epitherapy, Monotherapy, Bitherapy, 3. EEG, 4. Regular follow up with a period of 2years and after two years a patient is discharged",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "Analyze the number of crises per month eg: Fainting. Epitherapy, Monotherapy, Bitherapy",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (14) Supported employment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100. All funds are provided by Association Mabawa from the main headquater Switzerland",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for transportation, pay for Mutual Health Insurance, and we pay for medicines",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": null,
      "32. Other": 0,
      "33. How long will the funded project last?": "It will end in 2020",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 1,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We have an issue of transportation as the main headquaters used to provide us with lumpsums and used to pay for our taxes but nowadays they do not do that",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Our people on field work hand in hand with Community Health Workers as they are the one affiliated to Health Centres",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ASSOCIATION MABAWA, Mata Sector, Murambi cell, Nyamyumba village",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "en.mabawa.org",
      "13. What is your phone number?": "+250788312866",
      "14. Names of the contact persons": "Gashagaza Leon",
      "15. Email address of contact persons": "leongashagaza@gmail.com"
    },
    {
      "PVP ID": "A19",
      "Organization Name": "ASSOCIATION MODESTE ET INNOCENT (AMI)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Reconcile genocide perpetrators with the survivors, they do trauma healing, referral",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy",
      "Beneficiaries": 5300,
      "Total employees (including volunteers)": 18,
      "Full time employees": 18,
      "2. What is your organization’s street address?": "SH 57 Sreet, Huye, Rwanda ( House No. 11)",
      "2. City": "Huye",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o Box 201 Huye District",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "Western Province",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Nyabihu Gisagara Huye Nyanza Nyaruguru",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "AMI strives for the establishment of sustainable peace within society.",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2000-02-02",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "1. University of Rwanda. Huye campus in the Department of clinical psychology. They collaborate in research and offer internship to students",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 18,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 18,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 5300,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "severe headache, Insomnia and Stomach ulcers",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "stomach ulcers",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "23",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "45",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, 1. community approach i.e Social-therapic and Birth certificate 2. Psycho Therapy",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": " community approach, Psycho Therapy",
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Testimonies 2. evaluation 3. Attitude change",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "1. They advocate for the poor/ volunarable Genocide servivors to the Government Authorities inorder to give them housing services.  2. They advocate for the Teen Mothers to register thier abandoned children and also help them get the men who imprigenanted them.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 7,
      "31. Please characterize how your organization is funded? % Private Donations": 8,
      "31. Please characterize how your organization is funded? % NGOs": 85,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "85. MISEREOR- Germany, Bread for the world - Germany, 11-11-11 Belgium, and EIRENE SUIS- Switzerland",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, Transport to Cares Indera",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "all funded projects will end October 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Additional Health workers, Lack of infrastructure and Financial",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "We have enough personel. However the hospitals we work with need more trauined people in mental health",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "In all Our health related projects we work closely with Community health workers",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "ASSOCIATION MODESTE ET INNOCENT (AMI). SH 57 ST 11",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyanza",
      "9. In which districts do you have offices in the western province?": "Nyabihu",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.ami.ubuntu.com",
      "13. What is your phone number?": "+250788849846",
      "14. Names of the contact persons": "Jean Baptiste BIZIMANA",
      "15. Email address of contact persons": "ami.rwanda@yahoo.fr"
    },
    {
      "PVP ID": "A73",
      "Organization Name": "AVEGA AGAHOZO",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They do capacity building for genocide widows. They do counseling sessions and psychologically support them.",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 40,
      "Total employees (including volunteers)": 40,
      "Full time employees": 40,
      "2. What is your organization’s street address?": "KG 201 St",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o Box 1535 Kigali Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To work for the progress, empowerment and integration of genocide widows into the Rwandan society, improving and amiliorating their psycological and economic conditions of living which could help them live in perfect , harmony, solidality peace and stability.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Curative nature of their services is counselling. The nature of palliative service is in provision of essential necessities and prepare the families to provide end of life care",
      "11. What month and year was your organization founded?": "1995-01-15",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "We are community outreach organisation . We reach beneficiaries in their localities in the community and the homes (Visit widows who survived Genocide against Tutsi who lost their family members; including husbands and children",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes. we work closely with all health facilities in the country for refferal purposes and the District authorities",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "District authorities",
      "16. How many total people including volunteers does your organization employ?": 40,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 40,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 40,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "80",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "20",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia, Headache, Ulcers, Nightmares and strange voices",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "ulcers",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "2",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "98",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "21",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "9",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes. Although we aren't a medical facility we adhere to the Ministry of Health protocols guiding the provision of counseling",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Questionairre, Tests both oral and written, we visit them at home and do our own observation, reports and from family members and other members from the society",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (5) Court-ordered outpatient treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care (17) Vocational rehabilitation services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "1",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Training them in projects and management of finance generating",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We help them and provide legal assistance, follow up on their properties especially children whose properties could have been fraudulently taken.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 10,
      "31. Please characterize how your organization is funded? % Private Donations": 1,
      "31. Please characterize how your organization is funded? % NGOs": 70,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 19,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "19%, Internally generated income, 70% FARG,GIZ, SURF",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. We provide some assistance as we treat beneficiaries from our clinic free of charge We pay Mutual Health Insurance",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "we have one that will run out after one year i.e. it will end in 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints, Lack of human resource, Delay in service provision by third parties",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Delay in service provision by third parties",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 3. Additional space to administer mental health services",
      "36. Additional staff": "1",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work with Community Health Workers on follow up",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "AVEGA AGAHOZO, KG 201 St 3",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo /Kicukiro/ Nyarugenge",
      "8. In which districts do you have offices in the southern province?": "Kamonyi /Muhanga/ Ruhango/ Nyanza/ Nyaruguru/ Nyamagabe /Gisagara/ Huye ",
      "9. In which districts do you have offices in the western province?": "Karongi /Ngororero/ Nyabihu/ Nyamasheke/ Rubavu/ Rusizi/ Rutsiro",
      " 10. In which districts do you have offices in the northern province?": "Musanze/  Gicumbi Burera / Rulindo/ Gakenke ",
      "11. In which districts do you have offices in the Eastern province?": "Bugesera/ Gatsibo/ Kayonza /Rwamagana/ Kireh/e Ngoma/ Nyagatare ",
      "12. What is your website?": "www.avega-agahozo.org",
      "13. What is your phone number?": "+250788520115/78852022",
      "14. Names of the contact persons": "Mukabayire Valerie",
      "15. Email address of contact persons": "avegagahozo@gmail.com"
    },
    {
      "PVP ID": "A88",
      "Organization Name": "CARITAS  DIOCESAINE DE BYUMBA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial services. Eg: they have a psychologist at Musanze Prison who help prisoners psychologically on daily basis",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": "Don’t know",
      "Total employees (including volunteers)": 226,
      "Full time employees": null,
      "2. What is your organization’s street address?": "GICUMBI-BYUMBA",
      "2. City": "Gicumbi",
      "2. Province  ": "Northern province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "caritasbyumba@yahoo.fr",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "Eastern Province",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gicumbi",
      "8. What other districts do you cover?": "Gatsibo Nyagatare Burera Gicumbi Musanze Rurindo",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "The primary mission is to assist the most vulnerable people in society to intervene in case of disasters and advocacy for the needy in promoting integral human development",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Reintegration of patients and ex-prisoners in families through psychoeducation and reconcile perpetrators of genocide  and the families of survivors . We also help patients to get foster families. The curative nature  is done from 10 Health centers and Kiziguro hospital and in the foster family in Nyarurema parish where we have got a nurse and a psychologist assigned with daily follow up. The palliative care is provided for the needy at our offices and in families and we have got a clinical psychologist in charge of that ",
      "11. What month and year was your organization founded?": "1981-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (2) Multi-setting mental health facility (3) Other residential treatment facility (4) Partial hospitalization/day treatment (5) Psychiatric hospitalization or psychiatric unit of a general hospital (6) Residential treatment center for adults (7) Residential treatment center for children (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 1,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 1,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 1,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Rehabilitation center for people with mental health issues",
      "14. Is your organization a public private organization, or an NGO?": "(2) Private",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to UTAB (University of Technology and Arts) whereby we provide their students with professional training. We also work hand in hand with Byumba Hospital in case of referral. We work with CARAES Ndera and they help us to get our patients' medicines and help us with professional supervision and in case of referral",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 226,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": "Don’t know",
      "17. # Full time (Calculated)": null,
      "17. (# Full time employees coded)": 9,
      "17. % Part time": "Don’t know",
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": "Don’t know",
      "18. (# health care workers coded)": 9,
      "19. How many total people does your organization currently provide mental health services for?": "Don’t know",
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 9,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "People with trauma as a result of imprisonment",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "21",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "79",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Persistent headache, Insomnia but are the gtreated of Trauma",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "19",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "81",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "3",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "18",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "44",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "35",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Application 2. Consent 3. Pre-assessment 4. Admission 5. Assessment and treatment 6. Post-assessment 7. Decision-making (Discharge, Outpatient, Inpatient) 8. Transfer to psychiatric hospital 9. Follow-up",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Application 2. Consent 3. Pre-assessment 4. Admission 5. Assessment and treatment 6. Post-assessment 7. Decision-making (Discharge, Outpatient, Inpatient) 8. Transfer to psychiatric hospital 9. Follow-up",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Observation tests, Aptitude and Ability tests, Personality tests, Memory tests, Behavioral assessment",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (5) Court-ordered outpatient treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "1",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": null,
      "31. Please characterize how your organization is funded? % Private Donations": null,
      "31. Please characterize how your organization is funded? % NGOs": null,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": null,
      "31. Please characterize how your organization is funded? % Other": null,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, we pay for their transports and cover all of their costs to help them access better medical services",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "cover all of their costs to help them access better medical services",
      "33. How long will the funded project last?": null,
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "The first constraint is the high demad that is beyond our capacity. The second constraint is that the mental health care in Rwanda hasn't developed yet that we need advocacy in the government and in the private sectors",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 0,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We train CHWs on Trauma and Domestic violance for better management of Ex-Combatants in the community",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "CARITAS  DIOCESAINE DE BYUMBA, GICUMBI DISTRCT,BYUMBA SECTOR, NYAMABUYE CELL, NYIRAGASURUBA VILLAGE.",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": "Gicumbi/ Burera/  Rulindo",
      "11. In which districts do you have offices in the Eastern province?": "Gatsibo/ Nyagatare ",
      "12. What is your website?": "www.diocesebyumba.org",
      "13. What is your phone number?": "+250788491931",
      "14. Names of the contact persons": "Jean Marie Dushimiyimana",
      "15. Email address of contact persons": "jimdush@yahoo.fr"
    },
    {
      "PVP ID": "A78",
      "Organization Name": "CARITAS RWANDA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They've got mental health nurses and psychologists at every district hospital to help people with MHI",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 9000,
      "Full time employees": 1170,
      "2. What is your organization’s street address?": "KN 16 St Ave 30",
      "2. City": "Kigali",
      "2. Province  ": "Kigali",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "p.o Box 124 Kigali Rwanda  www.caritas.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Kicukiro Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To assit people people in needs and foster for the whole human being (Promoting human dignity)",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1960-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (2) Multi-setting mental health facility (3) Other residential treatment facility (4) Partial hospitalization/day treatment (5) Psychiatric hospitalization or psychiatric unit of a general hospital (6) Residential treatment center for adults (7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 1,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 1,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 1,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "- WORKING RELATIONS WITH RULI NURSING SCHOOL whereby the students are provided with internships in their own health facilities - WORK WITH ALL CATHOLIC BASED UNIVERSITIES IN RWANDA in the same context as Ruli Nursing School - And all catholic hospitals, health centers in the country",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "RULI NURSING SCHOOL, Catholic hospitals",
      "16. How many total people including volunteers does your organization employ?": 9000,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 13,
      "17. # Full time (Calculated)": 1170,
      "17. (# Full time employees coded)": 6,
      "17. % Part time": 0,
      "17. % Volunteers": 87,
      "18. Of your total staff, how many are health care workers?": 119,
      "18. (# health care workers coded)": 7,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "40",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "60",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "-Headache, Forgetting,Insomnia,Ulcers or stomachaches, Costant bad dreams, strange voices,Lack of appettitte, body pains.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": "ulcers",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "35",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We follow the WHO protocols and those subscribed by the ministry of Health",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 1,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "We use all metrics that are used by the WHO. Interviews, Self observation, Questionairre, reports from our community workers, family reports and change in behaviours, testimonies from society",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (5) Court-ordered outpatient treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care (17) Vocational rehabilitation services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "1",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "1",
      "29. Other, please specify": "-Provide Mutuelle de sante -School fees -Medical expenses incurred,- we also provide wheel chairs to those in need",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "-Advocacy on protection of victims --Pay legal charges -Transport  -Pay for tests and exams for those who were violated -We also have a peace and justice commission that works with the government of Rwanda",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 5,
      "31. Please characterize how your organization is funded? % Private Donations": 30,
      "31. Please characterize how your organization is funded? % NGOs": 60,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 5,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "60 %, Global Fund, USAID,DFID, FOUNDATION LILLIANE, INABEL, CATHOLIC CHURCH, UNHCR.",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. We do refer them to this facilitices, We pay the expenses incurred and and do privide transport and feeding expenses.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "1-5 Years. One project will end in 2021 and the other two projects will end in 2025",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "- We dont have enough mental health trained people.- There is no community inclusion in mental health management. - We dont have eneough resources, be it financial or trained personalle, Financila Constraints to cater for the big number that come to us, We lack enough resources also to cater for those whom we provide assistance beyond after the project is done",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 1,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "We are refered to by local authorities and even by  some ministers",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "The Community Health Workers contact us to get information about CARAES Ndera and want us to advocate for people with MHI whom they take care of out in the community",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "CARITAS RWANDA, CARITAS RWANDA KN 16 AV 30, PO.BOX: 124 KIGALI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo/ Kicukiro/ Nyarugenge",
      "8. In which districts do you have offices in the southern province?": "Kamonyi /Muhanga/ Ruhango/ Nyanza/ Nyaruguru/ Nyamagabe/ Gisagara/ Huye ",
      "9. In which districts do you have offices in the western province?": "Karongi /Ngororero/ Nyabihu/ Nyamasheke/ Rubavu/ Rusizi/ Rutsiro",
      " 10. In which districts do you have offices in the northern province?": "Musanze/  Gicumbi /Burera/  Rulindo/ Gakenke ",
      "11. In which districts do you have offices in the Eastern province?": "Bugesera /Gatsibo/ Kayonza /Rwamagana/ Kirehe/ Ngoma/ Nyagatare ",
      "12. What is your website?": "www.caritasrwanda.org",
      "13. What is your phone number?": "+250788535984",
      "14. Names of the contact persons": "Dr Prince Bosco KANANI",
      "15. Email address of contact persons": "bkanani@caritasrwanda.org / bosprince8@gmail.com"
    },
    {
      "PVP ID": "A37",
      "Organization Name": "CENTRE INSHUTI ZACU (Soeurs Inshuti z'Abakene)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide pychosocial support through women and young mothers empowerment. They also rehabilitate street children",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  d. Palliative  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help  h. Patient Advocacy  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 35,
      "Total employees (including volunteers)": 20,
      "Full time employees": 19.8,
      "2. What is your organization’s street address?": "Kicukiro District, Gahanga sector, Gahanga cell, Gatare village (CENTRE INSHUTI ZACU) ",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address. Contact us via email address: abakene@yahoo.fr",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Kicukiro",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Helping people with Mental Health illnesses in rehabilitation and help them live a happy life",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2000-05-17",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(6) Residential treatment center for adults (7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 1,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with CARAES Ndera; they do follow up on our children and help us to give them medicines and monitor their mental health progress",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 20,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 99,
      "17. # Full time (Calculated)": 19.8,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 1,
      "18. Of your total staff, how many are health care workers?": 4,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 35,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (8) Persons with HIV or AIDS (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (16) Persons with serious mental illness (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (7) Psychosomatic problems (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "100",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "They say that they have got serious headache,",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We follow CARAES Ndera' s protocols of treatment",
      "27. Ndera protocols": 1,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "We assess behavioral change, We work hand in hand with Ndera hospital which can professionally examine the progress",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 90,
      "31. Please characterize how your organization is funded? % Private Donations": 10,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "80, we are funded by NCPD and NUDOR, 10% government",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for our beneficiaries Mutual Health Insurance and we pay for their medicines",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "The projects funded by NCPD and NUDOR as well are ending next year 2021 with option to renew each year",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We have got a high demand and the constraint we do not have enough funds to satisfy the needs",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "Medical tools such as EEG in every Health Centre",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We have trained the Community Health Workers about the symptoms of epilepsy and in case they find someone with the symptoms they refer them to Health Centre but they do that they consult us to make sure they are making a right choice",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "CENTRE INSHUTI ZACU (Soeurs Inshuti z'Abakene), Kicukiro District, Gahanga sector, Gahanga cell, Gatare village (CENTRE INSHUTI ZACU).",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250786008525",
      "14. Names of the contact persons": "Nikuze Dative",
      "15. Email address of contact persons": "abakene@yahoo.fr"
    },
    {
      "PVP ID": "A52",
      "Organization Name": "CENTRE MAREMBO",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They a health program whereby they do clinical and psychothereapy. They fight against GBV and have 'safe house' for female victims of GBV ",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 35,
      "Total employees (including volunteers)": 23,
      "Full time employees": 20.93,
      "2. What is your organization’s street address?": "Bahoze Village, KIbenga Cell, NDERA SECTOR  GASABO DISTRICT",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o. Box: 5255 Kigali-Rwanda/Not currently operational centremarembo@gmail.com Twitter: @CentreMarembo Instagram: @centremarembo Facebook: @centremarembo",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Kirehe Rurindo Rutsiro",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To transform the lives of children and young girls who were victims of rape and other types of violence as well as the street children",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2010-09-17",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (7) Residential treatment center for children (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 1,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "They have a centre at Gikondo where girls and street are trained, counselled and undergo Psychotherapy and after which they are reintergrated  in the families. The centre has safe houses that are used to accomodate young girls who are victims of rape and sexual harassment for purposes of  counselling  and psycho social support.  Apart from that, they also  provide full financial assistance to children in Kigali, giving access to education for boys and girls who would otherwise be unable to go to school. The organisation pays  the full school and equipment costs for  the girls at their  Abatuje refuge and Harakeye house. The organisation continues to provide  support all the way through both primary and secondary education.",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Centre Marembo has MoU with Kigali City only in the process of getting girls especially  off the streets and to the organisation.  However, the Ministry of Youth is also a key partner in most activities",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "MoU with Kigali City, Ministry of Youth",
      "16. How many total people including volunteers does your organization employ?": 23,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 91,
      "17. # Full time (Calculated)": 20.93,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 1,
      "17. % Volunteers": 8,
      "18. Of your total staff, how many are health care workers?": 7,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 35,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Sex workers and single mothers",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Chronic headache, Insomnia, fear, isolation and bed wetting",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "isolation, bed wetting",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "90",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Therapy and counselling guidelines as well as the clinical principles",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Therapy and counselling guidelines as well as the clinical principles",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Behavior change and recovery progress monitoring",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Advocacy is done through families or local Government authorities not in court",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 20,
      "31. Please characterize how your organization is funded? % Private Donations": 2,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 78,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "78% from Self income generating services at Centre Marembo, 2 ie SIGHOR family foundation, ELMA Philanthropist",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Referral and medical insurance for some specific cases",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "1 year",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "A little and limited financial capacity, lack of legal assistance for beneficiaries, lack of partnership (MoU) with Heath Institution like MINISANTE, lack of skills in mental domain",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": " lack of legal assistance for beneficiaries, lack of partnership (MoU) with Heath Institution like MINISANTE",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Use of Social Media",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They are aware of our services and they recommend us",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "CENTRE MAREMBO, \"Bahoze Village, KIbenga Cell, NDERA SECTOR  GASABO DISTRICT\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.centremarembo.org",
      "13. What is your phone number?": "www.centremarembo.org",
      "14. Names of the contact persons": "Nsabimana Nicolette",
      "15. Email address of contact persons": "centremarembo@gmail.com"
    },
    {
      "PVP ID": "A36",
      "Organization Name": "Children and Youth Sports Organisation",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "Substance abuse prevention and management. Fight against Drugs so that vicitms can have life enhancing opportunities .",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help",
      "Beneficiaries": 65,
      "Total employees (including volunteers)": 87,
      "Full time employees": 21.75,
      "2. What is your organization’s street address?": "KG 1AVE",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "NO POST ADDRESS. Please reach us out through our address email: childrenyouthsport org@gmail.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kayonza Kirehe Burera Huye",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To support people with disabilities to have life enhancing opportunities for everyone to live, work and belong in an inclusive world",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2017-11-11",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 1,
      "13. (Other, please indicate)": "Campaign against drug abuse through sports. This is done at Amahoro National Stadium",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We do that campaign together with University of Rwanda",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 87,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 25,
      "17. # Full time (Calculated)": 21.75,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 15,
      "17. % Volunteers": 60,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 65,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "60",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "40",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "70",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We use Campaigns to mobilize the youth",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "use Campaigns to mobilize the youth",
      "28. What metrics does your organization use to measure success for your mental health programs?": "No metrics because we refer them to NDERA Hospital in case of severe symptoms and they are the one who have some metrics to measure the progress",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (13) Psychosocial rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 10,
      "31. Please characterize how your organization is funded? % NGOs": 90,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "90, National Police of Rwanda, KIGALI city, Ministry of sport",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We don't",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The project will end in 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are limited in funds. on our own we can't make to satisfy the needs",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "Additionally social care (Social support system including hospital, rent and food among others)",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "Testimonies are provided by people who have recovered from IWAWA rehabilitation center",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "Children and youth sports organisation, KG 1AVE",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788319602",
      "14. Names of the contact persons": "Mukasa Nelson",
      "15. Email address of contact persons": "childrenyouthsportorg@gmail.com"
    },
    {
      "PVP ID": "A11",
      "Organization Name": "CLINICAL PSYCHOLOGY STUDENTS ASSOCIATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Raise awareness of Mental Health, Attend to people with Mental Health Illnesses in the community and handling violence based issues",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training h. Patient Advocacy",
      "Beneficiaries": 72,
      "Total employees (including volunteers)": 100,
      "Full time employees": 100,
      "2. What is your organization’s street address?": "University of Rwanda. Huye Campus, NGOMA SECTOR, RUHANDE CELL",
      "2. City": "Huye",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "None",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Kayonza Nyagatare Musanze Rusizi Gisagara Nyanza",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Awareness of Mental Health, Reach people with Mental Health Illnesses in the community and handling violence based issues",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Psychology for success project helps us to tip students on how to manage stress",
      "11. What month and year was your organization founded?": "2002-05-03",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "We provide counselling services at our office",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to University of Rwanda in doing research research in psychology and they finance us in case we want to do home visits. UR has given us offices for free and we work hand in hand during commemoration to counsel students and other people experiencing trauma. Together with UR, we organise public talks for MHI awareness. We also work with Never Again Rwanda as we support them to counsel people during commemoration and we also are affiliated to Rwanda Psychological Society which helps us in capacity building and sometimes who supports us financially",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Never Again Rwanda, Rwanda Psychological Society",
      "16. How many total people including volunteers does your organization employ?": 100,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 100,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 100,
      "18. (# health care workers coded)": 6,
      "19. How many total people does your organization currently provide mental health services for?": 72,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Stress and Chronic headache",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "25",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Diagnosis  2. Psycho social Therapy  3. After we have done all the exercises and if a patient is not sorted then we reffer him/her to hospital",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Diagnosis  2. Psycho social Therapy  3. After we have done all the exercises and if a patient is not sorted then we reffer him/her to hospital",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Narrative Therapy is conducted to assess the progress  and for assessing the behavioural changes",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We advocate for patients' rights in local authorities and we do this through MAJ at the district level",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 40,
      "31. Please characterize how your organization is funded? % Private Donations": 15,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 45,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Voice of our values project has ended in the mid year 2020",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 1,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations and time management as we are students who are supposed to work on their studies",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "time management as we are students who are supposed to work on their studies",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They work hand in hand with them because their volunteers are among the community health workers",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "CLINICAL PSYCHOLOGY STUDENTS ASSOCIATION, University of Rwanda. Huye Campus, NGOMA SECTOR, RUHANDE CELL",
      " 2. Which of the following best describes your organization?": "Other",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "Under programming to be host soon",
      "13. What is your phone number?": "+250780375505",
      "14. Names of the contact persons": "Mugwaneza Odree",
      "15. Email address of contact persons": "mugwanezaod@gmail.com"
    },
    {
      "PVP ID": "A39",
      "Organization Name": "COMMISSION EPISCOPALE JUSTICE & PAIX",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Provides psychosocial service through  enhancement of Unity and Reconciliation ",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 1630,
      "Full time employees": 32.6,
      "2. What is your organization’s street address?": "Avenue de la Paix, KN4Av36",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 3043 Kigali",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Justice, peace, unity, reconciliation and human Rights",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1988-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 1,
      "13. (Other, please indicate)": "Pastoral  structure for education and networking surrounding the mental health",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 1630,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 2,
      "17. # Full time (Calculated)": 32.6,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 98,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "60",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "40",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "10",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Testimonies and Community healing approach.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": "Community healing approach",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "1. They are a team of 25 experts in different capacities i.e social, anthropologists (Lawyers). The team accesses the kind of case regards to legal matters and after that, the Bishop Council conducts the advocacy process through meeting the Government concerned entities, Bishop declaration (petition declaration) 2. Also, another assistance is that they hire the lawyers to assist the victims in the court.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100. Caritas scotland (SCIAF), TROCATRE, Alert International and DDC/SIDA SUISS/SWEDEN",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "some of them will end in Dec 2020, 2021 and 2023",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Lack of experts in mental health, Funds and Human Resource",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Community Mental Health Workers reffer people with symptoms of epilepsy to us",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "COMMISSION EPSCOPALE JUSTICE & PAIX (CEJP). KN4 Av 36. Avenue de la Paix.",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Nyarugenge",
      "8. In which districts do you have offices in the southern province?": "Muhanga /Nyamagabe Huye ",
      "9. In which districts do you have offices in the western province?": "Rubavu/ Rusizi",
      " 10. In which districts do you have offices in the northern province?": "Musanze / Gicumbi",
      "11. In which districts do you have offices in the Eastern province?": "Ngoma",
      "12. What is your website?": "www.cejprwanda.org",
      "13. What is your phone number?": "+250787044039",
      "14. Names of the contact persons": "FR. Valens NTIRAGIRE",
      "15. Email address of contact persons": "ntiragirevalens@gmail.com"
    },
    {
      "PVP ID": "A46",
      "Organization Name": "COMMUNITY BASED  SOCIOTHERAPY",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial services to the commuinity; with psychologists who attend to patients ",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help",
      "Beneficiaries": 649,
      "Total employees (including volunteers)": 134,
      "Full time employees": 17.42,
      "2. What is your organization’s street address?": "UMOJA house, KN3 st, Kiyovu, Nyarugenge, Kigali city, Rwanda",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address  but please reach to us through our email address. EMAIL ADDRESS:info@cbsrwanda.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Karongi Nyabihu Rubavu",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "contributing to the psychosocial well being of people affected by the genocide against Tutsi a painful past and or on going daily stresses, restoring the social fabric and protecting the peace",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2016-06-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "community based intervention",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to University of Rwanda; with its mental health department and we work together especially in mental health related research projects",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 134,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 13,
      "17. # Full time (Calculated)": 17.42,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 87,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 649,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 5,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "teachers and prisoners",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "CBS measures mental health status using SRQ20 it is difficult to sipte out which specific mental health disorder people have",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "CBS measures mental health status using SRQ20 it is difficult to sipte out which specific mental health disorder people have",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "37",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "63",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "22",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "68",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We have got a socio-therapy manual",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "socio-therapy manual",
      "28. What metrics does your organization use to measure success for your mental health programs?": "SRQ20",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "SRQ20",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "Other, please specify",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Psychosocial support",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 100,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100% from Embassy of the Netherlands",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "no",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "This will last for 3 years and will end in 2023",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "not enough means to expand the program through the country",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "Nothing",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "We work hand in hand with religious leaders as opinion leaders in the community",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work hand in hand with Community Health Workers to help us mobilize the community and in doing follow ups.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "COMMUNITY BASED  SOCIATHERAPY, UMOJA house, KN3 ST -0079 Plot, Kiyovu, Nyarugenge, Kigali city, Rwanda",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.cbsrwanda.org",
      "13. What is your phone number?": "+250788416724",
      "14. Names of the contact persons": "Ingabire Chantal",
      "15. Email address of contact persons": "chantal.ingabire@cbsrwanda.org"
    },
    {
      "PVP ID": "A80",
      "Organization Name": "COMPASSION INTERNATIONALE RWANDA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide pyschosocial support to vulnerable children",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 335,
      "Total employees (including volunteers)": 100,
      "Full time employees": 100,
      "2. What is your organization’s street address?": "KG 548 st Kigali",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o box 1200 kigali- Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Releasing Children from poverrty in Jesus's name.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1982-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "N/A",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 100,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 100,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 335,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "95",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "5",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Dimentia, Lack of appettitte, Insomnia, Stomachache, feeling threatened, tideness and body pain",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "45",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "55",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "95",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We don't treat but help our clients to get treatment from health facilities.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "help our clients to get treatment from health facilities.",
      "28. What metrics does your organization use to measure success for your mental health programs?": "- Self observation, Interviews, family reports, reports from societies, change in behaviour, we send our community health workers to visit them at their homes",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (5) Court-ordered outpatient treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "1",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "Our health community workers help the victims and mental health sufferers and survivors to get legal aid. Compassion do provide transport, legal fees for them. Secondly we do help unaccompanied children to get identity cards especially those whose origin and parentage is not known.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, Children correspondents",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. We provide Mutuelle de sante, pay the for medicines, buy wheel chairs, Transport of the patient to and from the hospital and pay for upkeep like food.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "buy wheel chairs",
      "33. How long will the funded project last?": "our program is long term. we sponsor children from age 0-22 years and then graduate them. however is some partners we have some projects that run for 5 years",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "rtners that Compassion projects are run by churches, there are parteners that dont own these projects thus misuse them. Corruption is abundant, Miss use of project funds by some project implementors, We find many drop outs, Dismissal of beneficiaries by implemetors and replace them with those they favour.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "corruption is abundant",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Lack of a permanent residences on ground near the communities that we serve in order to serve them better. We lack satellite offices in the communities",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 3) Recommendations from Traditional Leaders 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "1",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Public talk/Open workshop or selected group of people such as local leaders or families",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "Never",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Never",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Never",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "COMPASSION INTERNATIONALE RWANDA, KG 548 st Kigali",
      " 2. Which of the following best describes your organization?": "An international organization funding a local organization or institution to provide mental health services in the community ",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.compassion.org",
      "13. What is your phone number?": "+250788536726",
      "14. Names of the contact persons": "Ndayizeye Longin",
      "15. Email address of contact persons": "Lndayizeye@rw.ci.org"
    },
    {
      "PVP ID": "A06",
      "Organization Name": "FAITH VICTORY ASSOCIATION",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They work on improving public health, promote and protect children and women's rights, promoting gender equality. They fight GBV and have got safe houses where they deal with counseling violated young and adults females",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   c. Curative  e. Rehabilitative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 176,
      "Total employees (including volunteers)": 47,
      "Full time employees": 42.3,
      "2. What is your organization’s street address?": "GASABO district, RUSORORO SECTOR, NYAGAHINGA CELL, GISHARARA ",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 2800 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Kayonza Karongi Ngorero Nyabihu Nyamasheke Rutsiro Rusizi Gisagara Nyanza Nyaruguru",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Facilitating processes that eradicate poverty, improve public health, promote and protect children and women's rights, promoting gender equality, promote environment friendly projects.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2002-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "The organization is affiliated to Polyfemme Clinic, Beatrice Polyclinic: Relationship is based on refferal whereby we reffer our clients to the mentioned polyclinics. We are also affiliated to Rwanda National Police: After the police statement of a victim, they reffer the abused people to seek for psychological support and rehabilitation. They also work with GMO and Ministry of Gender and Family promotion in fighting GBV, promoting awareness and women empowerment. We also work with ARCT-RUHUKA in case of referral and in turn they send us some of their psychologists to help us. We have MoU with all the mentioned partners.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Polyfemme Clinic, Beatrice Polyclinic, Rwanda National Police, GMO and Ministry of Gender and Family promotion, ARCT-RUHUKA",
      "16. How many total people including volunteers does your organization employ?": 47,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 90,
      "17. # Full time (Calculated)": 42.3,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 10,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 176,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "We care especially for people under 18",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "15",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "85",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "85",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "13",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. We use Ministry of Health protocols, 2. Integrated Guidlines: Reception, We adhere to Standards of safe houses, 3. Discharge of patients, 4. Follow up, 5. Reintegration (Reintegrate Victims and families), 6. Sessions with Psychologists",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "We work with psychologists who determine their metrics",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (13) Psychosocial rehabilitation services (17) Vocational rehabilitation services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": 1,
      "29. Other, please specify": "We provide training about Saving and Support to create income generating activities. we provide teen mothers with tool kits and financial support to start business",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We work with MAJ at the district level to help our clients who can't afford to pay for lawyers get the legal assistance",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 20,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 60,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 20,
      "31. Explanations for funding": "60 . We are funded by ActionAid, CRS SNV and WomenforWomen International",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for transport and give our clients lunch money",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "The first project is: Speak Out which is ending in 2022. The second project is called HIV and is ending in 2021. the Third one is Twiceceka which will end in 2021. the Forth is our own project of Sfe houses and we don't have any plan to end it",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 1,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We have budget related issues i.e. financial limitations. the second constraints is that community isn't aware of mental health",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Yes, They invite the spiritual the spiritual healers to come into families that have issues to pray for them",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "N/a",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "FAITH VICTORY ASSOCIATION, GASABO district, RUSORORO SECTOR, NYAGAHINGA CELL, GISHARARA village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyaruguru/ Gisagara",
      "9. In which districts do you have offices in the western province?": "Karongi/ Nyabihu Nyamasheke/ Rusizi",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Kayonza",
      "12. What is your website?": "www.faith-victory.org",
      "13. What is your phone number?": "+250788677985",
      "14. Names of the contact persons": "`Bayasese Bernard",
      "15. Email address of contact persons": "bernard.bayasese@fvaministries.org"
    },
    {
      "PVP ID": "A48",
      "Organization Name": "FAMILLE ESPERANCE",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They fight against gender based conflicts among the married couples. They also do counseling.",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 4,
      "Full time employees": 1,
      "2. What is your organization’s street address?": "KG 8 Ave 44",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "famille_esperance@yahoogroupes.fr / www.familleesperance.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Kayonza Ngoma Rwagamana Gicumbi Musanze Karongi Rubavu Rusizi Huye Muhanga",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "The mission is to help reduce family based conflicts among the married couples in Rwanda",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2012-12-22",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(3) Other residential treatment facility",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 4,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 25,
      "17. # Full time (Calculated)": 1,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 75,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "The deal with Gender based related issues in the families not mental health issues",
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, They pay Mutual health insurance for the poor families",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "N/A",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "No full time workers due to lack of funds to pay them",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Capacity building for professionals, we need to develop new tools and approaches and need extra regular clinical supervision and stress management for professionals",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "We are reffered by by some hospitals and other entities such Transparency International Rwanda",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "FAMILLE ESPERANCE, KG 8 Ave 44",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Gisagara/ Huye ",
      "9. In which districts do you have offices in the western province?": "Rubavu",
      " 10. In which districts do you have offices in the northern province?": "Musanze ",
      "11. In which districts do you have offices in the Eastern province?": "Rwamagana",
      "12. What is your website?": "www.familleesperance.org",
      "13. What is your phone number?": "+250788304657",
      "14. Names of the contact persons": "Habyarimana Charles",
      "15. Email address of contact persons": "habychar@yahoo.fr"
    },
    {
      "PVP ID": "A17",
      "Organization Name": "Family Circle Love Lab Organization ( FCLLO)",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "Help Rwandans who experienced mental and psychosocial disturbances and wounds and disability. They also focus on GBV",
      "Category (PVP)": "Women's support",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 250,
      "Total employees (including volunteers)": 6,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "Agahenerezo village, rukira |Cell, \\huye Sector, Huye |District  1km from Huye Bus Park",
      "2. City": "Huye",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 605 Huye District- Rwanda",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "Create an environnt that educates, empowers girls and women, informs and inspires solutions through gender inclusiveness",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2018-04-28",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(2) Multi-setting mental health facility (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 1,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 1,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "\\the facility is a training centre where people are trained in psychosocial support for six months and thereafter given certificates to go and serve in the community. These work as volunteers int he communities",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, 1. University of Rwanda Huye Campu , social work Department in research and internship  2. Protestant Institute of Arts and Social Sciences in Research and internship. Also, they give lectures at the \\university on Trauma Management   3. Villengen Schwenningen University. Germany. The university sends students who come as interns and work as volunteers for three months",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 6,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 250,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "75",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "25",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "headache, Backache, Fear, Insomnia.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "5",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "95",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "16",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "9",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. EMDR- Eye Movement Disensitization Reprocessing. 2. Deep Breathing. 3. Musical Therapy. 4. ELGO Therapy",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. EMDR- Eye Movement Disensitization Reprocessing. 2. Deep Breathing. 3. Musical Therapy. 4. ELGO Therapy",
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. PTSD Symptom Scale - Interview DSM -5 (PSS-1-5), 2. Couple Therapy Tool, 3. Hear my body",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": " Couple Therapy Tool, Hear my body",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "They work with Isange one-stop center in most cases when they get a case of Sexual Abuse. 2. MAJ ( An association of Judicial Practitioners)  at the District that helps the poor or Volunarable people to access judicial rights. 3. They work hand in hand with the community policing",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": "work hand in hand with the community policing",
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Not Applicable",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "Not Applicable",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. Financial. 2. Enough Staff. 3. Supplies. 4. Building her own specialized treatment & Care for women victims of sexual violence & mental disorders Polyclinic center (EASPC)",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Through collaboration with the National Council of  women (CNF) at districts.",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "Never",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Never",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Community Health workers are involved as community mobilizers during the identification of beneficiaries",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "Family Circle Love Lab Organization ( FCLLO). Agahenerezo Village, Rukira cell, Huye sector, Huye District",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "N/A",
      "13. What is your phone number?": "+250788529315 / +250737209797",
      "14. Names of the contact persons": "Dative NAKABONYE",
      "15. Email address of contact persons": "dnakabonye@gmail.com"
    },
    {
      "PVP ID": "A53",
      "Organization Name": "FXB RWANDA ( Francois Xavier Bagnoud Rwanda)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support on most vulnerable in the communities",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 3316,
      "Total employees (including volunteers)": 3316,
      "Full time employees": 99.48,
      "2. What is your organization’s street address?": "Southern Province Kamonyi District, Runda sector Ruyenzi Cell Rubumba village",
      "2. City": "Kamonyi",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "From Ruyenzi to Ruyenzi (2020-202)",
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O. BOX: 188 Kigali-Rwanda Email: info@fxbrwanda.org Web site: www.fxbrwanda.org",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "City of Kigali Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyanza",
      "8. What other districts do you cover?": "Nyarugenge Kayonza Ngoma Musanze Rubavu Huye Muhanga Nyanza",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To fight against and address root causes of poverty as a means of securing children's rights to live, grow and develop in Rwanda.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The core activity is to look at the roots of conflict and try to manage that from the grassroot. Support is therefore targeted at uprooting the root causes of issues in families so as to attain stability in families eg dealing with poverty, conflict in families. They form groups where they provide psycho social support .",
      "11. What month and year was your organization founded?": "2012-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "The organisation provides services in the communities where it has helped to create associations  for individual therapy and group counselling.",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No written MOUs signed but FXB works in collaboration with Heath Centers and Hospitals, ISANGE One stop Center for GBVs issues as well as districts authorities. There is also a partnership with Boston University of USA for technical assistance in Research",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "ISANGE One stop Center for GBVs issues, districts authorities",
      "16. How many total people including volunteers does your organization employ?": 3316,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 3,
      "17. # Full time (Calculated)": 99.48,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 12,
      "17. % Volunteers": 85,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 3316,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (11)  Orphans (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Sex workers and street children",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide ",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "98",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "2",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "60",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "None",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Recovery rates and monthly/Quarterly/ progress reports",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (4) Intensive case management (7) Education services (8) Family psycho education (9) Housing services (12)  Peer-support groups (13) Psychosocial rehabilitation services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 3,
      "31. Please characterize how your organization is funded? % Private Donations": 2,
      "31. Please characterize how your organization is funded? % NGOs": 95,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "2% from SKOL, The Lego Foundation, Grant Challenges Canada,Wellsprings, 95 USAID",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes/Medical fees or reimbursement for referred patients ad medical insurance",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "2020-2022/ 3 years",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Insufficient infrastructures and spaces due to lack of  funds and lack of skills for some mental health workers",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We do work with Community health workers and schools especially when we need to talk to youths about drugs and unwanted pregnancies",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "FXB RWANDA ( Francois Xavier Bagnoud Rwanda), \"Southern Province Kamonyi District, Runda sector Ruyenzi Cell Rubumba village\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo",
      "8. In which districts do you have offices in the southern province?": "Muhanga/ Nyanza",
      "9. In which districts do you have offices in the western province?": "Rubavu",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Rwamagana /Ngoma",
      "12. What is your website?": "www.fxbrwanda.org",
      "13. What is your phone number?": "+250788651847",
      "14. Names of the contact persons": "Kayitana Emmanuel",
      "15. Email address of contact persons": "ekayitana@fxb.org"
    },
    {
      "PVP ID": "A84",
      "Organization Name": "GAERG",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial services to the genocide survivors",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 3500,
      "Total employees (including volunteers)": 73,
      "Full time employees": 29.2,
      "2. What is your organization’s street address?": "KK 30Ave",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "http://gaerg.org.rw",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Gicumbi Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To strengthen the capacity of survivor's communities to overcome the consequences of Genocide against Tutsi, Play more ffective role in Rwanda Society.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Research on Mental Health",
      "11. What month and year was your organization founded?": "2003-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "University of Rwanda, University Libre de Kigali, AUCA(adventist University of Central Africa) Health Centers, Hospitals. Our relationship with all Universities is that they help us during commemoration as they provide us volunteers and in case we want to mobilize the students in campuses",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 73,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 40,
      "17. # Full time (Calculated)": 29.2,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 60,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 3500,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "40",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "60",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Persistent headache, Insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "30",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "20",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Therapeutic Hearing; After doing the therapeutic hearing the patients are taken to the cunsultation room to get further counselling from professional psychologists.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Therapeutic Hearing, after that the patients are taken to the cunsultation room to get further counselling from professional psychologists.",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Specific test of Therapetic Hearing and assessment of behavioral change and follow up in the community",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": "test of Therapetic Hearing",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We work hand in hand with AERG lawyers to help them out and the service is free of charge",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 30,
      "31. Please characterize how your organization is funded? % NGOs": 70,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "70, SURF, Imbuto foundation",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Open Ended",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial Support",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Train them on trauma counselling, Train them on identification of persons with MHI, Train them on evacuation and listening frequencies , and they in turn help us in advocacy",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "GAERG KK 30 Ave",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.gaerg.org.rw",
      "13. What is your phone number?": "+250788315809",
      "14. Names of the contact persons": "Gatari Egide",
      "15. Email address of contact persons": "gaergrwanda@gmail.com"
    },
    {
      "PVP ID": "A24",
      "Organization Name": "GALLAGHER TUBITEHO",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Support people with severe disabilities; including people with MHI and reintegrate them in families.",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 26,
      "Total employees (including volunteers)": 20,
      "Full time employees": 20,
      "2. What is your organization’s street address?": "Avenue de l'Umuganda. District Rubavu, Gisenyi sector, Mbugangali cell, Uburanga village",
      "2. City": "Rubavu",
      "2. Province  ": "Western province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address but please reach out us through email address info@gallaghertubiteho.rw",
      "5. What province do you primarily operate in?": "Western Province",
      "6. What other provinces do you cover?": "City of Kigali",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Rubavu",
      "8. What other districts do you cover?": "Gasabo",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Support people with severe disabilities for their future and reintegration in families",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2019-09-10",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Gisenyi Hospital: They provide us with nurses who come to treat our patients at their residential. We also work with Ndera Hospital (CARAES Ndera) in case we want to refer patients for hospitalization",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 20,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 20,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 26,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "85",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "15",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "50",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We use 1. Medical Chart 2. we have got Incident reports and 3. Progress report and we copy daily transactions",
      "27. Ndera protocols": null,
      "27. Government/ MoH/ RBC Portocol": null,
      "27. WHO Protocols": null,
      "27. Psychologist developed protocols": null,
      "27. ARCR EHUKA Protocols": null,
      "27. No protocols": null,
      "27. Other": " Medical Chart, Incident reports, Progress report ",
      "28. What metrics does your organization use to measure success for your mental health programs?": "For epileptics, we consider number of crisis per month, we use the medical charts to track the progress. We also examine progress report from the key support workers and we finally do home visits for follow-up.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (13) Psychosocial rehabilitation services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We work hand in hand with MAJ (justice bureaus at District level) as we are partner to the district",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": "We work hand in hand with MAJ (justice bureaus at District level) as we are partner to the district",
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100% of funds is provided by the only one donor Gallagher from England",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "1. We pay transport fees, 2. We pay for their Mutual Health Insurance, 3. We pay for prescribed medicines, 4. We buy them mobility aids such as Wheelchairs, First Aids kits",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "buy them mobility aids",
      "33. How long will the funded project last?": "The Housing project should have ended this year 2020 but due to the corona virus outbreak, we plan to extend. Community based living project is somehow tricky as Gallagher started funding it since 2014 without a fixed timeline and this project will end whenever she will say she is tired to continue, Another project that we have will end in 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. Limited in funds as we do have a high command 2. Lack of legal personality",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "4. Awareness and understanding of Mental Health Illnesses and symptoms 7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Recommendations from healed patients or from the families that we helped",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "GALLAGHER TUBITEHO, Avenue de l'Umuganda. District Rubavu, Gisenyi sector, Mbugangali cell, Uburanga village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.gallaghertubiteho.rw",
      "13. What is your phone number?": "+250783150260",
      "14. Names of the contact persons": "Nzabahimana Theophile",
      "15. Email address of contact persons": "nzabahitheophile@gmail.com / info@gallaghertubiteho.rw"
    },
    {
      "PVP ID": "A86",
      "Organization Name": "GERUKA HEALING CENTER",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "A private clinic that provides psychotherapy",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  c. Curative  d. Palliative  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training h. Patient Advocacy",
      "Beneficiaries": 50,
      "Total employees (including volunteers)": 7,
      "Full time employees": 7,
      "2. What is your organization’s street address?": "KG 66 st, Gisozi",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "gerukahealingcentre@gmail.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "Strengthening individuals, families, couples, children and groups by championing mental health through psychotherapy",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The palliative care nature is done as a home based psychological accompaniment",
      "11. What month and year was your organization founded?": "2019-09-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(2) Private",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No formal partnership but we are used to referring patients at CARAES Ndera hospital",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 7,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 7,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 6,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 50,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "People with sexual identity struggles",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "60",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "40",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "30",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Adapted tests for psychotherapists notably PCL-5,  John Hopkins tests, Resilience tests, African Neuropsychological Battery",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Adapted tests for psychotherapists notably PCL-5,  John Hopkins tests, Resilience tests, African Neuropsychological Battery",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We apply the above tests to assess the progress. Actually, the pro-PCL-5, pro- John Hopkins, pro-African neurological battery and pro-resilience",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (7) Education services (8) Family psycho education (10)  Illness management and recovery (13) Psychosocial rehabilitation services (15)  Suicide prevention services Other, please specify",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "The nature of education services here is that we train other professionals about stress management skills",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, Self generation from service fees",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No, we don't",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Not applicable as we do not have any funded project",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Lack of infrastructure, limited number of staffs, Ministerial guidelines are not clear to private practices, insurance issue as all most of them do not cover our services",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Ministerial guidelines are not clear to private practices, insurance issue as all most of them do not cover our services",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "GERUKA HEALING CENTER, KG 66 st, Gisozi",
      " 2. Which of the following best describes your organization?": "Other",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+18016448488/ +250789473102",
      "14. Names of the contact persons": "Omar Ndizeye",
      "15. Email address of contact persons": "nomar.book@gmail.com"
    },
    {
      "PVP ID": "A25",
      "Organization Name": "GLOBAL EPILEPTIC CONNECTION",
      "Category (RBC)": "Epilepsy",
      "Core business  / Basis for categorisation ": "They are specialized in helping out people with epilepsy; having got own clinical psychologists. They fight against epilepsy in different domains including health, psycho-social and legislative to improve the well-being of beneficiaries and their families for community",
      "Category (PVP)": "Epilepsy",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  c. Curative  e. Rehabilitative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training g. Empowerment/Self-Help  h. Patient Advocacy  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 350,
      "Total employees (including volunteers)": 33,
      "Full time employees": 2.31,
      "2. What is your organization’s street address?": "Rubuvu District, Avenue Nyakabungo, Gisenyi Sector, Bugoyi cell",
      "2. City": "Rubavu",
      "2. Province  ": "Western province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address but you reach us out through email address of globalepelepticc@gmail.com or via website www.gecorwanda.com",
      "5. What province do you primarily operate in?": "Western Province",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Rubavu",
      "8. What other districts do you cover?": "Bugesera Gatsibo Kayonza Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Kamonyi Nyamagabe Ruhango",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "We fight against epilepsy in different domains including health, psycho-social and legislative to improve the well-being of beneficiaries and their families for community holistic transformation",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2010-10-29",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to all district hospitals whereby we share information on epileptics and do referral in any case. We do train their Mental Health practitioners and we do have forums of epileptics at district hospitals from all district health centres. We are also affiliated to Ndera Hospital as we do refer our patients and they also sometimes decentralize their teams to treat our patients on field.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 33,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 7,
      "17. # Full time (Calculated)": 2.31,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 93,
      "18. (# health care workers coded)": 6,
      "19. How many total people does your organization currently provide mental health services for?": 350,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "100",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Fainting",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 1,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "52",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "48",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "34",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "56",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Identification of the patient 2. Guiding patients to Health Centres 3. Treatment at Health Centre  4. we then do Psycho-social treatment  5. Counselling and finally 6. We rehabilitate both the patient and his/her family",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Identification of the patient 2. Guiding patients to Health Centres 3. Treatment at Health Centre  4. we then do Psycho-social treatment  5. Counselling and finally 6. We rehabilitate both the patient and his/her family",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We consider the number of crisis per month, We consider the decrease of symptoms, and the Socialization of the patient",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We accompany the patients to avoid injustice as in case some patients can't make to speak for themselves",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 90,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 10,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "10% generated from membership contribution, 90% is provided by Handicap International and IBE (International Bureau for Epilepsy",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "In partnership with Handicap International, we pay the transportation fees for patient and caregiver, we pay for restaurant, we pay for Mutual Health Insurance and We also provide Wheelchairs",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "provide wheelchairs",
      "33. How long will the funded project last?": "The project funded by Handicap International will end in 2021 in December however there is a plan to renew and start the second phase though all has been put on hold due to the corona virus outbreak",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited funds to satisfy the high demand that we receive, We lack transportation means, Limited professional training, and the limited number of Mental Health practitioners",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "We don't have finance to run our offices because we don't have donors.",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Because we work with new Jerusalem church some people are reffered to us by the members of this church.",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We meet community health worker who know drug users and we advise them to refer them to us.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "GLOBAL EPILEPTIC CONNECTION, Rubuvu District, Avenue Nyakabungo, Gisenyi Sector, Bugoyi cell",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.gecorwanda.com",
      "13. What is your phone number?": "+250788836616",
      "14. Names of the contact persons": "Nshavujabandi Jean",
      "15. Email address of contact persons": "shavujean@yahoo.com"
    },
    {
      "PVP ID": "A79",
      "Organization Name": "GREEN HEALTH ORGANIZATION",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They are keen on protecting social health; attending on  children, youth and their parents, in their households to train them on how to avoid drug abuse",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 71,
      "Total employees (including volunteers)": 30,
      "Full time employees": 5.1,
      "2. What is your organization’s street address?": "Musanze District, MUHOZA SECTOR, Mpenge cell, Gikwege village, Rue Pyrthreum",
      "2. City": "Musanze",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "organisationgreenhealth@gmail.com",
      "5. What province do you primarily operate in?": "Eastern Province",
      "6. What other provinces do you cover?": "Northern Province",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Musanze",
      "8. What other districts do you cover?": "Burera Gakenke Musanze",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To protect social health (social responsibilities and well being) of children, youth and their parents, people in house holds in Rwanda.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "we have a project of building supportive services in the future",
      "11. What month and year was your organization founded?": "2020-05-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community outreach organization",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No, but in our working plan we are to work with all hospitals especially those that provide Mental Health services",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 30,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 17,
      "17. # Full time (Calculated)": 5.1,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 83,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 71,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "40",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "60",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Nightmare, Tiredness, heartache,body pain, dimentia, angerness,stomatchache, swelling legs.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": "heartache, swelling legs, angerness",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "45",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "55",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "68",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "2",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We adhere to National Mental Health policy, MoH guidlines and protocols for mental health diagnosis and treatment. counselling as per Ministry of Health protocols, such as Don't blame i.e. there is no need of blaming a client, Familiality i.e. get in touch with a client to create a good atmosphere where they can feel comfortable to share their stories",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "We look at their behaviour change, Community reports, Face to Face interview, Community health workers reports.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (6) Diet and exercise counseling (12)  Peer-support groups (14) Supported employment (15)  Suicide prevention services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, We get membership fees and contribution.",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "N/A",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Trainings, Lack of Partners in our field work, Limitations from local leaders, Financial constraints, Logistics, People hide their conditions.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "GREEN HEALTH ORGANIZATION, Musanze District, MUHOZA SECTOR, Mpenge cell, Gikwege village, Rue Pyrthreum",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.greenhealthorganization.com",
      "13. What is your phone number?": "+250788774495",
      "14. Names of the contact persons": "Sinamenye Theobard",
      "15. Email address of contact persons": "sinamenyetheobard1@gmail.com"
    },
    {
      "PVP ID": "A82",
      "Organization Name": "HAGURUKA",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They fight against GBV and advocate for women's rights",
      "Category (PVP)": "Women's support",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 438,
      "Full time employees": 21.9,
      "2. What is your organization’s street address?": "KG 11 AVE 95",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "WWW.info@haguruka.org.rw",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kayonza Musanze Nyamasheke Nyanza",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To promote and defend the rights of women and children",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "short term period shelter for victims of gender based violance",
      "11. What month and year was your organization founded?": "1991-07-16",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "We are a facility that help out women and children with legal issues and we do this in the community and in our office",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with all health centers in case of referral and Isange one stop center when there is a case related to GBV",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Isange one stop center ",
      "16. How many total people including volunteers does your organization employ?": 438,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 5,
      "17. # Full time (Calculated)": 21.9,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 95,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (9) Persons who have experienced trauma (11)  Orphans (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "80",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "20",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Constant headache, Tiredness, Insomnia, Dimentia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "5",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "95",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "85",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We help people who come to seek for our help",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "We help people who come to seek for our help",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We provide legal assistance and their presence in courts is a measure for the achievements.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "provide legal assistance and their presence in courts is a measure for the achievements",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "Other, please specify",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "N/A",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 85,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 15,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "85, USAID, UN Woman, RCN(Justice and Democracy), TROCARE, GIZ",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "N/A",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "we have one with 3 years remaining i.e. it will end in 2023",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints, the demand is high for our services but we are unable to deliver, Duration of the project based on timelines leave clients without solving their problems, technical constraits.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 1,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Recommendations by Local Authorities",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "HAGURUKA , Gasabo,Kimironko KG 11AV 95",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyanza",
      "9. In which districts do you have offices in the western province?": "Nyamasheke",
      " 10. In which districts do you have offices in the northern province?": "Musanze ",
      "11. In which districts do you have offices in the Eastern province?": "Kayonza",
      "12. What is your website?": "www.haguruka.org.rw",
      "13. What is your phone number?": "+25078830834",
      "14. Names of the contact persons": "UMURERWA NINETTE",
      "15. Email address of contact persons": "executiveshaguruka@gmail.com"
    },
    {
      "PVP ID": "A76",
      "Organization Name": "HEALING AND REBUILDING OUR COMMUNITY",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support and have a system of volunteering where they go into communities to help people psychologically",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 100,
      "Total employees (including volunteers)": 300,
      "Full time employees": 30,
      "2. What is your organization’s street address?": "Musanze District, Kimonyi sector, Kivumu cell, Nyamugari village",
      "2. City": "Musanze",
      "2. Province  ": "Nothern province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O.BOX 5498 Kigali, Rwanda",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Musanze",
      "8. What other districts do you cover?": "Bugesera Karongi Nyamasheke Huye",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To have a healed community.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2003-02-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We pattern with with PIASS a university of protestants in Rwanda in peace building, We also work with hospitals and health centers especially those located in Musanze, we trained teachers on identifying and counseling of trauma healing and drug abuse, we have a library that has books on drugs and trauma for children.",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 300,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 10,
      "17. # Full time (Calculated)": 30,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 90,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 100,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "20",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "80",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Poverty, Insomnia, Hadache, lack of appetite, tiredness, backache",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": "poverty",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "20",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "6",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "4",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Group counselling, individual counselling, Healing companions",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Group counselling, individual counselling, Healing companions",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Follow up in their communities, Testmonies from communities, Reports from healing companions, interviews using phone calls, conduct surveys, questionnaire, Personal observations",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 95,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 5,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "2 from internal institutional generated, 95, AGLI, Karuna Center for Peace Building, Global Giving, 3, Members contribution",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "providing transport for an emergency case to the medical facility, Provide mutuelle de sante, We can even pay for medicines that they are prescribed by medical facilities.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "3years ending in 2023",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints, lack of technical staffs, lack of private rooms to offer our services(Listening rooms), Lack of sustainability of maintening our clients, Follow up on our clients",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "Stigma in some parents",
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 3. Additional space to administer mental health services 5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Regular meetings with community health workers helps them to trace those who uses the drugs and also recommendations",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "HEALING AND REBUILDING OUR COMMUNITY, Musanze District, Kimonyi sector, Kivumu cell, Nyamugari village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.healingandrebuildingourcommunities.org",
      "13. What is your phone number?": "+250788427028",
      "14. Names of the contact persons": "Maniraguha Solange",
      "15. Email address of contact persons": "mansol2019@gmail.com"
    },
    {
      "PVP ID": "A44",
      "Organization Name": "HOPE AND HOMES FOR CHILDREN",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support and financial direct support to their beneficiaries across the country. ",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help",
      "Beneficiaries": 578,
      "Total employees (including volunteers)": 1206,
      "Full time employees": 60.3,
      "2. What is your organization’s street address?": "KG 607 ST",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 605 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To be the catalyst for the global elimination of institutional care for children",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  g. Empowerment/Self-Help",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2002-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "N/A",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No Relationship with any",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 1206,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 5,
      "17. # Full time (Calculated)": 60.3,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 1,
      "17. % Volunteers": 94,
      "18. Of your total staff, how many are health care workers?": 9,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 578,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 5,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "They just refer them to the health centers and support them financially",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "No",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Majoring change, SPDS- Psychological and social re-assessment approach",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "1. Child registration. children were born from teen pregnancy, and a majority of them were not registered by the state. At the root of this issue lies the fact that most teen mothers are the result of sexual abuse, thus do not meet the requirement of the presence of the husband during the first antenatal visit.  2. They advocate for poor people to get proper justice concerning their deprived properties i.e land  3. Sexual Abuse. They advocate for any family member who has been sexually abused by hiring the lawyers and even pay them.  4. They also advocate for the children to have their rights of living in a family.  5. Adoption and guardianship. They advocate for the children with no parents through looking for them families to adopt or guardians",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100. Hope and Homes for children Uk",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, When they reach in a family and find one of the family member is sick of any disease, Hope and Homes for Children makes sure that the patient is taken to the health center or a hospital at their costs and follows up that person until he/she gets well",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Un-limited or Continuous funds",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Re-uniting children with their Families, Stigma, Poor Families, Giving children with disabilities cannot access medicines using Government health insurance( Mutual Health insurance) they have to pay 100%",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Re-uniting children with their Families, Stigma, Poor Families, Giving children with disabilities cannot access medicines using Government health insurance( Mutual Health insurance) they have to pay 100%",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We do work closely with Community Health Workers whom are affiliated to Rubona Health Center and Rwamagana District Hospital.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "HOPE AND HOMES FOR CHILDREN, KG 607 ST",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.hopeandhomes.org",
      "13. What is your phone number?": "+250788461380",
      "14. Names of the contact persons": "Nduwimana Emile",
      "15. Email address of contact persons": "enduwimana@hhcrwanda.org"
    },
    {
      "PVP ID": "A13",
      "Organization Name": "HOPE FOR LIVING ASSOCIATION",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They fight against drug abuse through evangelization",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 200,
      "Total employees (including volunteers)": 15,
      "Full time employees": 7.05,
      "2. What is your organization’s street address?": "KK 573St, Kigali",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "None",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Kicukiro Rwagamana",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Helping youth get out of drug abuse, rehabilitation, unity and reconciliation through evangelization",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2015-11-11",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "We serve people in their communities",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Ndera Hospital in case of referal for hospitalisation. Normally, we send them people with severe drug abuse consequences",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 15,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 47,
      "17. # Full time (Calculated)": 7.05,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 53,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 200,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "30",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "39",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "1",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We follow protocols that are used by our psychologist",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 1,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Follow-up and Narrative therapy",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (9) Housing services (13) Psychosocial rehabilitation services (14) Supported employment",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, Maranatha Church",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No but we plan to do that but this will depend on the funding",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The current project is ending in 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations and lack of transportation means to enable us reach easily to our clients",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "Radio Rwanda, Igihe.com, Voice of America",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "We don't work with them",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "We don't work with them",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We provide them with trainings, and the community health workers in turn do sensibilization .",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "HOPE FOR LIVING ASSOCIATION, KK 573St, Kigali",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "No website",
      "13. What is your phone number?": "+250786587861",
      "14. Names of the contact persons": "Museveni Jean Claude",
      "15. Email address of contact persons": "musevenijeanclaude437@gmail.com"
    },
    {
      "PVP ID": "A35",
      "Organization Name": "HOPETHIOPIA RWANDA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Psychosocial: Foster educational, physical, social emotional, economic and spiritual growth of the most vulnerable youth/young adults of Rwanda Eg: Rehabilitate the drug users, Faciliate people with MHI to receive medical care",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 37,
      "Total employees (including volunteers)": 21,
      "Full time employees": 10.29,
      "2. What is your organization’s street address?": "RNN15 KICUKIRO SONATUBE",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 2937 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Nyarugenge",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Empowering youth from poverty towards self-sustainability",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2011-10-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (3) Your offices (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 1,
      "13. (Other, please indicate)": "Hostel and Traditional home facility",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 21,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 49,
      "17. # Full time (Calculated)": 10.29,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 51,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 37,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(2) Depression, bipolar disorder, and other mood disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "60",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "93",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We receive youth who are discharged from IWAWA rehabilitation center, we proceed by rehabilitating them in vocational services",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "receive youth who are discharged from IWAWA rehabilitation center, proceed by rehabilitating them in vocational services",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Observe behavioral changes",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (8) Family psycho education (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, Own Generating income activities",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We hire private services providers to come at our place",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "hire private services providers to come at our place",
      "33. How long will the funded project last?": "We don't have a deadline as we generate our own income and don't have a plan to end it",
      "33. Self-funded": 1,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We lack professional training in the domain of Mental Health and we are also financially limited",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "They interact with them very much because spiritual leaders meets so many number of believers, so it is very easy and cost effective to deliver messages concerning drug abuse and other related issues",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They help them in identifying the people addicted to drugs and recommends them",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "HOPETHIOPIA RWANDA. KN 3 Rd. Niboye, Kicukiro Kigali",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We do not have satellite offices in other regions, but our employees/ volunteers who are based in the headquarter/ main office travel to other regions to provide services in the communities",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.hopethiopia.com",
      "13. What is your phone number?": "+250788355331",
      "14. Names of the contact persons": "Ronald ASIIMWE",
      "15. Email address of contact persons": "asiimwebayingana@gmail.com"
    },
    {
      "PVP ID": "A28",
      "Organization Name": "HOPITAL NEURO-PSYCHIATRIQUE (CARAES NDERA)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "A specialized Mental Health hospital",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 73679,
      "Total employees (including volunteers)": 329,
      "Full time employees": 305.97,
      "2. What is your organization’s street address?": "Gasabo district, Ndera village, Kibenga cell, Nezerwa village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 423 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Huye",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "The hospital's mission is to provide the best quality care with professionalism and devotion to psychiatric and neurologic patients from the whole country and to ensure teaching for mental health professionals",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1968-08-04",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(2) Multi-setting mental health facility (4) Partial hospitalization/day treatment (5) Psychiatric hospitalization or psychiatric unit of a general hospital (6) Residential treatment center for adults",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 1,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 1,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 1,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(1) Public / government",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "University of Rwanda: With University of Rwanda, We train students for increasing their professionalism and awareness in the faculty of medicine, especially in Mental Health. We also work with all District hospitals whereby we train HCPs in mental health and neurology. We also do mentorship on HIV and Mental Health integration",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 329,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 93,
      "17. # Full time (Calculated)": 305.97,
      "17. (# Full time employees coded)": 5,
      "17. % Part time": 2,
      "17. % Volunteers": 5,
      "18. Of your total staff, how many are health care workers?": 148,
      "18. (# health care workers coded)": 7,
      "19. How many total people does your organization currently provide mental health services for?": 73679,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "10",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "90",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "They just say we have got Mental complications like we have a persistent headache, insomnia and paranoia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "35",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "65",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "65",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "17",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "3",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We do Psycho-tropidrugs",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Psycho-tropidrugs",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Assess the level of the disease, Assess the level of drugs, Assess the behavioral change",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "Assess the level of the disease, Assess the level of drugs, Assess the behavioral change",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (5) Court-ordered outpatient treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "1",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We do advocacy in case of any kind of abuse eg: When a patient is deprived of his/her heritage",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 28,
      "31. Please characterize how your organization is funded? % Private Donations": 20,
      "31. Please characterize how your organization is funded? % NGOs": 4,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 48,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "48% is from our income, 12% from MINISANTE, 4%, CARAES BELGIQUE, ICB, Johnson&Johnson, 8% private donation",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Not Applicable as we are the one who treat them",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The government fund ends with the national budget but it is renewable",
      "33. Self-funded": null,
      "Faith based": null,
      "33. Funded through service fees/ rent": null,
      "No longer funded": null,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": null,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are financially limited that we can't satisfy the needs of people as we have a high command, Limited number of HCPs, We are challenged as we can't accommodate patients who don't have shelter",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Limited number of HCPs, We are challenged as we can't accommodate patients who don't have shelter",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "We work hand in had with CHWs especially in follow up of patients who have been hospitalised and discharged into the communities ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 0,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work hand in hand on a daily basis with the Community Health Workers and they help us in all means to do a follow up on our patients",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "HOPITAL NEURO-PSYCHIATRIQUE (CARAES NDERA), Gasabo district, Ndera village, Kibenga cell, Nezerwa village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Huye ",
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.careasndera.rw",
      "13. What is your phone number?": "+250780402332, +250788860907",
      "14. Names of the contact persons": "Ndayisenga Eraste",
      "15. Email address of contact persons": "ndayeraste2020@yahoo.fr"
    },
    {
      "PVP ID": "A30",
      "Organization Name": "HUMANITY INCLUSION",
      "Category (RBC)": "Epilepsy",
      "Core business  / Basis for categorisation ": "Mobilization and sensitization giving training and direct support to prevent epilepsy. They are concerned with the rights of vulnerable persons more specifically the rights of persons with disabilities.",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 9510,
      "Total employees (including volunteers)": 336,
      "Full time employees": 3.36,
      "2. What is your organization’s street address?": "KK 10 AVE",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o box747 kigali rwanda; www,hi-rwanda.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Karongi Ngorero Nyabihu Rubavu Rutsiro",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Humanity Inclusion Rwanda seeks a Rwandan society which will be inclusive, united and respectful of the rights of vulnerable persons more specifically the rights of persons with disabilities.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1994-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with all Hospitals in case of referring a patient for hospitalization and we provide some logistics such as mobility aids, we can't ignore that we mainly work hand in hand with them in preventing epilepsy. We also work with health centers in the same way as hospitals. We are also affiliated to University of Rwanda and they support in different research projects.",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 336,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 1,
      "17. # Full time (Calculated)": 3.36,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 99,
      "18. Of your total staff, how many are health care workers?": 5,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 9510,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Persons with disabilities",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "86",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "14",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, insomnia, ulcers, body pains, nightmares and unwillingness to work. They tend to relate these all to bewitchment",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": "ulcers",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "25",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "25",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We don't treat but we identify and refer our patients to hospitals",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "we identify and refer our patients to hospitals",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We have tools to assess depression, we use questionarre, Hospital asessment, Reports from communities and family, and we also visit them at home and use observation method.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We help sexually abused to get to one stop center and help them get legal representation. -Provide financial assistance for transport and getting the legal judgment - We also do advocacy in their locality",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, UNHCR, UNICEF, Agence Francaise de Developement, Federal Ministry for Economic Cooperation and Development",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes we provide medical insurance( mutuelle de sante) to our clients, transport and up keep.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "1-5 years dependending on the project",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "- Project time line., Financila constraints, Services that are usually needed but not provided for in the project, Mental health services are not decentralised.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "additional training of volunteers, Finances",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Radio talk shows,  District Authorities.",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NO",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "HUMANITY AND INCLUSION, KK 10 AVE",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": "An international organization funding a local organization or institution to provide mental health services in the community",
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.hi-rwanda.org",
      "13. What is your phone number?": "+250788512227",
      "14. Names of the contact persons": "Umuhoza Hortence",
      "15. Email address of contact persons": "h.umuhoza@hi.org"
    },
    {
      "PVP ID": "A21",
      "Organization Name": "INTERNATIONAL ALERT",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support and economic support to vulnerable people",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingh. Patient Advocacy",
      "Beneficiaries": 5915,
      "Total employees (including volunteers)": 165,
      "Full time employees": 1.65,
      "2. What is your organization’s street address?": "KG 632 ST-HOUSE NO: 4",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o. Box: 7063 Kigali-Rwanda email: Rwanda@international-alert.org Web site: www.international-alert.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Musanze Ngorero Rubavu Gisagara Huye Nyamagabe Nyanza Ruhango",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To break cycles of violence and to build sustainable peace",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1996-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Local Partner including ARCT RUHUKA",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "1.  ARCT RUHUKA 2. ASSOCIATION MODESTE & INNOCENT 3. NATIONAL RECONCILIATION COMMISSION (NRC)",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "1.  ARCT RUHUKA 2. ASSOCIATION MODESTE & INNOCENT 3. NATIONAL RECONCILIATION COMMISSION (NRC)",
      "16. How many total people including volunteers does your organization employ?": 165,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 1,
      "17. # Full time (Calculated)": 1.65,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 99,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 5915,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (14)  Persons with post-traumatic stress disorder",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "60",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "40",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "A chronic headache, fear, bad memories,isolation and insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "53",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "47",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "24",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "62",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "14",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "ARCT RUHUKA Protocoles",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "ARCT RUHUKA Protocoles",
      "28. What metrics does your organization use to measure success for your mental health programs?": "-Clinical supervision/Case management and progress reports (M&E)",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (12)  Peer-support groups (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "Funds from NGOs (Project proposals) and International Alert",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations and some specific medical facilities/fees",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "We lack personel dedicated to mental health and with experience",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "N/A",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "INTERNATIONAL ALERT, KG 632 ST-HOUSE NO: 4",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": "An international organization funding a local organization or institution to provide mental health services in the community",
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.international-alert.org",
      "13. What is your phone number?": "+250788616384",
      "14. Names of the contact persons": "Mayobera Homeur",
      "15. Email address of contact persons": "OMayobera@international-alert.org"
    },
    {
      "PVP ID": "A03",
      "Organization Name": "ITABWEHO",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They do trauma counseling especially to victims of gender based violance ",
      "Category (PVP)": "Women's support",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 30,
      "Total employees (including volunteers)": 8,
      "Full time employees": 4,
      "2. What is your organization’s street address?": "DR74 (Road to Rilima Prison)",
      "2. City": "Bugesera ",
      "2. Province  ": "Eastern Province ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P o Box 6484 KIGALI",
      "5. What province do you primarily operate in?": "Eastern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Bugesera",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "Trauma healing and life sustaining",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2014-07-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(6) Telemedicine/telehealth",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 1,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": null,
      "13. How would you best describe the type of facility? (1) Community mental health center": null,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": null,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": null,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": null,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": null,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": null,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": null,
      "13. How would you best describe the type of facility?  (9) Training center": " ",
      "13. How would you best describe the type of facility?  (10) Home/ community based": " ",
      "13. How would you best describe the type of facility? (8) Other, please indicate": null,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 50,
      "17. # Full time (Calculated)": 4,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 25,
      "17. % Volunteers": 25,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 30,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (9) Persons who have experienced trauma (14)  Persons with post-traumatic stress disorder",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Flashback, Nightmare, Anxiety, Eating disorders, Emotional disturbances, Body pains (Headache, Stomachache)",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Holistic approach such as mental, physical, economical, spiritual support",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Holistic approach such as mental, physical, economical, spiritual support",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We consider the decrease in symptoms",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (13) Psychosocial rehabilitation services (15)  Suicide prevention services (16)  Therapeutic foster care (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "60% from Japanese Churches",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "None",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "It will last for 10 years or more",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We have financial problems",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Distribution of brochures in the hotels",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "ITABWEHO, DR74 (Road to Rilima Prison)",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250789053324",
      "14. Names of the contact persons": "Hafashimana Jean Pierre",
      "15. Email address of contact persons": "hafashimanaegleq@gmail.com"
    },
    {
      "PVP ID": "A50",
      "Organization Name": "IZERE MUBYEYI ORGANIZATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychotherapy and special need education to the disabled children",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 53,
      "Total employees (including volunteers)": 11,
      "Full time employees": 7.7,
      "2. What is your organization’s street address?": "Kanombe Sector  Karama cell, Butare Village /BINGARO 900m  KK35st",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O.Box. 818 Kigali Rwanda, w.w.w.izeremubyeyi.org.rw Email: izeremubyeyi1center@gmail.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To advocate for the promotion of lrregal political and social of children /youth with intellectual disability and sustain capacity building of parents and member organizations.",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2004-11-24",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Physiotherapy, Kinestherapy, Education, Health, Culture, Sport&leisure trainings, Social, Integration awareness of the rights of people with disabilities",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with University of Rwanda, CMHS(College of Medicine and Health Sciences), whereby the internees from there provide kinestherapy for children with disability;  Health Center Busanza, Vaccination of Children and HIV Test.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 11,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 70,
      "17. # Full time (Calculated)": 7.7,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 30,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 53,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(15)  Children /adolescents with serious emotional disturbance (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Parents of those children.",
      "21. What types of mental health concerns does your organization address? ": "(11)  Persons with epilepsy (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Autist, Down syndrom",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "The symptom of forgetting so easily (Dementia), inability to focus",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "inability to focus",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "55",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "90",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "There is no clear protocol",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Progress assessment from the first day up to the current form; considering the process of Kinestherapy",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "considering the process of Kinestherapy",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (12)  Peer-support groups (13) Psychosocial rehabilitation services (16)  Therapeutic foster care (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 60,
      "31. Please characterize how your organization is funded? % Private Donations": 5,
      "31. Please characterize how your organization is funded? % NGOs": 15,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 20,
      "31. Explanations for funding": "15 JUMELAGE RHENANIE PALATINAT.",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, we pay for  prescribed medecines, Mobility Aids, NUDOR(Foundation Lilliane)",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "There is no clear timeline as we are given money depending on the expenses and the donor is the one who knows when to end the partnership",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "Determined by doners",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Infrastructures: We still need other buildings for the well function of the centre, now we have only one building, insufficient salaries of the staffs",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma from society and more so family members who neglect them",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They help us in knowing families and homes with people with disabilities",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "IZERE MUBYEYI ORGANIZATION, Kanombe Sector  Karama cell, Butare Village /BINGARO 900m  KK35st",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.izeremubyeyi.org.rw",
      "13. What is your phone number?": "+250786721626 / 789194766",
      "14. Names of the contact persons": "Jeanne Marie Auxiliatrice",
      "15. Email address of contact persons": "jmarieauxiliatrice@gmail.com"
    },
    {
      "PVP ID": "A51",
      "Organization Name": "LES ENFANTS DE DIEU",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Rehabilitation of  street children and their reintegratation into families. They also do psychosocial counseling.",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 250,
      "Total employees (including volunteers)": 21,
      "Full time employees": 17.64,
      "2. What is your organization’s street address?": "Ndera sector, Kibenga cell. Ururembo village. Almost 200m southbound the cell office",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "PO BOX 2045 KIGALI RWANDA",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Nyarugenge Kayonza Rwagamana Gicumbi Gisagara Huye Kamonyi Ruhango",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "We transform the lives of Rwanda street children by meeting their basic and phychosocial, providing them with education and skills, and reintegrating them to become valuable member of society.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Healing section, building relationship between children and employees., buiding resuriance",
      "11. What month and year was your organization founded?": "2004-06-08",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": null,
      "13. How would you best describe the type of facility?  (10) Home/ community based": null,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Rehabilitation center",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with College of Medicine and Health Sciences in Health related research projects. We also work with CARAES Ndera and our affiliation is based on referral",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 21,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 84,
      "17. # Full time (Calculated)": 17.64,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 16,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 250,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Children abandoned by their parents.",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Paranoia",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "100",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "95",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Therapeutic Hearing; Narrative counselling, Building relationship, Building resiliance, Economic empowment through vocational trainings",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Therapeutic Hearing; Narrative counselling, Building relationship, Building resiliance, Economic empowment through vocational trainings",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Physical screenning, Test HIV, Assessing behavior change.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "Physical screenning, Test HIV, Assessing behavior change.",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "They provide them with lawyers free of charge and follow up on the progress of the legal advocacy done",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 6,
      "31. Please characterize how your organization is funded? % Private Donations": 64,
      "31. Please characterize how your organization is funded? % NGOs": 30,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "34, Sulfo Rwanda, Steel Rwanda, Group Promotion, NGO: Global Funds, 30 Global Funds",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, we cover all of their expenses",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Project funded by Global Funds will end 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. We are financially limited, 2. We don't have self guarding policy",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "don't have self guarding policy",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "None",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "Never",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Never",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "CHW are used as Community based volunteers",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "LES ENFANTS DE DIEU, Ndera sector, Kibenga cell. Ururembo village. Almost 200m southbound the cell office",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.lesenfantsdedieu.org",
      "13. What is your phone number?": "+250783364738",
      "14. Names of the contact persons": "Hazabintwari Charles",
      "15. Email address of contact persons": "charleshazab@gmail.com"
    },
    {
      "PVP ID": "A22",
      "Organization Name": "LES NAZAREENS",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Through their rehabilitation homes, they help Mental Health patients and street children and  also assist vulnerable families ",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help  h. Patient Advocacy  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 157,
      "Total employees (including volunteers)": 307,
      "Full time employees": 6.14,
      "2. What is your organization’s street address?": "NM 13 St Musanze MUSANZE DISTRICT, MUHOZA SECTOR, MPENGE CELL, GIRAMAHORO VILLAGE",
      "2. City": "Musanze",
      "2. Province  ": "Nothern province",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "We don't know exactly because it will depend with finances but we plan to relocate in Cyuve sector, Rwebeya cell, Nyiraruhengeri village",
      "4. What is the mailing address of your organization (if different than Q2)?": "We use the postal address of the cathedral of Ruhengeri. Po Box 45 Ruhengeri",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Musanze",
      "8. What other districts do you cover?": "Burera Gakenke Rurindo",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Helping Mental Health patients and street children and attend to assist vulnerable families",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1993-08-15",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(6) Residential treatment center for adults (7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 1,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Ruhengeri hospital which provides us with professional advises. in turn we refer patients to them for treatment and hospitalisation. We help them to accommodate patients who do not have homes and families. they are also planning to assign a nurse for our patients. We are also affiliated to Ndera Hospital which train us and we refer patients to them for treatment and hospitalisation. .",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 307,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 2,
      "17. # Full time (Calculated)": 6.14,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 98,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 157,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (9) Persons who have experienced trauma (14)  Persons with post-traumatic stress disorder (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders 9) Substance abuse disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "70",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "30",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia, persistent Headache",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "80",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Admit the most vulnerable, 2. Referral to Hospital, 3. Rehabilitation done at our residential",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Admit the most vulnerable, 2. Referral to Hospital, 3. Rehabilitation done at our residential",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We consider the reduction of dosage prescribed, Evaluate the patients' level of confidence, Socialisation of patients in the community",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "consider the reduction of dosage prescribed",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (8) Family psycho education (9) Housing services (14) Supported employment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100%, Our own income generating activities",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for transportation, We pay for Mutual Health Insurance, We pay for medicines, We pay for hospitalization fees",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "There is no limit as it is our devotion as faith based association",
      "33. Self-funded": null,
      "Faith based": 1,
      "33. Funded through service fees/ rent": null,
      "No longer funded": null,
      " >5 years / renewable/ continuouse funding": null,
      "Funded for 1-5 years": null,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are financially limited, We have a challenge that most of patients whom we take care of do not have National Identity Cards which deny them some services",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "most of patients whom we take care of do not have National Identity Cards which deny them some services",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "They do not deal with Mental Health",
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "N/A",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "As a Christian Organization they work hand in hand with the spiritual leader as they help then in teaching children Christianity",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They work hand in hand with community health workers through maternal & child health, to promote WASH related behaviors and also Nutrition",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "LES NAZAREENS, NM 13 St Musanze MUSANZE DISTRICT, MUHOZA SECTOR, MPENGE CELL, GIRAMAHORO VILLAGE",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250789025148",
      "14. Names of the contact persons": "Frere Celestin Gahamanyi",
      "15. Email address of contact persons": "None"
    },
    {
      "PVP ID": "A40",
      "Organization Name": "LIWOHA- Life Wounds Healing Association",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They do trauma healing and life wounds healing",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 90,
      "Total employees (including volunteers)": 73,
      "Full time employees": 9.49,
      "2. What is your organization’s street address?": "KG 78 ST. 500 M from Kimironko Prison KG 11Ave",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "They want to build their own offices because where they are renting is very expensive, they have a plot of land in kimironko sector, Gasabo District still",
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O Box 6804 Kigali Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Nyarugenge Gisagara Huye Nyamagabe",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Our mission is to help young adults and adults with divorced and separated parents find deep spiritual healing. We do this by providing unique means for Christ to transform their life-draining wounds into life-giving resources of faith, hope, love, and joy for themselves, their relationships, and the Church.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2012-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, University of Rwanda- the founders of this organization are the University Lecturers, so they train other psychologists and help students in research",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 73,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 13,
      "17. # Full time (Calculated)": 9.49,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 1,
      "17. % Volunteers": 86,
      "18. Of your total staff, how many are health care workers?": 6,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 90,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "psychosomatic diseases such as headache, eye problems. loss of appetite",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "eye problems",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "20",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "3",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, They follow ministry of Health guidelines",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Evaluation i.e baseline, middle and endline survey. 2. DDC- Swedish Corporation",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "DDC- Swedish Corporation",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups Other, please specify",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Re-creative Activities",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "1. Gender-based violence. They do advocate for them in case of Gender-based violence. 2. Teen Mothers. They advocate for them in making sure that the victims do get justice because in many families when a girl-child gets an unwanted pregnancy, they get chased away from their parent's houses. Also, they help these young mothers to find those who really impregnated them and when these men are caught they face justice.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 5,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 95,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, They provide transport fee to any family member who gets sick of any disease as long as he/she is a member of the family in their cycle",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Swidish Government will end in Dec 2020, GIZ Dec 2021 and Corymbo 2023",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "The Demand is higher than the organization's capacity to serve them better, Stigma and Poor households",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": "The Demand is higher than the organization's capacity to serve them better, poor households",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "They collaborate with spiritual or faith based organizatios i.e Cultus Rwanda in Gicumbi and other churches and mosques",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They help them in tracing, screening and follow ups of the beneficiaries i.e Prostitutes",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "Life Wounds Healing Association (LIWOHA). KG11 Ave, KG 78 ST",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts",
      "7. In which districts do you have offices in Kigali?": "Gasabo",
      "8. In which districts do you have offices in the southern province?": "Huye ",
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.liwoha.org",
      "13. What is your phone number?": "+250788510958",
      "14. Names of the contact persons": "Simon Gasibirege",
      "15. Email address of contact persons": "sgasibirege@yahoo.com"
    },
    {
      "PVP ID": "A18",
      "Organization Name": "Mental Health Diginity foundation",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide clinical psychotherapy as their core business ",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 8110,
      "Total employees (including volunteers)": 1329,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "SH 60 ST In Catholic University Huye district",
      "2. City": "Huye",
      "2. Province  ": "southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 25 Huye - Rwanda",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Gisagara Huye Nyaruguru",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Is the contribution to the construction of inner peace of each person to facilitate social cohesion towards development.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2012-06-23",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "1. Rwanda Allied Health Profession council. The association provided the licence of accreditation to offer Phsyco-social support services  2. University of Rwanda. We offer internship for Clinical Psychology students   3. Humberg University - Germany. Offer internship fro their students who come as volunteers",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Rwanda Allied Health Profession council",
      "16. How many total people including volunteers does your organization employ?": 1329,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 8110,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Traumatic Grief",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Anti social behaviors and low self esteem",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "96",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "4",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Dizziness, Dried up, fear, night mares, body pain and insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "20",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, Non Violent communication, Inter-personal Therapy, Narrative Exposure Therapy (NET), Relaxation Exercise, Peace cycle Technic",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Non Violent communication, Inter-personal Therapy, Narrative Exposure Therapy (NET), Relaxation Exercise, Peace cycle Technic",
      "28. What metrics does your organization use to measure success for your mental health programs?": "PTSD Symptom Scale- Diagnosis and Evaluation (DSM-5), Screening for suicidal ideation, The Rosenberg Self-Esteem scale, Traumatic Grief Inventory and WHO-ASSIST V3.0",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "Diagnosis and Evaluation (DSM-5), Screening for suicidal ideation, The Rosenberg Self-Esteem scale, Traumatic Grief Inventory and WHO-ASSIST V3.0",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (4) Intensive case management (5) Court-ordered outpatient treatment (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "1",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Parenting Classes",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "1. They advocate for the vulnerable and Genocide Survivors to get housing  2. They also link the people with mental health problems and poor to the lawyers.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 70,
      "31. Please characterize how your organization is funded? % NGOs": 30,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "30. ZENTRUM FUR Psychotraumatology Hamburg and MISEREOR",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "NO",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "MISEREOR December 2020 and ZENTRUM December 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "lack of enough space, financial, Additional Staffs, lack of enough mental health centers in the country",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "None",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "Mental Health Diginity foundation, SH 60 ST In Catholic University Huye district. Huye district, Ngoma sector",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.mhdf.rw",
      "13. What is your phone number?": "+250788509187",
      "14. Names of the contact persons": "Uwitonze Therese",
      "15. Email address of contact persons": "uwitonze@yahoo.fr / info.mhdf@gmail.com"
    },
    {
      "PVP ID": "A74",
      "Organization Name": "MENTAL HEALTH NURSE STUDENTS ASSOCIATION LED ORGANISATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Through community outreach, these students help people out in the villages to cope with mental issues, of course through training",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training g. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 30,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "University of Rwanda, College of Health Sciences, Remera campus",
      "2. City": "Huye",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "University of Rwanda, Huye campus",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To promote and maintain mental wellbeing of Rwandan society especially that of school community",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2017-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community and schools based outreach",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are an association of volunteering students of the faculty of medicines and health sciences in the mental health nursing department at the the university of Rwanda",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 30,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "We just organize public talks on a range of mental health issues and we just talk of various concern",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "They organize seminars with a special focus groups in the community and schools",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "organize seminars with a special focus groups in the community and schools",
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services Other, please specify",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "The nature of education service is in organizing public talks for promoting mental health awareness",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, we use our own pocket money though sometimes the university provides us with a school bus for transportation",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "N/A",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "we lack financing, loss of members after graduation, lack of a running office and capacity building",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "MENTAL HEALTH NURSE STUDENTS ASSOCIATION LED ORGANISATION, University of Rwanda, College of Health Sciences, Remera campus",
      " 2. Which of the following best describes your organization?": "Other (Local Student Association providing Mental Health services in the communities)",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788500486",
      "14. Names of the contact persons": "Umuziga Marie Providence",
      "15. Email address of contact persons": "umuprov20@gmail.com"
    },
    {
      "PVP ID": "A65",
      "Organization Name": "MIZERO CARE ORGANISATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They have got psychologists who provide pschotherapy ",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingh. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 70,
      "Total employees (including volunteers)": 8,
      "Full time employees": 2.96,
      "2. What is your organization’s street address?": "KG11AV, 49 GASABO District,  REMERA sector",
      "2. City": "Kigali ",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "mizerocare.mcf@gmail.com, www.mizerocare.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Nyamagabe",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "PULLING THE YOUTH OUT OF ISOLATION TO BRING ABOUT REAL DEVELOPMENT",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Curative nature of the organization services is actually counseling and psychotherapy",
      "11. What month and year was your organization founded?": "2013-03-29",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community outreach organization. Work with youths and peer groups where they offer counseling  and psychosocial services",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation,",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 37,
      "17. # Full time (Calculated)": 2.96,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 25,
      "17. % Volunteers": 38,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 70,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide ",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "98",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "2",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "60",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "7",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "93",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Adhere to Cognitive behaviour theory: With this treatment protocol, we assess behavioural change through five steps: 1. Education, 2. Stress management, 3. Cognitive destruction, Scaling, 5. Homework",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Adhere to Cognitive behaviour theory",
      "28. What metrics does your organization use to measure success for your mental health programs?": "clinical supervision, Behaviour change/evaluate symptoms, Social cohesion, We evaluate the level of self reliance,",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (4) Intensive case management (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, Members Contribution",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No, We just do advocacy for them to get help from local authorities",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "We do not have a timeline about how long the project will last because we use our own money, we won't stop unless we will get tired to do so",
      "33. Self-funded": 1,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are financially limited, shortage of infrastructures",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 3. Additional space to administer mental health services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We invite Community Health Workers as a way to train them the way that they can help us monitor those people out in community",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "MIZERO CARE ORGANISATION, KG11AV, 49 GASABO District,  REMERA sector",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.mizerocare.org",
      "13. What is your phone number?": "+250788745992",
      "14. Names of the contact persons": "Irene Mizero",
      "15. Email address of contact persons": "mizerocare.mcf@gmail.com"
    },
    {
      "PVP ID": "A27",
      "Organization Name": "MOUCECORE",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Psychosocial services through mobilizing, organizing and empowering population through their projects: Church and Community mobilization, Water Hygiene and Sanitation, Ending poverty One Village at a time and Child Wholistic Development",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training g. Empowerment/Self-Help  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 215,
      "Full time employees": 32.25,
      "2. What is your organization’s street address?": "KG 686 ST1 KACYIRU, GASABO DISTRICT",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 2540 KIGALI/ RWANDA",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Kicukiro Gatsibo Kayonza Burera Nyamagabe Ruhango",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Mobilizing, organizing and equipping the local church and communities for wholistic and sustainable development, thereby bringing about spirituals",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1993-12-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Mainly focuses on drug related issues",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 215,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 15,
      "17. # Full time (Calculated)": 32.25,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 85,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Surveys and Campaigns in all the districts of Rwanda",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 1,
      "31. Please characterize how your organization is funded? % Private Donations": 32,
      "31. Please characterize how your organization is funded? % NGOs": 67,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "10. Church of the Redeemer,60. Tear fund and Earth Together",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Tear fund will end i April 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "No",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Provide spiritual support by Church Leaders",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We help each other to know traumatized people whom we help or support",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "MOUCECORE. KG 686. KACYIRU, KIGALI RWANDA",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.moucecore.org",
      "13. What is your phone number?": "+250788382558",
      "14. Names of the contact persons": "Bishop Alexis BILINDABAGABO",
      "15. Email address of contact persons": "abilindabagabo@gmail.com"
    },
    {
      "PVP ID": "A42",
      "Organization Name": "MUTIMAWURUGO CLARE",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "Fighting against drug abuse through songs. Focus is put on  sensitization  against drug abuse, especially the  youth",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 16,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "NYARUGENGE DISTRICT, KIMISAGARA SECTOR, KATABARO CELL, AKISHURI VILLAGE",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "When they get the funds after COVID-19. In Nyarugenge, Kimisagara kigali city",
      "4. What is the mailing address of your organization (if different than Q2)?": "N/A",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Is to reduce the use of drug abuse in Youths and have a better future of our Nation",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being ",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2018-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Through songs and poems in primary and secondary schools",
      "14. Is your organization a public private organization, or an NGO?": "(2) Private",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 16,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(2) Depression, bipolar disorder, and other mood disorders 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "3",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "97",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, Campaigns against the use of drug abuse in schools",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Campaigns against the use of drug abuse in schools",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Testimonies from the ex-drug users them selves",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 30,
      "31. Please characterize how your organization is funded? % Private Donations": 50,
      "31. Please characterize how your organization is funded? % NGOs": 20,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "20, Clardo",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "No specific funds.  They just get funds when they have an on going campaigns and when its done then that's the end of the funded project",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "Project based",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Transport, communication, supplies, lack of enough skills in handling drug abuse patients, Financial support, trained staff",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "communication",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma, Culture and beliefs which challenge the provision of services",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Advocacy for a better recognition by the government and support Mental Health Sector activities and even donors",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We train community health workers and in turn they reffer people to come seek for our services",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "MUTIMAWURUGO CLARE, NYARUGENGE DISTRICT, KIMISAGARA SECTOR, KATABARO CELL, AKISHURI VILLAGE",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788226281",
      "14. Names of the contact persons": "Uwineza Marie Claire",
      "15. Email address of contact persons": "ndamwemera@gmail.com"
    },
    {
      "PVP ID": "A58",
      "Organization Name": "NATIONAL REHABILITATION SERVICE",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They rehabilitate drug addicted people, especially youth through different transit centers. They provide them with vocational training",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 49,
      "Total employees (including volunteers)": 124,
      "Full time employees": 124,
      "2. What is your organization’s street address?": "Western Province  Karongi District, Bwishyura Sector  RSSB Building 3th Floor",
      "2. City": "Karongi",
      "2. Province  ": "Western province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O. Box 4062 Kigali Rwanda",
      "5. What province do you primarily operate in?": "Western Province",
      "6. What other provinces do you cover?": "City of Kigali Eastern Province Northern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Rutsiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Eradicating all forms of deviant behaviors by instilling positive behaviors, educating and providing professional skills.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Special pshychological rehabilitaion since people who come to the centres do so by force . They do not come voluntarily because they are first arrested by police, which then bring them to the centers. It is more or less coercive in nature",
      "11. What month and year was your organization founded?": "2017-04-28",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "NRC is a Government body with three rehabilitation centres  ie IWAWA in Rustiro, Gitagata in Bugesera and \ yamagabe inNyamagabe District . Holdees in these centres stay for a year where they are treated, undergo psycho therapy. medical treatment  and learn basic vocational skills. They ate then released in the society to start a transformed life",
      "14. Is your organization a public private organization, or an NGO?": "(1) Public / government",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes  1. Rubavu District Hospital for purposes of referring the patients  2. Kigeme District Hospital in Nyamagabe for referral purposes and Nyamata District hospital (ADEPR( in Bugesera 3. Districts and other local Government structures for the identification of delinquents  4. Government security forces for identification, arrest, screening of delinquents for taking to rehabilitation centers",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "Districts and other local Government structures, Government security forces",
      "16. How many total people including volunteers does your organization employ?": 124,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 124,
      "17. (# Full time employees coded)": 4,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 49,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "The organisation deals with all sorts of delinquents in the society like drug abusers, petty thieves, prostitutes . Such persons might have degenerated into delinquency because of many reasons some of which might be a result of  situations like \\domestic abuse, HIV , trauma past history of drug abuse etc",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "97",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "3",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "When police arrests delinquents, they are first screened at the police station to separate delinquents from criminals. The delinquents are then taken to transit centers for further screening and treatment. After three months, they are taken to taken to rehabilitation centers for a year .",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "delinquents are then taken to transit centers for further screening and treatment. After three months, they are taken to taken to rehabilitation centers for a year .",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Measuring the rate of proper  reinterpretation in the community as the final process of rehabilitation. If delinquents are able to perfect the skills, take orders and considered to go back to the community",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(4) Intensive case management (7) Education services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 100,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100. All other funding comes through the Government through the \\ministry of finance",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. This is done indirectly because medical care is provided at the sickbays of the rehabilitation centers free of charge and when referrals are done, the centers bear transport and treatment costs",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "The date is not known . However, with decrease in the rate of delinquency, the Government will prepare to phase out the project ,",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "Determined by government",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. Financial 2. Personnel",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work with community workers and provide them with necessary tools. we train them in identification, handling and evacuation, and also help GoR programs in the implementation of supporting community health workers",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "NATIONAL REHABILITATION SERVICE, \"Western Province  Karongi District, Bwishyura Sector  RSSB Building 3rd Floor",
      " 2. Which of the following best describes your organization?": "Other ( Governement Institution- National Commission )",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.nrs.gov.rw",
      "13. What is your phone number?": "+250788307568",
      "14. Names of the contact persons": "Niyitegeka Jean Marie Vianney",
      "15. Email address of contact persons": "jmv.niyitegeka@nrs.gov.rw"
    },
    {
      "PVP ID": "A61",
      "Organization Name": "NEVER AGAIN RWANDA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Provide psychosocial support and counseling whenever necessary",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 4880,
      "Total employees (including volunteers)": 5,
      "Full time employees": 5,
      "2. What is your organization’s street address?": "HOUSE 58, KG 9 Ave ",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "info@neveragainrwanda.org www.neveragainrwanda.org Po Box 4969 KIGALI, Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Nyarugenge Musanze Rutsiro Huye",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "A society that enjoys peace and development as well as social justice",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Supportive services are related to provision of Mutual Health Insurance,",
      "11. What month and year was your organization founded?": "2002-04-18",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Home based Health service",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to all District  Health centers in case of referring the patients",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 5,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 5,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 5,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 4880,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Teen mothers",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Persistent headache, Insomnia, paranoia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "57",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "43",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "20",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Selective guidelines with a focus on genocide survivors, confidentiality is key in counseling and therapy, Individual therapy, Healing program working with youth clubs and adult clubs)",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Selective guidelines with a focus on genocide survivors, Healing program working with youth clubs and adult clubs",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Baseline indicators, Feedback from beneficiaries, progress markers, willingness and understanding on healing, behavior change",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (7) Education services (12)  Peer-support groups",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100 from USAID, EU, Sweden Embassy, Global fund for children, Swiss agency for development and cooperation SDC, Interpeace, German cooperation, GIZ, ZFD",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, we pay for medical fees but for few cases and sometimes we pay for funeral services",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "funeral services",
      "33. How long will the funded project last?": "The project funded SDC and Interpeace will end in December 2020",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 1,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations, Limited number of beneficiaries per district, Holistic support",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": "Limited number of beneficiaries per district",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  3) Recommendations from Traditional Leaders 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "1",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A. But in some way or another, patients who end up in traditional healers are refered to our hospitals after they fail to deliver",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We have trained many community based health workers and we also work together with the government trained health workers especially in identifying the needy, and monitoring outr clients",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "NEVER AGAIN RWANDA, HOUSE 58, KG 9 Ave",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Northern/ Districts",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Huye ",
      "9. In which districts do you have offices in the western province?": "Ngororero",
      " 10. In which districts do you have offices in the northern province?": "Musanze ",
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.neveragainrwanda.org",
      "13. What is your phone number?": "+250 788 386 688",
      "14. Names of the contact persons": "Karemera Debby",
      "15. Email address of contact persons": "debby@neveragainrwanda.org"
    },
    {
      "PVP ID": "A14",
      "Organization Name": "OPPROMAMER",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They advocate for rights and well-being of people with Mental Health Illnesses. ",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive Advocacy",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Training g. Empowerment/Self-Help h. Patient",
      "Beneficiaries": 3792,
      "Total employees (including volunteers)": 8,
      "Full time employees": 8,
      "2. What is your organization’s street address?": "Ndera sector, Kibenga cell, Rugazi village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "None",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Kirehe Ngoma Nyagatare Gicumbi Musanze Karongi Rubavu Rutsiro Gisagara Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "RIghts and well-being of people with Mental Health Illnesses",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2003-05-29",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Our treatment is office and home based",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to CARAES Ndera: They provide us with HCP when we are to go in field, they sensitize and explain community about symptoms. They provide medicines to vulnerable among others (free of charge). They provide us with transportation means (car) when we are to go in field. They also help us do legal advocacy whereby they provide us with a free lawyer (CARAES' s lawyer)",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": null,
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 8,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 3792,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users  (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "People with Mental Disability",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "20",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "80",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Seeing Unusual images and Hearing unusual sounds",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "43",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "57",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "8",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "64",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "19",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "9",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Counselling the patient  2. Counselling the family  3. Refer a patient to hospital",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Counselling the patient  2. Counselling the family  3. Refer a patient to hospital",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We follow up on the socialisation of the patient in the community",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (15)  Suicide prevention services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We write to declare and claim for the rights of the patients with MHI. we collaborate with CARAES Ndera which provides us with their lawyer (Free of charge)",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We only do advocacy for social support in local authorities",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "We don't have any plan to end any of our projects and no limit because this is an organization through which we strive for our own good as people who have experienced MHIs and i think we will keep to finance it",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitation is the key challenge. we are also challenged by poor perception of people with stigma",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "N/A",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "OPPROMAMER, Ndera sector, Kibenga cell, Rugazi village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Kicukiro",
      "8. In which districts do you have offices in the southern province?": "Kamonyi/ Ruhango /Nyanza/ Nyaruguru /Gisagara",
      "9. In which districts do you have offices in the western province?": "Rubavu/ Rutsiro",
      " 10. In which districts do you have offices in the northern province?": "Gicumbi",
      "11. In which districts do you have offices in the Eastern province?": "Ngoma/Nyagatare ",
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250782783870",
      "14. Names of the contact persons": "Haragirimana Claver",
      "15. Email address of contact persons": "promorwanda@yahoo.com"
    },
    {
      "PVP ID": "A16",
      "Organization Name": "OSSARAU ( Organisation socio-culturelle des Sans Autrui au Rwanda D'Aujourd'hui)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "The core activitity is counseling. ",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help  h. Patient Advocacy  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 7,
      "Total employees (including volunteers)": 8,
      "Full time employees": 0.16,
      "2. What is your organization’s street address?": "Rutongo trading Centre , Gacyamo Village, Kigarama cell, Masoro Sector, Rulindo District",
      "2. City": "Rulindo ",
      "2. Province  ": "nothern province",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "When we get funds, we would like to relocate to Mutagata Village, Shingamuri Cell, Masoro Sector, Rulindo District . This is where we have the our activities .",
      "4. What is the mailing address of your organization (if different than Q2)?": "We dont have a box number. We only have an email adress: ossarau12@gmail.com",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Rurindo",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "1. Fight against the exclusion of the persons suffering from Mental illness 2. Contribute to social and professional reintegration of non-accompanied persons by organising cultural activities in their living areas 3. Allow the transmission and exchange  with  different professional/health , culture, social), Rwandese , Foreigners and participate in research in Mental Health for the psychic reconstruction of the non accompanied persons 4. Participate in keeping Rwandan cultural heritage by using culture in Psychic reconstruction of the non accompanied.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The organisation has income generating projects . These include faming ( Crop and animal husbandry as well as bee keeping ). Products from these activities are given to the beneficiaries to improve their diet and are sold to boost the incomes",
      "11. What month and year was your organization founded?": "2014-01-23",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "The facility is used as a point where people with mental problems come to for counselling and be helped to reintegrate into the society  . They have a room where they can rest during the day",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "They have a memorandum of understanding with Rutongo District  Hospital where they refer patients who are in acritical condition for further management. They also have an MOU with the District of Rulindo on policy streamlining",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 2,
      "17. # Full time (Calculated)": 0.16,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 2,
      "17. % Volunteers": 4,
      "18. Of your total staff, how many are health care workers?": 4,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 7,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(2) Seniors or older adults (3) Mothers needing mental health services/support (7) Persons with comorbid mental and substance use disorders (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (16) Persons with serious mental illness (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (6) Suicide  (8) Psychotic disorders, including schizophrenia (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "30",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "70",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "1. That they experience insomnia 2. Some complain of backache 3. That they have alot and uncontrolled anger",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "14",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "86",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "86",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "14",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes. 1. We offer services to people who have mental problems 2. We offer services to people who have got medical insurance 3. We also work with people who have been to a medical facility and confirmed to have MHI 4. Work with patients who are high functioning 5. Should be some one who can be able to look after livestock that the organization gives out in form of economic support",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "offer services to people who have mental problems, offer services to people who have got medical insurance, work with people who have been to a medical facility and confirmed to have MHI, Work with patients who are high functioning, Should be some one who can be able to look after livestock that the organization gives out in form of economic support",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We use narrative therapy to gauge the level of recovery and proper reintegration. We ask a set of questions to rate the patient on a scale of 1-10 where 1 means low success and 10 is high success",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": "use narrative therapy to gauge the level of recovery and proper reintegration",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (6) Diet and exercise counseling (13) Psychosocial rehabilitation services (15)  Suicide prevention services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Give the patients under our care the products from our farming projects ( Crop husbandry, Animal husbandry and bee keeping )",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100: We get all the Donations from a French organisation called \" Insertion Socio-Culturelle des sans autrui Au Rwanda d'Aujourd'hui). It is based in France",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "It is not set in terms of time frame. The donor is not a big organisation and support they give is not much . We only get money for rent, two casual laborer's and grass for the animals. The rest of the work is done on the volunteer basis and the patients we take care of",
      "33. Self-funded": 1,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 1,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "The biggest problem is lack of finances . It would be Okey if we got donors .",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "They deal with Pastors through training them in mental health disorders and trauma healing most especially during Genocide commemoration period.",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Yes, they work with them mostly when they want to send messages in the community they pass through them to convey their messages",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "OSSARAU ( Organisation socio-culturelle des Sans Autrui au Rwanda D'Aujourd'hui), Rutongo trading Centre , Gacyamo Village, Kigarama cell, Masoro Sector, Rulindo District",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788689905",
      "14. Names of the contact persons": "Muhoza Brandine",
      "15. Email address of contact persons": "hozablandy@gmail.com"
    },
    {
      "PVP ID": "A26",
      "Organization Name": "OYES (ORGANIZATION FOR THE YOUTH EMPOWERMENT AND STRIVE)",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They contribute to youth well-being; prevent drug abuse and rehabilitate the ex-drug users in community",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 200,
      "Total employees (including volunteers)": 6,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "Avenue de la Croix Rouge, Rubavu district, Gisenyi sector, Kivumu cell, Ubukerarugendo",
      "2. City": "Rubavu",
      "2. Province  ": "Western province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address. Please reach us out through email address: oyes2014@yahoo.com",
      "5. What province do you primarily operate in?": "Western Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Rubavu",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "Contribute to youth well-being; preventing drug abuse and rehabilitate the ex-drug users in community",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2013-11-22",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Home based psycho-social support and counseling and follow up",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Gisenyi Hospital: We refer the drug users with serious effects for treatment and hospitalization in case and they help us to train students against drug abuse",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 6,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 200,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (11)  Orphans 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "60",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Sensibilization to fight against drugs, We receive people who have been discharged from IWAWA Rehabilitation center , We receive the most vulnerable in the community",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": " receive people who have been discharged from IWAWA Rehabilitation center",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Self-Help assessment i.e. we assess the capacity of a beneficiary to fulfill his/her own duties Eg: Go back to school",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(8) Family psycho education (12)  Peer-support groups (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 40,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 60,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "60% is generated Membership contribution, 40% is provided by FUNDACIA PORT",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for transportation money when they have to go to hospital",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "There is a socio-economic project of rehabilitation that ended in 2018 but the ongoing project with FUNDACIA PORT has stopped abruptly but we had partnership to end in 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We are financially limited",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We work with comunity health workers in promoting awareness of our services.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "OYES (ORGANIZATION FOR THE YOUTH EMPOWERMENT AND STRIVE), Avenue de la Croix Rouge, Rubavu district, Gisenyi sector, Kivumu cell, Ubukerarugendo",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788530112",
      "14. Names of the contact persons": "Rukirande Musana Jean Bosco",
      "15. Email address of contact persons": "oyes2014@yahoo.com"
    },
    {
      "PVP ID": "A87",
      "Organization Name": "PARTNERS IN HEALTH/INSHUTI MU BUZIMA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide both mental health and psychosocial support ",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 5000,
      "Total employees (including volunteers)": 288,
      "Full time employees": 247.68,
      "2. What is your organization’s street address?": "KG 9 AV 46 NYARUTARAMA",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "rwanda@pih.org. P.BOX.3432",
      "5. What province do you primarily operate in?": "Eastern Province",
      "6. What other provinces do you cover?": "Northern Province",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kayonza Musanze",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To provide a preferential option for the poor in Health Care",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2005-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(2) Multi-setting mental health facility (4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 1,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "MH Decentralization at Health Centers",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, We have an affiliation with UGHE(UNIVERSITY OF GLOBAL HEALTH EQUITY), And also implements different healthcare activities(e.g. providing M.H Services, NCD etc. ) in all Health Centers from 3 Districts,(Kirehe, Kayonza, and Burera)",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 288,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 86,
      "17. # Full time (Calculated)": 247.68,
      "17. (# Full time employees coded)": 5,
      "17. % Part time": 14,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 21,
      "18. (# health care workers coded)": 3,
      "19. How many total people does your organization currently provide mental health services for?": 5000,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "People with depression, anxiety disorder",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": 100,
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": 40,
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": 60,
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "Not known",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "Don’t know ",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "Don’t know ",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "Don’t know ",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "yes we follow MOH Treatment guidelines",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "we conduct different evaluation reseach such as MESH Evaluation and PM+Evaluation, and we also use observation checklists to measure HC Nurses performance progress in MH Service.",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": 0,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": null,
      "31. Please characterize how your organization is funded? % Private Donations": null,
      "31. Please characterize how your organization is funded? % NGOs": null,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": null,
      "31. Please characterize how your organization is funded? % Other": null,
      "31. Explanations for funding": "Don’t know",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "yes by referring MH Patients to Ndera",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "referring MH Patients to Ndera",
      "33. How long will the funded project last?": null,
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "financial",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We mentor community health workers to work with girls with unwanted pregnancy, the abused and people with depression.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "PARTNERS IN HEALTH/INSHUTI MU BUZIMA, KG 9 AV 46 NYARUTARAMA",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": "Burera ",
      "11. In which districts do you have offices in the Eastern province?": "Kayonza/ Kirehe",
      "12. What is your website?": "www.pihrwanda.org",
      "13. What is your phone number?": "+250788292189",
      "14. Names of the contact persons": "Mukasakindi Hildegarde",
      "15. Email address of contact persons": "hmukasakindi@pih.org"
    },
    {
      "PVP ID": "A64",
      "Organization Name": "PRISON FELLOWSHIP RWANDA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Supports prisoners through psychosocial healing, peace building and reconciliation, restorative justice, spiritual resilence, crime prevention, human rights promotion and legal aid.",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 450,
      "Total employees (including volunteers)": 200,
      "Full time employees": 46,
      "2. What is your organization’s street address?": "KK 455 ST House 4",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "Rebero Kicukiro Rwanda",
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o Box 2098 Kigali Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Kayonza Gicumbi Musanze Rubavu Rusizi Muhanga Nyanza",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Fostering intervations that supports psychosocial healing, peace building and reconciliation, restorative justice, spiritual resilence, crime prevantion, human rights promotion and legal aid.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1995-07-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Communtiy Outreach Organisation where the organisation works with Prisoners and Ex prisoners who have reintergrated in the communities",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "NONE",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 200,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 23,
      "17. # Full time (Calculated)": 46,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 77,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 450,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "65",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "35",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Sleeplessness hatefull thinking.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "hateful thinking",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "60",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "46",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "14",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "20",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Questinairre, Home visits, observation, Rate the Languge used, Appearance, Judgement of issues,TRhinking and societal reports",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (4) Intensive case management (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We provide legal fees and provide lawyers -Pay for the final judgment document - Provide legal assistance to refugees",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 1,
      "31. Please characterize how your organization is funded? % Private Donations": 59,
      "31. Please characterize how your organization is funded? % NGOs": 40,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "40, Prison Fellowship International, UNHCR, USAID, UNDPR",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. Health Insurance, Transport to and from hospitals, assist children with special needs with education especialy those with downsydrome, autism etc",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Unlimited timeline for special needs children. Howevere we have some that run for 5 years.",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "- Financila constraints, -Demand out weighs our ability to provide the needed services,- We dont have a particula department with trained people who understand mental health cases and how to deal with them, -We have lots of drop outs from this program, Fear for stigmaisation especially the youth.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We have a big number of volunteers whom we encourage to work hand in hand with community health workers so that they can identify, evacuate and refer the patient to the health facility.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "PRISON FELLOWSHIP RWANDA, KK 455 ST, HOUSE 4",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyamagabe",
      "9. In which districts do you have offices in the western province?": "Karongi",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Bugesera/ Kirehe",
      "12. What is your website?": "www.pfrwanda.com",
      "13. What is your phone number?": "+250788842474",
      "14. Names of the contact persons": "Adelite Mukamana",
      "15. Email address of contact persons": "madelite06@gmail.com"
    },
    {
      "PVP ID": "A59",
      "Organization Name": "PsyRWanda Organisation",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide online counseling and psychotherapy",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  c. Curative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Training g. Empowerment/Self-Help  h. Patient Advocacy",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 15,
      "Full time employees": 1.05,
      "2. What is your organization’s street address?": "Online prototype of E-Counseling. But Huye district, Ngoma sector",
      "2. City": "Kigali ",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Email: josephka300@gmail.com Web site: www.psyRwanda.org Tweeter: @PsyRwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Rusizi Huye Nyanza",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Promote psychotherapy and help contribute to psychological well-being in Rwanda",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2012-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community based services; It is an association of clinical psychologists who go out to the communities and carry psycho-social  support ( Counselling ). Literally , they organise seminars in the communities on specific mental health topics and also do counselling. They are also called upon by other bodies to provide mental health services on some occasions eg during Genocide commemoration  days.",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 15,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 7,
      "17. # Full time (Calculated)": 1.05,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 93,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 14,
      "18. (# health care workers coded)": 2,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "The organisation does work with everybody in the communities as long as they are interested in their services because they are payed for . The organisation organises seminars in the communities and are also called upon to provide psycho-social support on some occasions.",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "40",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "60",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "A headache and insomnia or extreme  fear",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "70",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "13",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Not in place so far",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Baseline studies and assessment as well as progress reports",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (12)  Peer-support groups (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 70,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 30,
      "31. Explanations for funding": "30 of contribution of the organisation  members and fees from beneficiaries of the services",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No. Instead the patients pay for our services",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "2020-2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limitations of skills and lack of experienced therapists, a little number of beneficiaries due to financial limitations and infrastructures",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "PsyRWanda Organisation, Huye district, Ngoma sector",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda Other",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali ",
      "7. In which districts do you have offices in Kigali?": "Gasabo",
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "https://cmh.ur.ac.rw",
      "13. What is your phone number?": "+250788771289",
      "14. Names of the contact persons": "Uwizeye Prince",
      "15. Email address of contact persons": "princearielvii@gmail.com"
    },
    {
      "PVP ID": "A34",
      "Organization Name": "REBEJO ORGANISATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "provide tangible and psychological support to youth and  women that are in low income category.",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 275,
      "Total employees (including volunteers)": 13,
      "Full time employees": 6.5,
      "2. What is your organization’s street address?": "GASABO DISTRICT, RUSORORo sector, NYAGAHINGA CELL, GISHARU VILLAGE",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 5068 Kigali Rwanda",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Rwagamana Kamonyi",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Empowering youth, Helping out teen mothers",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2014-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(1) Community mental health center (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 1,
      "13. (Other, please indicate)": "We rent halls and organize special events",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Kabuga Health Center in case of referral for treatment and hospitalization",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 13,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 50,
      "17. # Full time (Calculated)": 6.5,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 30,
      "17. % Volunteers": 20,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 275,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (5) Sexual Abuse Survivors (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "anger,",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "25",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We have got patients' forms to fill",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "have got patients' forms to fill",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We do narrative hearing, We evaluate the symptoms that they manifest, We assess the capacity to respond (responses assessment),",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "narrative hearing",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We advocate for DNA test, and registration of children in local authorities.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 20,
      "31. Please characterize how your organization is funded? % NGOs": 80,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "80 from Rwanda Governance Board, Ministry of Gender and Family Promotion",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for Mutual Health Insurance, We finance income generating activities",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "finance income generating acitivies",
      "33. How long will the funded project last?": "The ongoing funded project called \"single mothers rehabilitation and check taking care project\" has ended in 2019 while there is another that we are about to sign the contract to get funds which is supposed to start in August this year and will end in July 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited funds",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Insufficient food while on strong medicines and Related drop-out",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Rehabilitation services",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "They do have meeting with the Traditional healers on a quarterly basis",
      "38. Meeting/ training with Traditional Healers": 1,
      "38. NO interaction with Traditional Healers": 0,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "They do have regular meetings with the spiritual Leaders and also train them",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "The community workers are among their volunteers they do help the organization through raising community awareness, follow up the patients conditions and those on medication and recommendations",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "REBEJO, GASABO DISTRICT, RUSORORo sector, NYAGAHINGA CELL, GISHARU VILLAGE",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.rebejo.org",
      "13. What is your phone number?": "+250788539710",
      "14. Names of the contact persons": "Ndacyayisenga Gaspard",
      "15. Email address of contact persons": "ndacyayisengagaspard@gmail.com"
    },
    {
      "PVP ID": "A54",
      "Organization Name": "Réseaux de Développement de femmes pauvres (RDFP)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They normally empower women through training, mentorship and  counselling.",
      "Category (PVP)": "Women's support",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 40,
      "Total employees (including volunteers)": 36,
      "Full time employees": 6.12,
      "2. What is your organization’s street address?": "Muhima Sector Nyarugenge District Kigali City KN 87 St",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No operational P.o. Box Email:  rdfp@gmail.com mukacres@gmail.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Ngoma Musanze Ngorero Nyabihu Rubavu Muhanga Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To advocate for rights and empower women and girls from poor/ vulnerable families",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2009-04-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "NGO involved in socioeconomic development for women and girls through community based service, counselling and psycho-social therapy",
      "14. Is your organization a public private organization, or an NGO?": "(1) Public / government",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "RDFP signed an MoU with the Independent University of Kigali (ULK)  on issues of developing  project proposals and implementation.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 36,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 17,
      "17. # Full time (Calculated)": 6.12,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 83,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 40,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide ",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": "Teen mothers and sex workers.",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "60",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "40",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia and chronic pains in the stomach",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "10",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "90",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "25",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "No",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Degree of socialization and behavior change among the beneficiaries of our services",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 1,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (11)  Legal Advocacy (12)  Peer-support groups (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "RDFP advocates for the change of laws and policies regulating alcohol and drugs such as TOBACCO control",
      "30. Advocate change of laws re: substance control": 1,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100 funds mobilization based on project proposals; The funds are from Mature and Justice NGO from Canada",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes by providing transport fees and medical insurance",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "2016-2021( i year from today)",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations and lack of skilled counselors",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  3) Recommendations from Traditional Leaders 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "1",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "The Community Health Workers recommend us",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "Réseaux de Développement de femmes pauvres (RDFP), \"Muhima Sector Nyarugenge District Kigali City KN 87 St\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788513975",
      "14. Names of the contact persons": "Crescence Mukantabana",
      "15. Email address of contact persons": "mukacresce@gmail.com"
    },
    {
      "PVP ID": "A89",
      "Organization Name": "Rwanda Demobolisation and Re-Intergration Commission (RDRC)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Support successful demobilization, social and economic reintegration of ex-combatants in their respective communities so as to realize national security, reconciliation and rehabilitation ",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 345,
      "Total employees (including volunteers)": 70,
      "Full time employees": 70,
      "2. What is your organization’s street address?": "KG 5 Ave, Kacyiru-Kigali",
      "2. City": "Kigali",
      "2. Province  ": "Muhoza Sector, Musanze District ",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "demobrwanda@yahoo.com",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "City of Kigali Eastern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Musanze",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "To support successful demobilization, social and economic reintegration of ex-combatants in their respective communities so as to realize national security, reconciliation and development.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1998-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Coomunity outreach organisation. Makes follow up on beneficiaries in the settlements  and also work closely with  Caraes Ndera for refferal purposes",
      "14. Is your organization a public private organization, or an NGO?": "(1) Public / government",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "It has collabaoration wit all Public Hospitals where patients are reffered to for proper management since the organisation does not have hopitalisation facilities",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 1,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 70,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 70,
      "17. (# Full time employees coded)": 3,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 11,
      "18. (# health care workers coded)": 2,
      "19. How many total people does your organization currently provide mental health services for?": 345,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "100",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": null,
      "24. Lack of apetite/ fatigue/ stress": null,
      "24. Insomnia/ nightmares": null,
      "24. Insecurity/ fear/ paranoia": null,
      "24. Depression/ axiety/ trauma/ anger": null,
      "24. Hallucinations/ loss of memory/ demetia": null,
      "24. Convulsion/ fainting": null,
      "24. Body pains": null,
      "24. Other (specify)": null,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "98",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Go are guided by the ministry of Health gudielines and protocols",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Dont know",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 100,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We provide Transport , Pay medical insurance and ensure that the Extra medical  bill is payed in full. (100%)",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "I have no idea because it is Governmetn funded",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "Funded by government",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints and support staff",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma",
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "We work with spiritual leaders as opinion leaders in community",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We consider them as stakeholders in sharing information and awareness and in as part of referral system at community Health centers",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "Rwanda Demobolisation and Re-Intergration Commission ( RDRC), KG 5 Ave Kacyiru-Rwanda",
      " 2. Which of the following best describes your organization?": "Other ( Governement Institution- National Commission  )",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.demobrwanda.gov.rw",
      "13. What is your phone number?": "+250788465624",
      "14. Names of the contact persons": "Ntazinda Narcisse",
      "15. Email address of contact persons": "nalisitan@gmail.com"
    },
    {
      "PVP ID": "A81",
      "Organization Name": "RWANDA INITIATIVE AGAINST DRUG ABUSE AND RELATED CRIMES (RIDARC)",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support and deal with prevention of drug abuse",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help",
      "Beneficiaries": 300,
      "Total employees (including volunteers)": 63,
      "Full time employees": 3.15,
      "2. What is your organization’s street address?": "Avega village, Gako cell, Kimironko sector Gasabo district ",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "ridarc.mr.rw",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Bugesera Kayonza Burera Musanze Nyabihu",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To empower Rwandan youths to fight, resisit the use of drugs, and promote the culture of peace and tolorence",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2012-11-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "No current facilty as the whole project have been stopped because of the pandemic",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with University of Rwanda whereby they would train our members",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 63,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 5,
      "17. # Full time (Calculated)": 3.15,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 95,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 300,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "70",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "30",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Ulcers, Headache, Insomnia, some cry alot, body pains, self-isolation, lack of concetration, dimentia.",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": "ulcers, lack of concentration",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We do mobilization in the community. We provide community based counseling",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "do mobilization in the community,  provide community based counseling",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Results from places where we refer them, observation, reports from family amd talking to them",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": "Results from places where we refer them",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (6) Diet and exercise counseling (7) Education services (8) Family psycho education (12)  Peer-support groups (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 30,
      "31. Please characterize how your organization is funded? % Private Donations": 25,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 45,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "45 Internally generated from membership and donations",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes We pay mutuelle de sante for them",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "We have one that is ending in this year2020, it was for 5 years",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": null,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints, Lack of Human resource, lack of awareness about mental health, we lack infrastructure and fear of stigma that is experienced by our clients.",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "The organisation os the one that goes out to the communities , who are the beneficiaries",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "None",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "RWANDA INITIATIVE AGAINST DRUG ABUSE AND RELATED CRIMES (RIDARC), Avega village, Gako cell, Kimironko sector Gasabo district",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250785026291",
      "14. Names of the contact persons": "Mushyikirano Joseph",
      "15. Email address of contact persons": "mushyjos@gmail.com"
    },
    {
      "PVP ID": "A68",
      "Organization Name": "RWANDA LEGACY OF HOPE",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They have three scopes of activities: Health, Education and Social. Concerning health, they bring specialist l in surgery with medical care. Their supportive services include provision of Mutual Health Insurance to their beneficiaries",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 719,
      "Total employees (including volunteers)": 106,
      "Full time employees": 6.36,
      "2. What is your organization’s street address?": "Nyarugenge district Kigali sector, Nyabugogo cell, Gakoni village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "We will move in the same sector of Kigali, we are constructing a center nearby our current office",
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address Email: contactus@rwandalegacyofhope.com Website: www.rwandalegacyofhope.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Kicukiro Kayonza Rwagamana Musanze Karongi Rubavu Huye Kamonyi Nyamagabe Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Provide specialist hospital in surgery with medical care",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The supportive services include provision of Mutual Health Insurance to their beneficiaries",
      "11. What month and year was your organization founded?": "2011-04-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community outreach",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 106,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 6,
      "17. # Full time (Calculated)": 6.36,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 94,
      "18. Of your total staff, how many are health care workers?": 100,
      "18. (# health care workers coded)": 6,
      "19. How many total people does your organization currently provide mental health services for?": 719,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 5,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (5) Post-traumatic stress disorder/Genocide-related MH disorders (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "98",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "2",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "95",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "27",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "25",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "8",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We have a got MOU with twelve districts that we operate in and MOU with CHUK and Ministry of Health and concerning our protocol we receive people that have been referred to us by these partners that we have got MOU with.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "We do follow up in the community and we also do callback checks to check on the recovery of the patients",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (7) Education services (9) Housing services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 20,
      "31. Please characterize how your organization is funded? % NGOs": 80,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "80, All Nations Ministries, 20% from private donors from UK, Germany, Holland, Sweden, Switzerland, Czech republic, Poland, Greece and Australia",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, We pay for Mutual Health Insurance",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": null,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "No timeline as the donors provide depending on the current activities to be carried",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited funds",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "3. Awareness of your organization services",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "There is no interaction between Mental health service in AHA Kiziba with traditional healers, unless psychoeducation for patient and family who abandoned Traditional Healers’ treatment.",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Community health worker are our first stakeholders because they  help AHA kiziba health center  to provide all needed health activities  within community such as :  Identification of patients in community, Patient follow up in the community, Provision of primary health care within the community, community case management, involvement in community based nutrition, and community health mobilization",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "RWANDA LEGACY OF HOPE, \"Nyarugenge district Kigali sector, Nyabugogo cell, Gakoni village\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.rwandalegacyofhope.com",
      "13. What is your phone number?": "+250783352664",
      "14. Names of the contact persons": "Rev. Osee Ntavuka",
      "15. Email address of contact persons": "rwandalegacyofhope1@gmail.com"
    },
    {
      "PVP ID": "A66",
      "Organization Name": "RWANDA PALLIATIVE CARE AND HOSPICE ORGANIZATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support. They treat mentally ill people. They also carry out  psychotherapy",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 70,
      "Total employees (including volunteers)": 11,
      "Full time employees": 2.9699999999999998,
      "2. What is your organization’s street address?": "Kicukiro District,  Gatenga sector,  Karambo cell,  Ruhuka village",
      "2. City": "Kigali ",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "rwandahospice@gmail.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Promote access to high quality palliative care for all people living in Rwanda",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions. g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2014-12-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 11,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 27,
      "17. # Full time (Calculated)": 2.9699999999999998,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 10,
      "17. % Volunteers": 63,
      "18. Of your total staff, how many are health care workers?": 7,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 70,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (6) Clients referred from the court/judicial system (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "10",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "90",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia, persistent headache, stomachache",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. RPCHO advocate for palliative care and hospice by using different strategies, to influence state or private stakeholders to design, adopt, implement or change practices for quality of life up to the end of life. RPCHO establish multidisciplinary team in identified health facilities aimed at providing palliative care services to patients.RPCHO provide and support Capacity building by helping integration of palliative care in Schools and Colleges of Medicine and other social medicine parties by using its expert members. 4. RPCHO contribute in training to empower multidisciplinary team at all level of health system. 5. RPCHO contribute in Mentorship to strengthen and update health system in Palliative Care services. 6. RPCHO Mobile Unit conduct home visits to the patients identified by the various health centers, district hospitals and referral hospitals. 7. RPCHO actively participate in all related activities of promoting Palliative Care including national palliative care cluster.  8. RPCHO develop and implement strategies to mobilize domestic and foreign resources to support all programs in palliative care and to unsure support for people suffering from life-threatening illness.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. RPCHO advocate for palliative care and hospice by using different strategies, to influence state or private stakeholders to design, adopt, implement or change practices for quality of life up to the end of life. RPCHO establish multidisciplinary team in identified health facilities aimed at providing palliative care services to patients.RPCHO provide and support Capacity building by helping integration of palliative care in Schools and Colleges of Medicine and other social medicine parties by using its expert members. 4. RPCHO contribute in training to empower multidisciplinary team at all level of health system. 5. RPCHO contribute in Mentorship to strengthen and update health system in Palliative Care services. 6. RPCHO Mobile Unit conduct home visits to the patients identified by the various health centers, district hospitals and referral hospitals. 7. RPCHO actively participate in all related activities of promoting Palliative Care including national palliative care cluster.  8. RPCHO develop and implement strategies to mobilize domestic and foreign resources to support all programs in palliative care and to unsure support for people suffering from life-threatening illness.",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Progress assessment and follow up",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups Other, please specify",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Collaborates with HOSPICE JOHN PAUL II located in Rusororo sector, where they refer patients that have been abandoned by family and in need of intensive palliative care",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "Training lawyers, religious leaders, journalists about human rights and child rights",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": "Training lawyers, religious leaders, journalists about human rights and child rights",
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100.   Hospice Without Boarders",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, We pay for Mutual Health Insurance that would enable them to access medication at Ndera Hospital (CARAES Ndera)",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "The project that we had has been affected by COVID 19 and which came at the time the funding was closing. We hope to get more funds when the pandemic is contained.",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "Uncertain",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitation, Insufficient space to administer our services",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They help us to give vaccines to children and in case of socio-economic categorization of children to find out who we can provide with Mutual Health Insurance",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "RWANDA PALLIATIVE CARE AND HOSPICE ORGANIZATION, \"Kicukiro District,  Gatenga sector,  Karambo cell,  Ruhuka village\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.rpcho14.org",
      "13. What is your phone number?": "+250788302755",
      "14. Names of the contact persons": "Dr. Blaise Uhagaze",
      "15. Email address of contact persons": "blaise.uhagaze@gmail.om, rpcho2013@yahoo.com"
    },
    {
      "PVP ID": "A20",
      "Organization Name": "RWANDA PSYCHOLOGISTS SOCIETY",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Capacity building for psychologists and advocacy on their behalf. They provide counseling to the genocide survivors",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training g. Empowerment/Self-Help  h. Patient Advocacy",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 5,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "KIYOVU, KN 35ST, EPR KIYOVU",
      "2. City": "KIGALI",
      "2. Province  ": "KIGALI CITY",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "In Aug, 2020, Kiyovu, Kigali",
      "4. What is the mailing address of your organization (if different than Q2)?": "rwandapsychology@gmail.com   www.rwandapsychology.org",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "City of Kigali Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To promote the practice of psychology through training, research and advocacy",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2015-08-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 1,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "They train the psychologists in all District hospitals on psycho -social support",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, They have a formal partnership with the western university ( Canada)",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 5,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "For them they train psychologists in all District hospitals who are then tasked with service delivery to other levels",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Domestic Violence",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "27",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "73",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Physical problems but in actual sense they are suffering from depression or other mental problems",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "43",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "57",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "20",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "DSM-5. Diagnostic and statistical manual for mental disorders",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "DSM-5. Diagnostic and statistical manual for mental disorders",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "Doing the campaigns against stigma and inequality",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 15,
      "31. Please characterize how your organization is funded? % Private Donations": 70,
      "31. Please characterize how your organization is funded? % NGOs": 15,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "15, GIZ, Western University and ARCT Ruhuka",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "no",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Sporadic",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": null,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "N/A",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "RWANDA PSYCHOLOGISTS SOCIETY, KIYOVU, KN 35ST, EPR KIYOVU",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.rwandapschology.org",
      "13. What is your phone number?": "+250788771289",
      "14. Names of the contact persons": "Pr. Sezibera Vincent",
      "15. Email address of contact persons": "vsezibera@gmail.com"
    },
    {
      "PVP ID": "A55",
      "Organization Name": "Rwanda Red-Cross Society",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Provide First Aid (PFA) and human rights assistance in some specific services. They do trauma counseling during commemoration. They reintegrate orphans into communitieas",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive",
      "10. What type of mental health services does your organization provide?  - All Other Services": " g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 1002,
      "Full time employees": 10.02,
      "2. What is your organization’s street address?": "Kacyiru Sector Gasabo District  KG 15 AV",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O. BOX: 425 Kgiali-Rwanda Email: rrcs@rwandaredcross.org Web site: www.rwandaredcross.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Provide first Aid (PFA) and human rights assistance in some specific services",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1963-07-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": null,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Just first Aid for children and psycho-social support during genocide commemoration. Red Cross has a presence in all districts with transit homes for orphans meant to be reunited with families. These transit homes are in all Districts except  Kirehe, Nyanza, Bugesera  and Gastibo",
      "14. Is your organization a public private organization, or an NGO?": "(2) Private",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "MINISANTE MINEMA UNICEF Canada Embassy ICRS IFRC",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "MINISANTE MINEMA UNICEF Canada Embassy ICRS IFRC",
      "16. How many total people including volunteers does your organization employ?": 1002,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 1,
      "17. # Full time (Calculated)": 10.02,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 99,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (9) Persons who have experienced trauma (12)  Refugees (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Fast Aid services but does not provide counselling services",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Do not know",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "No",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "None",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (8) Family psycho education (9) Housing services (12)  Peer-support groups (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100% International Federation of Red-Cross (IFRC) and other National Red-Cross organisations",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes such as Medical fees and insurance (Mituel de santé) and Transport",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "2020-2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "None",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "Rwanda Red-Cross Society, \"Kacyiru Sector Gasabo District  KG 15 AV\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo /Kicukiro/ Nyarugenge",
      "8. In which districts do you have offices in the southern province?": "Kamonyi /Muhanga/ Ruhango/ Nyanza/ Nyaruguru/ Nyamagabe/ Gisagara Huye ",
      "9. In which districts do you have offices in the western province?": "Karongi /Ngororero/ Nyabihu/ Nyamasheke /Rubavu/ Rusizi /Rutsiro",
      " 10. In which districts do you have offices in the northern province?": "Musanze/  Gicumbi /Burera / Rulindo /Gakenke ",
      "11. In which districts do you have offices in the Eastern province?": "Bugesera /Gatsibo/ Kayonza/ Rwamagana/ Kirehe/ Ngoma/ Nyagatare ",
      "12. What is your website?": "www.rrcs.org",
      "13. What is your phone number?": "+250788799260",
      "14. Names of the contact persons": "Umutoniwase Josiane",
      "15. Email address of contact persons": "josianewase@gmail.com / josiane.umutoniwase@rwandaredcross.org"
    },
    {
      "PVP ID": "A85",
      "Organization Name": "RWANDA VILLAGE COMMUNITY PROMOTERS",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They do psychotherapy on daily basis",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 20,
      "Total employees (including volunteers)": 253,
      "Full time employees": 2.53,
      "2. What is your organization’s street address?": "Urugwiro Village, Rango B Cell ,Tumba sector, Huye District",
      "2. City": "Huye",
      "2. Province  ": "SOUTHERN PROVINCE",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O.BOX 696 HUYE",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "Eastern Province Northern Province ",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Nyagatare Musanze Rurindo Gisagara Huye Nyamagabe Nyanza",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "RVCP aims to improve the living conditions of underprivileged people in village communities and build the capacity of involved youth.​",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Promotive: Radio talks show-counselling for Genocide servivors, Preventive: Poverty reduction, Curative: Specialists that comes to talk to genocide survivors at the place of work 'NVURA NKUVURE\" program where patients share experience, Rehabilitative: Teenegers with issues of  stigma, genocide survivors(Single mothers), Patient advocacy: Radio talks and raising awareness, Supportive services: Volunteers capacity building and making them Ambassadors in the communities. These are students  from pyschology department - who are given exposure through volunteering",
      "11. What month and year was your organization founded?": "2000-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(2) Multi-setting mental health facility (4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 1,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "This advocacy for patients : Day treatment is in terms of counselling givennon particular days at the offices.rms of counselling etc...",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, We work with: 1. University of Rwanda; This is a  Youth-run NGO that was started by University students and staff. The relationship  is that we get  volunteers from the student body who are psychologists , Mental health nursing, Medicine ; They alos collaborate with the university in terms of research. Board members and advisors are  advisers are professors form  the university.  2. CHUB: Research and advisory services 3. Health Center services: Other Programs.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "CHUB: Research and advisory services",
      "16. How many total people including volunteers does your organization employ?": 253,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 1,
      "17. # Full time (Calculated)": 2.53,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 99,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 20,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "100",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "4",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "65",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "1",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We apply the primary care behavioral health model i.e. applications to prevention, acute care and chronic conditiion management.",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "apply the primary care behavioral health model",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We do Survey interviews with target groups on a certain scale and gauge how far our programmes have impacted on them. We also use the same yardstick improve service delivery even when necessitates that we carry out some changes",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (7) Education services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We hire lawyers to help genocide survivors to regain property or to defend property ownership rights. This is in are cases though",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 60,
      "31. Please characterize how your organization is funded? % NGOs": 40,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "40, OMPRAKASH, KWIZERA, BVDA, JEFF-HEALTH, RVCP-FRANK FUNDS, TUSAA, IFMSA",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Health Insurance- Social economic Intervention, Transport when they come for counselling session.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "December 2020, Renewable after 1 year",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial;  Awareness; the society has low opinion on mental health services.",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 6. Other,  please specify",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "1",
      "36. Other,  please specify": "Lack of skills and expertise for some specific domain related to mental health",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Local authorities and opinion leaders",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "Never",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Never/We involve Religious leaders not healers",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Health workers are involved as volunteers and facilitators in the community",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "RWANDA VILLAGE COMMUNITY PROMOTERS, Urugwiro Village, Rango B Cell ,Tumba sector, Huye District",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Nyarugenge",
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": "Musanze ",
      "11. In which districts do you have offices in the Eastern province?": "Nyagatare ",
      "12. What is your website?": "www.rwandavcp.org",
      "13. What is your phone number?": "+250788502199",
      "14. Names of the contact persons": "Brandon Niyibizi",
      "15. Email address of contact persons": "brandonniyibizi@gmail.com"
    },
    {
      "PVP ID": "A02",
      "Organization Name": "Rwanda Youths Action for Development (RYAD)",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "Prevention of drug abuse and GBV",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 224,
      "Full time employees": 13.44,
      "2. What is your organization’s street address?": "Kicukiro District,  Masaka Sector, Mbabe Cell, Terimbere Village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "ryadkigali@gmail.com",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Kicukiro",
      "8. What other districts do you cover?": "Gasabo",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Our mission is to fight against HIV/AIDS and sexual illnesses, Drugs, Encourage and avoid a unplanned pregnancies among youths in Rwanda",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2005-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Activities are carried out at the office where clients are gathered for counselling and sensitization. The organisation also has community outreach programs",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "They have partnerships with the health centers in all the districts they are operating in. In any case of complications when they do counselling, they can refer their beneficiaries to the mentioned district health centres.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 224,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 6,
      "17. # Full time (Calculated)": 13.44,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 94,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia, Lack of appetite, eating disorder",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "55",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "They do peer education to the youths (Through Peer support groups), and when they meet with such case that needs to be handled medically, they refer them to the health centers",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "peer education to the youths (Through Peer support groups)",
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Monitoring and Evaluation i.e. they do baseline assessment, mid-line  and end-line as in doing progress follow up 2. Action plan (Set targets and set indicators to be used so as to track the progress)",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (7) Education services (12)  Peer-support groups (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 100,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100, Imbuto Foundation and Family Health International (FHI360)",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "The project funded by Imbuto foundation ended in 2016 while FHI360 in 2017",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 1,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Lack of infrastructure (Office), No budget to pay the workers . Although the office is still open , activities ceased in 2017 when the organisation got the last funding . There have not been activities for the last three years",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "Stigma ( Patients in most cases fear to be seen as seeking mental health services)",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "None",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "Rwanda Youths Action for Development (RYAD). Kicukiro District, Masaka Sector, Mbabe Cell, Murambi Village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We do not have satellite offices in other regions, but our employees/ volunteers who are based in the headquarter/ main office travel to other regions to provide services in the communities",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "N/A",
      "13. What is your phone number?": "+250788856354",
      "14. Names of the contact persons": "NIYONZIMA Jonathan",
      "15. Email address of contact persons": "niyonzimaj@yahoo.fr"
    },
    {
      "PVP ID": "A09",
      "Organization Name": "Rwandan Society Psychiatrist Mental Health Nurses",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They do psychotherapy and counseling",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training h. Patient Advocacy",
      "Beneficiaries": 150,
      "Total employees (including volunteers)": 142,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "KG 11 Av 47 ",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "When we shall get finances, we will have to set our offices somewhere else",
      "4. What is the mailing address of your organization (if different than Q2)?": "None",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Nyarugenge Muhanga",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To promote mental disease prevention and community outreach interventions, decrease stigma around mental disorders and support professional development for Mental Health nurses",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "We train Community Health Workers and nurses and we also supervise professionals",
      "11. What month and year was your organization founded?": "2011-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "The organisation is affiliated to University of Rwanda: With UR, we set outreach activities, together with UR, we train professionals. We are affiliated to Ndera Hospital and our relationship is that Ndera Hospital provides us with a car to help us with transportation in case we need to do community visits and we work hand in hand with them during preparations and sessions of training professionals.  It is also affiliated to RNMU which supports us with logistics",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 142,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 100,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 142,
      "18. (# health care workers coded)": 7,
      "19. How many total people does your organization currently provide mental health services for?": 150,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (5) Post-traumatic stress disorder/Genocide-related MH disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "90",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "10",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache and Stomachache",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "7",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "3",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We follow RBC protocols",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "We do follow-up and Clinical assessment",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (8) Family psycho education (13) Psychosocial rehabilitation services (15)  Suicide prevention services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "We are not funded by anybody but we have a project that has stopped due to COVID19 outbreak and which was supposed to end by this year 2020 but we are planning to extend it for another year to 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 1,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. Financial limitations  2. Lack of logistics and infrastructures especially our own offices",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "The Community health workers do recommend our organization during community work in case there is a case of a person in need of our services depending on the symptoms they have manifested.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "Rwandan Society  of Psychiatrist Mental Health Nurses, KG 11 Av 47",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Huye ",
      "9. In which districts do you have offices in the western province?": "Rubavu",
      " 10. In which districts do you have offices in the northern province?": "Musanze ",
      "11. In which districts do you have offices in the Eastern province?": "Ngoma",
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788500486",
      "14. Names of the contact persons": "Umuziga M. Providence",
      "15. Email address of contact persons": "umuprov20@gmail.com"
    },
    {
      "PVP ID": "A33",
      "Organization Name": "SAVE GENERATION ORGANISATION",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They deal mostly with GBV",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "g. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 240,
      "Total employees (including volunteers)": 8,
      "Full time employees": 6,
      "2. What is your organization’s street address?": "KG147 st, KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.o Box 2815 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Bugesera Nyagatare Kamonyi Nyamagabe",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To provide opportunities to children and Youth Integral development and healthy life in order to help them reach their full potetial.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2015-06-15",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 75,
      "17. # Full time (Calculated)": 6,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 25,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 240,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": "we also work with street children",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "75",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "25",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Sleeplessness, Lack of peace of mind, Headache, constant mestraul period(unending menstrual periods) lack of appettitte)",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "constant mestraul period",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "10",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "90",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We refer them to other organizations whom we work together in the legal field",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 25,
      "31. Please characterize how your organization is funded? % NGOs": 70,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 5,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "5 internally generated, 70% from Kvinna Till Kvnina  a Bridge for girls funds",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. We pay mutuelle de sante for some of our clients, transport and medicine",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "They range btn 1-5 years depending to whoever gives us money",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We have high demand from many people especially the youth but we are limited on finances, out reach is not easy because people dont want to open up, we also meet a problem where parents hide their children, we encounter ptogram dropout",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "out reach is not easy because people dont want to open up, we also meet a problem where parents hide their children, we encounter ptogram dropout",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "2. Additional training 3. Additional space to administer mental health services",
      "36. Additional staff": "0",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "0",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Community meetings/Inteko y'abaturage at cells and villages level",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "Never",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "Churches leaders are involved in community meetings as opinion leaders",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Community health workers are involved in community meetings and mobilization or to indemnify vulnerable children",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "SAVE GENERATION ORGANISATION, KG147 st, KIGALI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.sgorwanda.rw",
      "13. What is your phone number?": "+250783812690",
      "14. Names of the contact persons": "Yvette Nyinawumuntu",
      "15. Email address of contact persons": "yvettenyinamuntu@gmail.com"
    },
    {
      "PVP ID": "A71",
      "Organization Name": "Save the children International",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They have got a health centre in Mahama refugee camp and the facility has a unit of psychiatry",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative d. Palliative",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 31000,
      "Total employees (including volunteers)": 538,
      "Full time employees": 118.36,
      "2. What is your organization’s street address?": "KG9 AV, #23, Remera- Gasabo, Kigali",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O Box 2953, Kigali, Plot 204",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Gatsibo Kirehe Musanze Ngorero Gisagara Huye Nyamagabe Nyanza Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Save the Children works in development and humanitarian contexts with children and partners to help families, communities, and governments identify and use innovations to ensure all children survive, learn and are protected. Our mission is to inspire breakthroughs in the way the world treats children and to achieve immediate and lasting change in their lives.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  d. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 1,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1994-07-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(5) Psychiatric hospitalization or psychiatric unit of a general hospital (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 1,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "They have got a health centre in Mahama refugee camp and the facility has a unit of psychiatry",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We have a partnership with Kirehe District hospital and our relationship is based on referral",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 538,
      "(Total employees & volunteers coded)": 6,
      "17. % Full time ": 22,
      "17. # Full time (Calculated)": 118.36,
      "17. (# Full time employees coded)": 4,
      "17. % Part time": 1,
      "17. % Volunteers": 70,
      "18. Of your total staff, how many are health care workers?": 33,
      "18. (# health care workers coded)": 4,
      "19. How many total people does your organization currently provide mental health services for?": 31000,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "4",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "96",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Chronic headache and Insomnia",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "47",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "53",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "37",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "28",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "29",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "6",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, MOH Clinical Treatment and Guidelines",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 1,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Mental Health indicators including suicide rates, hospitalization rates (e.g., in-hospital stay or discharge data), utilization rates of health resources (e.g., number of psychiatrists or psychiatric beds per capita) and self-reported use of mental health services or disorders (e.g., derived from national surveys).",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 1,
      "28. Other": "Mental Health indicators including suicide rates, hospitalization rates (e.g., in-hospital stay or discharge data), utilization rates of health resources (e.g., number of psychiatrists or psychiatric beds per capita)",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(2) Chronic disease/illness management (3) Case management (6) Diet and exercise counseling (8) Family psycho education (12)  Peer-support groups",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "0",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 10,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 90,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "90, UNHCR, BPRM",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, We provide Mutual Health Insurance and cover of costs of hospitalization in case",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "RUNNING A HEALTH CENTER project IN MAHAMA CAMP is funded by UNHCR and is ending Dec, 2020 although we are going to extend for another year which is a rule of game where we renew every year and the project which is funded with BPRM will end in July 2021 but with option to renew",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "We don't have enough funds. Some service costs are not covered by UNHCR and our patients are not happy with us because we can't take a risk to provide them",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "2. Financial 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders",
      "35. Transportation": "0",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "Normally, advertisement in medicine is unethical but we use our Social Medias and organize Public talks.",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "N/A",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "Save the children International, KG9 AV, 23, Remera- Gasabo, Kigali",
      " 2. Which of the following best describes your organization?": "An international organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": "An international organization funding a local organization or institution to provide mental health services in the community. ",
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Kirehe",
      "12. What is your website?": "www.rwanda.savethechildren.net",
      "13. What is your phone number?": "+250788410180",
      "14. Names of the contact persons": "Mukazera Phoebe",
      "15. Email address of contact persons": "phoebe.mukazera@savethechildren.org"
    },
    {
      "PVP ID": "A75",
      "Organization Name": "SEXUAL HEALTH EDUCATION CENTER",
      "Category (RBC)": "Gender Based Violence (GBV)",
      "Core business  / Basis for categorisation ": "They deal with sexual health as they provide psychological support whenever in a need. They fight against GBV. They also fight against drug abuse",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingh. Patient Advocacy",
      "Beneficiaries": 4,
      "Total employees (including volunteers)": 13,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "suspended to have a permanent place - Closed the office due to COVID 19 Effects",
      "2. City": null,
      "2. Province  ": null,
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "N/A",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Nyarugenge",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Fostering a better health to young girls and boys to resist having unwanted preganies, to fight against drug use based on a firm foundation in our families.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) f. Training: Educating other mental health workers h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2013-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community outreach organization",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work with any schools and any churches",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "any schools and any churches",
      "16. How many total people including volunteers does your organization employ?": 13,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 13,
      "18. (# health care workers coded)": 2,
      "19. How many total people does your organization currently provide mental health services for?": 4,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (8) Persons with HIV or AIDS (11)  Orphans (15)  Children /adolescents with serious emotional disturbance",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "We just do promotion of mental health awareness",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache and stomach pain were mostly reported",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "25",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Not defined because we are a group of Mental Health workers that strive to promote awareness of mental health",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 5,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 95,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "95, Membership and contributions for a certain cause, 05, KOICA",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "N/A",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "We dont have any funded project",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "NA",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial constraints that is why we do not have a permanent place where we can work from",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "The organization is still small and not known by would be beneficiaries because it is not able to advertise its activities or be able to carry out community outreach on a big level   ries of its services . It is not able to advertise its activities",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "The organization has a small room where patients rest during their visits or when they come for counselling. However, the room is small and not enough for all the clients . Supplies is also a big challenge because we cannot be able to provide even a bottle of water to our patients when they come for our services",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "CHWs recommend u people with Mental Illness to the organization. The organization also works with CHWs in the communities to follow up on the patients and make sure they go by the Doctor's advice",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "SEXUAL HEALTH EDUCATION CENTER, closed temporarily due to COVID-19 effects",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788549308",
      "14. Names of the contact persons": "Mukakabanza Chantal",
      "15. Email address of contact persons": "shecrwa.org1@gmail.com"
    },
    {
      "PVP ID": "A07",
      "Organization Name": "SOCIETE DE L'APOSTOLAT CATHOLIQUE of 8th June 1973. All activities related to Mental Health are done under PALLOTTI CHILDREN HOPE CENTER of 1st January 2011 located in Gisagara district, Southern Province with Postal adress Po Box 700- Butare",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial services to and rehabilitate children with disabilities including mental disabilities. ",
      "Category (PVP)": "MH Treatment",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  c. Curative  e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training g. Empowerment/Self-Help  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 81,
      "Total employees (including volunteers)": 40,
      "Full time employees": 40,
      "2. What is your organization’s street address?": "KK 561 st Kigali FOR THIS SOCIETY BUT GISAGARA DISTRICT, KIBIRIZI SECTOR, RUHUHA CELL FOR THE PALLOTTI CHILDREN HOPE CENTER",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 1083 KIGALI",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "City of Kigali Northern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gisagara",
      "8. What other districts do you cover?": "Gasabo Kicukiro Musanze Huye Nyaruguru",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To provide education and training services to children with mildly and moderately intellectual disabilities in academic work, activities for daily living and pre-vocation skills in order to improve their life style",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Provision of Mutual Health Insurance and provision of first aid to help injured persons with disability",
      "11. What month and year was your organization founded?": "2011-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "The organization is affiliated to Kibilizi DH for refferal and hospitalization. And also the hospital trains us about how to take care of our clients and recently they have trained us about how to fight COVID19 in our residential.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 40,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 40,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 1,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 81,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy (19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Under 18 by age are our main target",
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (7) Psychosomatic problems (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "For people over 18, we do home visit as our age limit in the center is 18",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "100",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "They think of brain injury and disability whereby they comfuse dizziness with fainting related to epilepsy",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "95",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "We follow NCPD and NCC protocols",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": " follow NCPD and NCC protocols",
      "28. What metrics does your organization use to measure success for your mental health programs?": "Assessment: Baseline assessment, We carry out Progress Evaluation eg: If a child couldn't pick a pen and now can pick it and finally we have got Graduation program",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (16)  Therapeutic foster care (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We do advocacy for our clients in case of sexual abuse and family issues",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 100,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": null,
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for RMI test and for Mutual Helath Insurance for them to access medication",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "pay for RMI test",
      "33. How long will the funded project last?": "This is a catholic church faith based center",
      "33. Self-funded": 0,
      "Faith based": 1,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations as we have got a surplus demand which we cannot satisfy and poor perception of community about Mental Health services",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 1,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  3) Recommendations from Traditional Leaders 4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "1",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "They work with traditional Healers in giving them enough training inorder when they meet such kind of patient that has mental health, drug abuse to send them for further help.",
      "38. Meeting/ training with Traditional Healers": 1,
      "38. NO interaction with Traditional Healers": 0,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "They train the spiritual leaders through mental health awareness and GBV,",
      "39. Meeting/ training with Spiritual Healers on MHI": 1,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "they work hand in hand with Health workers through identifying the teen mothers in the community, tracing people with trauma and other mental health patients.",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "SOCIETE DE L'APOSTOLAT CATHOLIQUE, KK 561 st Kigali FOR THIS SOCIETY BUT GISAGARA DISTRICT, KIBIRIZI SECTOR, RUHUHA CELL FOR THE PALLOTTI CHILDREN HOPE CENTER",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.eglisecatholiquerwanda.org",
      "13. What is your phone number?": "+250788337418",
      "14. Names of the contact persons": "Utazirubanda Charles",
      "15. Email address of contact persons": "utazicharles2@gmail.com"
    },
    {
      "PVP ID": "A77",
      "Organization Name": "SOLID AFRICA",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support through feeding poor mental health patients, they support them financially to buy medicines and do advocate on their behalf and do whatever possible in terms of stress management",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": null,
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 8,
      "Full time employees": 4,
      "2. What is your organization’s street address?": "Gasabo district, Rusororo sector, Kigarama cell,  Nyagahinga village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "info@solidafrica.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Huye",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Help vulnerable patients in government hospitals, providing holistic approach to their changes,",
      "10. What type of mental health services does your organization provide? ": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The nature of supportive services is in providing facilities and in feeding the vulnerable patients in hospitals",
      "11. What month and year was your organization founded?": "2010-09-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "They plan everything from their offices",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We work closely with the University of Global health equity, CHUK, Kibagabaga Hospital, and Kanombe Military hospital, CHUB and Muhima Hospital. We facilitate vulnerable patients in the stated hospitals",
      "15. Hospitals (non specific) (1)": 1,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "CHUK,  CHUB, Kibagabaga Hospital, Kanombe Military hospital",
      "16. How many total people including volunteers does your organization employ?": 8,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 50,
      "17. # Full time (Calculated)": 4,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 50,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "Not Applicable as we assist whoever patient in need",
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "We do not address any concern particularly rather we help people with various health issues in the h",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "25",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "75",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "40",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "10",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Not defined",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 15,
      "31. Please characterize how your organization is funded? % Private Donations": 25,
      "31. Please characterize how your organization is funded? % NGOs": 50,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 10,
      "31. Explanations for funding": "50, Imbuto Foundation",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "None",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "No timelines",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "N/A",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Vocational service infrastructures and finances to establish a residential",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "2) Recommendations from community health workers  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "0",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "1",
      "37. Other, please specify": "Evangelization, Healing, Deliverance and Counselling",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "When on field we work hand in hand with Community Health Workers as they are the ones who can easily identify people in a need of our services",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "SOLID AFRICA, Gasabo district, Rusororo sector, Kigarama cell,  Nyagahinga village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.solidafrica.rw",
      "13. What is your phone number?": "+250785647102",
      "14. Names of the contact persons": "Kamaliza Isabelle",
      "15. Email address of contact persons": "isabelle@solidafrica.org, info@solidafrica.rw"
    },
    {
      "PVP ID": "A01",
      "Organization Name": "SOLID MINDS",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They are a mental health clinic that does psychotherapy on drug addiction, counseling including trauma counseling, couple counseling",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive  c. Curative  e. Rehabilitative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training h. Patient Advocacy",
      "Beneficiaries": 61,
      "Total employees (including volunteers)": 10,
      "Full time employees": 0.3,
      "2. What is your organization’s street address?": "KG 620 ST KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address but can be  reached  us out via email address info@solidminds.rw and website www.solidminds.rw",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "To provide high quality confidential mental health care and preventive services.",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "They hire consultants to train social wrokers, counsellors and community development workers.  They  also ( Consultants ) provide high quality counselling and psycho-social support services",
      "11. What month and year was your organization founded?": "2015-11-20",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 1,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Clinic (Counselling center)",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "No affiliation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 10,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 3,
      "17. # Full time (Calculated)": 0.3,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 96,
      "17. % Volunteers": 1,
      "18. Of your total staff, how many are health care workers?": 7,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 61,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 2,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (12)  Refugees (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems (8) Psychotic disorders, including schizophrenia 9) Substance abuse disorders (10) Neurological problems (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "We handle marriage conflicts i.e. we unite and re conciliate couples and also make sure that conflic",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "80",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "20",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Dimentia, paranoia",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 1,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "1",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "28",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "1",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "None ( Not provided )",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Recovery progress assessment report and regular follow up 2, Use questionnaire to elicit feedback from clients",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 1,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (2) Chronic disease/illness management (3) Case management (7) Education services (8) Family psycho education (10)  Illness management and recovery (15)  Suicide prevention services",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "1",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100% own generating income (Consultation and counseling fees)",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "We don't get any funds from anywhere. We generate our own funds through medical charges from the clients ( Consultation and therapeutic services)  )",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 1,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Mental Health services are not covered by insurance, Lack of expertise (on Narrative therapy), Limited experience for psychologists (Only young graduates are mostly available)",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Mental Health services are not covered by insurance",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "SOLID MINDs Rwanda, KG 620 ST KIGALI",
      " 2. Which of the following best describes your organization?": "Other(Mental Health clinic)",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.solidminds.rw",
      "13. What is your phone number?": "+250788304347",
      "14. Names of the contact persons": "Samuel Mundere Munderere",
      "15. Email address of contact persons": "samderere@yahoo.com"
    },
    {
      "PVP ID": "A43",
      "Organization Name": "Solidarity for the development of widows and orphans to promote self-sufficiency and livelihoods (SEVOTA)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Psychosocially empower widows and orphans / Rebuild human relationships that were destroyed during genocide ",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "b. Preventive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": " g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 6955,
      "Total employees (including volunteers)": 69,
      "Full time employees": 20.7,
      "2. What is your organization’s street address?": "Nyarusange Village, Taba Cell, Rukoma Sector Kamonyi District",
      "2. City": "Kamonyi",
      "2. Province  ": "Southern ",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "They have 10 ACRES of land where they  want to build their own offices in Kamonyi District, Gacurabwenge sector, Gihinga cell and Nyagasozi Village",
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 3607 kIGALI- RWANDA",
      "5. What province do you primarily operate in?": "Southern Province",
      "6. What other provinces do you cover?": "City of Kigali Eastern Province Northern Province Western Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Kamonyi",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Kirehe Musanze Karongi Ngorero Rubavu Kamonyi Muhanga Nyanza",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "To have a society in which human dignity is recognized and in which men, women and children all help one another so that all may prosper",
      "10. What type of mental health services does your organization provide? ": "b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1994-12-28",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Intervention strategy ( Psycho-social support",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "Yes, Texas Christian University in the USA. The university has Rwandan students which they send them for Internship programs during the student's research period.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 69,
      "(Total employees & volunteers coded)": 3,
      "17. % Full time ": 30,
      "17. # Full time (Calculated)": 20.7,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 5,
      "17. % Volunteers": 65,
      "18. Of your total staff, how many are health care workers?": 7,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 6955,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 7,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (3) Eating disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (7) Psychosomatic problems (10) Neurological problems",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "1",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "1",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "60",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "40",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache, Stomachache, Insignia, Backache, Loss of appetite, insomnia, night mares and fear",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 1,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 1,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 1,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "10",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "90",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "2",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "90",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "3",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes, They have a module that were developed by Dr. Lindolf that helps them to follow up the mental health patients",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 1,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Psycho-social Approach based on family and FAMA- Factor Association Meaning Action",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(6) Diet and exercise counseling (7) Education services (8) Family psycho education (11)  Legal Advocacy (12)  Peer-support groups (15)  Suicide prevention services (17) Vocational rehabilitation services",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "They first go through Government policies and when they find that those policies are in one way or another not in favour of their beneficiaries they go ahead and write the official letter to the Government concerned parties addressing the issue regarding their unsatisfactory, they also engage the press and Government officials and have a dialogue with them, so it is in this dialogue where they influence the policymakers to go and review or change the policies that do not favour their beneficiaries i.e women that were sexually abused during the Genocide in against the Tutsi in 1994.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 10,
      "31. Please characterize how your organization is funded? % Private Donations": 5,
      "31. Please characterize how your organization is funded? % NGOs": 85,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "85, Medica Mondiale, ARCS, UN WOMEN, PRO LITERACY, KVINNA KVINNA,",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "NO",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "KVINNA KVINNA will end in Dec 2020, arcs will ended, Medica Mondiale will end in Dec 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Lack of experts in mental health, Huge demand but not capable of serving them, Financial and Lack of their own infrastructure",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 1,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "None",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "None",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "None",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "Solidarity for the development of widows and orphans to promote self-sufficiency and livelihoods (SEVOTA). Nyarusange Village, Taba Cell, Rukoma Sector, Kamonyi District",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts ",
      "7. In which districts do you have offices in Kigali?": "Gasabo",
      "8. In which districts do you have offices in the southern province?": "Kamonyi /Muhanga",
      "9. In which districts do you have offices in the western province?": "Ngororero",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.sevotapeace.org",
      "13. What is your phone number?": "+250788520831",
      "14. Names of the contact persons": "Mukasarasi Godelieve",
      "15. Email address of contact persons": "mukasarasigoliva@gmail.com"
    },
    {
      "PVP ID": "A31",
      "Organization Name": "SOS",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Rehabilitations of children orphans with different psychological and mental issues",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 1076,
      "Total employees (including volunteers)": 16,
      "Full time employees": 16,
      "2. What is your organization’s street address?": "KG 573 ST KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 1168, KIGALI RWANDA",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kayonza Gicumbi Nyamagabe",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "our mission we build families for children in need,help them shape their own future and we share in development of their communities",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "community based",
      "11. What month and year was your organization founded?": "1979-12-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 0,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment (7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to university of Rwanda whereby they send us their students to train us and to provide counselling in schools. We are also affiliated to Ndera Hospital, and CHUK in case we have someone who needs to be referred to hospital",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 16,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 16,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 5,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 1076,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (11)  Orphans (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Self-isolation",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "self-isolation",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "100",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. Narrative therapy  2. Community network",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. Narrative therapy  2. Community network",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We follow international standards of CORS",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "follow international standards of CORS",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (4) Intensive case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (12)  Peer-support groups (13) Psychosocial rehabilitation services (15)  Suicide prevention services (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 95,
      "31. Please characterize how your organization is funded? % NGOs": 5,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "2% is from BPR, Radisson Blue, 95, International donors through German and Danish foundations, 3% is from GIZ",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "No",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "Projects with funds we receive from International donors will end in 2021",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited funds comparing the high demand, Limited skills in  clinical psychology",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "Patients follow-up, Community Awareness, Home visits",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "SOS, KG 573 ST KIGALI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyamagabe",
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": "Gicumbi",
      "11. In which districts do you have offices in the Eastern province?": "Kayonza",
      "12. What is your website?": "www.sos-childrensvillages.org",
      "13. What is your phone number?": "+250788838257",
      "14. Names of the contact persons": "Habyarimana Anne Candide",
      "15. Email address of contact persons": "annecandide.habyarimana@sos-rwanda.org"
    },
    {
      "PVP ID": "A12",
      "Organization Name": "STRIVE FOUNDATION Rwanda",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Promoting and advocating for social justice for the most vulnerable. They have got clinical psychologists to help their beneficiaries with MHIs. They do counseling",
      "Category (PVP)": "MH services",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   b. Preventive  c. Curative  e. Rehabilitative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Training g. Empowerment/Self-Help  h. Patient Advocacy  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 106,
      "Total employees (including volunteers)": 33,
      "Full time employees": 31.02,
      "2. What is your organization’s street address?": "KG 11 St KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "kigali city",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 4318 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gatsibo Ngoma Rwagamana Musanze Nyamasheke Rusizi Huye",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Promoting and advocating for social justice for the most vulnerable groups",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "They provide vulnerable people with cows",
      "11. What month and year was your organization founded?": "2003-07-15",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Kibogora District Hospital where we refer patients for advanced medication and hospitalisation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 33,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 94,
      "17. # Full time (Calculated)": 31.02,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 6,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 3,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 106,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (8) Psychotic disorders, including schizophrenia",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "1",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "99",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "1",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Whey the get a repetitive headache they think that they have been bewitched",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "40",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "50",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "35",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Raising awareness and Psycho-education",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "Raising awareness and Psycho-education",
      "28. What metrics does your organization use to measure success for your mental health programs?": "1. Diagnosis; person per person, we also do individual counselling as well as group counselling through peer groups and finally we have graduation phase. We also use tools such as Therapeutic thermometer",
      "28. Clnical/ psycho-social assessment": 1,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "use tools such as Therapeutic thermometer",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (7) Education services (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (16)  Therapeutic foster care",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We work hand in hand with MAJ",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": "We work hand in hand with MAJ",
      "31. Please characterize how your organization is funded? % Government": 10,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 90,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "70: EU, British High Commission,20: Global Fund",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for hospital charges, transportation, lunch money and lodges in any case",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "Project with EU ended in 2017 but we are renewing probably in 2021 (under negotiations). The project with Global Fund ends in 2021 but is renewable. We also have two long term project without a written duration about how long they will last",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations that we can't satisfy community needs. and limited skills of staff",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "proper mental health programs in place ( private space for women basically)",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Recommendations from Local leaders",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "The community health workers together with the organization's Volunteers work hand in hand to ensure that the people with mental health be followed, help those on medications, forming peer-groups in the community and also help them through healing processes",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "STRIVE FOUNDATION Rwanda, KG 11 St KIGALI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Huye ",
      "9. In which districts do you have offices in the western province?": "Nyamasheke/ Rusizi",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Gatsibo/ Rwamagana/ Ngoma",
      "12. What is your website?": "www.sfrwanda.org",
      "13. What is your phone number?": "+250788302273 / 788850199",
      "14. Names of the contact persons": "Ruzibiza Leopold",
      "15. Email address of contact persons": "ruzibileo@yahoo.fr / ruzibizaleopold16@gmail.com"
    },
    {
      "PVP ID": "A15",
      "Organization Name": "UMBRELLA OF ORGANIZATIONS OF PERSONS WITH DISABILITIES IN THE FIGHT AGAINST HIV&AIDS AND FOR HEALTH PROMOTION (UPHLS)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They  support people with disabilities through Psycho-social support.",
      "Category (PVP)": "Epilepsy",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive   e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": " f. Training g. Empowerment/Self-Help  h. Patient Advocacy  i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 20,
      "Total employees (including volunteers)": 1225,
      "Full time employees": 24.5,
      "2. What is your organization’s street address?": "KG 625 Av 7 KIMIHURURA",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 1493 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Kayonza Kirehe Ngoma Nyagatare Rwagamana Burera Gicumbi Gakenke Musanze Rurindo Karongi Ngorero Nyabihu Nyamasheke Rubavu Rutsiro Rusizi Gisagara Huye Kamonyi Muhanga Nyamagabe Nyanza Nyaruguru Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 1,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 1,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 1,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 1,
      "8. What other districts do you cover?/Karongi": 1,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 1,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 1,
      "8. What other districts do you cover?/Gisagara": 1,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 1,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Strengthen the capacities of member organizations, support, guide and coordinate programs to promote the rights of PWDs for inclusive services in HIV&AIDS, health and employability.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2006-09-21",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "DPO (Disability People Organization)",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "All District Hospital: We work with them in case we want to refer our beneficiaries for treatment and hospitalisation",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 1,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 1225,
      "(Total employees & volunteers coded)": 7,
      "17. % Full time ": 2,
      "17. # Full time (Calculated)": 24.5,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 98,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 20,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 1,
      "20. What populations does your institution primarily serve with mental health services?": "(7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (15)  Children /adolescents with serious emotional disturbance (16) Persons with serious mental illness (17)  Persons with epilepsy",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(11)  Persons with epilepsy (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Children born with disability",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "25",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "75",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Headache,",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "59",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "41",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "42",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "10",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "3",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "No specific technical guidelines",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "Monitoring and evaluation",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (4) Intensive case management (6) Diet and exercise counseling (7) Education services (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (16)  Therapeutic foster care Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "1",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Capacity building of Health care practitioners",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 40,
      "31. Please characterize how your organization is funded? % Private Donations": 1,
      "31. Please characterize how your organization is funded? % NGOs": 55,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 4,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "4% Internal income, Membership contribution",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes, we pay for Mutual Health Insurance, Pay for medication and we provide wheelchairs",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "provide wheelchairs",
      "33. How long will the funded project last?": "The current project will end in 2022",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Limited funds, Shortage of partners in Mental Health Programs",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 0,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Shortage of partners in Mental Health Programs",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Misconception",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "N/A",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "UMBRELLA OF ORGANIZATIONS OF PERSONS WITH DISABILITIES IN THE FIGHT AGAINST HIV&AIDS AND FOR HEALTH PROMOTION (UPHLS), KG 625 Av 7 KIMIHURURA, IMIHIGO CELL",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Muhanga",
      "9. In which districts do you have offices in the western province?": "Rubavu",
      " 10. In which districts do you have offices in the northern province?": "Musanze ",
      "11. In which districts do you have offices in the Eastern province?": "Gatsibo",
      "12. What is your website?": "www.uphls.org",
      "13. What is your phone number?": "+250788561683",
      "14. Names of the contact persons": "KARANGWA FRANCOIS Xavier",
      "15. Email address of contact persons": "karangwa.francois@uphls.org"
    },
    {
      "PVP ID": "A57",
      "Organization Name": "UMURAGE UHEBUJE",
      "Category (RBC)": "Substance abuse prevention and Management ",
      "Core business  / Basis for categorisation ": "They fight against drug abuse and do psychotherapy on the related issues",
      "Category (PVP)": "Youth/ Children",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive ",
      "10. What type of mental health services does your organization provide?  - All Other Services": null,
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 4,
      "Full time employees": 0,
      "2. What is your organization’s street address?": "KIGALI city, REMERA sector, Rukiri Cell  KG 11 AV",
      "2. City": "Kigali ",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "P0 box 7082 kigali Rwanda",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Rurindo",
      "8. What other districts do you cover?": "None-Only operate in primary district mentioned in Q.7",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 1,
      "9. What is the primary mission of your organization?": "To foster change in Rwandan society to be a society of love, have values and patriotic",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being ",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 0,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 1,
      "10. Other, please explain": "The organisation is dorminantly involved in sensitisation of the youth especially in schools on drug use awreness and prevention .",
      "11. What month and year was your organization founded?": "2016-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(8) Other, please indicate",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 1,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": "Community outreach organisation. Basically working with schools ie GS Shyorongi and GS Kijabagwe",
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "N/A",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 1,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 4,
      "(Total employees & volunteers coded)": 1,
      "17. % Full time ": 0,
      "17. # Full time (Calculated)": 0,
      "17. (# Full time employees coded)": 0,
      "17. % Part time": 0,
      "17. % Volunteers": 100,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (11)  Orphans",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "It is an organisation that offers sensitisation programs to the youth on awreness and prevention of",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "-18",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "35",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "65",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "0",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Sensitation programs in the  schools ie GS  SHYORONGYI AND GS KIJABAGWE",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": null,
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 0,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 100,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "100% contribution from members",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "N/A",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "N/A",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": "N/A",
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Have little time to visIt schools and talk to students, finanacial constraints, Limitation by members who work as volunteers knowledge about mental health and drug abuse",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": "Have little time to visIt schools and talk to students",
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "The Community Health Workers refer people to us",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "UMURAGE UHEBUJE, \"KIGALI city, REMERA sector, Rukiri Cell  KG 11 AV\"",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788456020",
      "14. Names of the contact persons": "Nkurikiye Emmanuel",
      "15. Email address of contact persons": "enkurikiye@gmail.com"
    },
    {
      "PVP ID": "A23",
      "Organization Name": "UMUZABIBU MWIZA ORGANIZATION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "Contributing to Rwandan community development through empowering vulnerable women mostly widows and orphans. Empowering vulnerable women  through different income generating activities. Follow-up on counselors whom they train across districts. ",
      "Category (PVP)": "Women's support",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative ",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 2854,
      "Total employees (including volunteers)": 198,
      "Full time employees": 130.68,
      "2. What is your organization’s street address?": "Musanze-Gisenyi road RN4 towards Gisenyi for 3.5km left (southbound) at INES Ruhengeri, bear right to 1.2km, MUSANZE DISTRICT, MUHOZA SECTOR, RUHENGERI CELL, SUSA VILLAGE",
      "2. City": "Musanze",
      "2. Province  ": "Nothern province",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "Po Box 126 Musanze",
      "5. What province do you primarily operate in?": "Northern Province",
      "6. What other provinces do you cover?": "None",
      "6. City of Kigali - ALL MENTIONS": 0,
      "6. Eastern Province - ALL MENTIONS": 0,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 1,
      "7. What district do you primarily operate in?": "Musanze",
      "8. What other districts do you cover?": "Musanze",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 0,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Contributing to Rwandan community development through empowering vulnerable women mostly widows and orphans.",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Reintegration into families for people who have lost or do not know the whereabouts of their own families",
      "11. What month and year was your organization founded?": "2007-01-01",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to INES Ruhengeri: During the commemoration we work hand in hand in provision of counselling for traumatized people. We also train students about trauma healing, counselling and psychoeducation. We are affiliated to Ruhengeri Hospital for referral and we get professional assistance from the hospital psychologist mostly in case of case of epilepsy. We also provided training to their nurses and other two staffs about counselling. We are also affiliated to Rutongo DH and we have got MoU where we train their staff and do supervision on counselling. We have got relationship with Ruhengeri prison as we provide their nurses with training about psychoeducation and we follow up when they have complicated cases that they cannot handle on their own",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "INES Ruhengeri, Ruhengeri prison",
      "16. How many total people including volunteers does your organization employ?": 198,
      "(Total employees & volunteers coded)": 4,
      "17. % Full time ": 66,
      "17. # Full time (Calculated)": 130.68,
      "17. (# Full time employees coded)": 4,
      "17. % Part time": 34,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 2,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 2854,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 6,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (12)  Refugees (14)  Persons with post-traumatic stress disorder (16) Persons with serious mental illness (17)  Persons with epilepsy 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "1",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "1",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "1",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders (11)  Persons with epilepsy",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "1",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "99",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "1",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Insomnia and permanent headache that they relate to bewitchment",
      "24. Headache/ stomachache": 1,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 1,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "30",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "5",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "60",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "30",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "5",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "1. In-take assessment 2. Building relationship  3. Assess the problem  4. Set goals  5. Intervention (We apply CBT i.e. Cognitive Behavioral Therapy",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1. In-take assessment 2. Building relationship  3. Assess the problem  4. Set goals  5. Intervention (We apply CBT i.e. Cognitive Behavioral Therapy",
      "28. What metrics does your organization use to measure success for your mental health programs?": "TFCBT: It has ten steps notably 1. Gathering information 2. Learning 3. Helping 4. Relaxing 5. Feeling 6. Seeking 7. Sharing One 8. Evaluating 9. Sharing Two 10. Living (Building life). We also have CBT with five steps notably: 1. Teaching 2. Stress management 3. Cognitive destruction 4. Scaling 5. Homework",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "TFCBT",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (7) Education services (8) Family psycho education (9) Housing services (11)  Legal Advocacy (12)  Peer-support groups (14) Supported employment",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We announce to them new laws that have been put in place. We provide them with legal advises. We work hand in hand with HAGURUKA which is specialized in laws. We also do accompaniment in court. We also inform RIB on abuse cases.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 1,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": "announce to them new laws that have been put in place, work hand in hand with HAGURUKA which is specialized in laws",
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 0,
      "31. Please characterize how your organization is funded? % NGOs": 65,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 35,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "35% is obtained from our own income generating activities, 65% is provided by the main True Vineyard Ministries headquartered in Texas, USA",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for Transport fees, We pay for hospitalized charges, we pay for medicines, We assist them financially in the period of commemoration to cover all expenses. We also provide glasses.",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 1,
      "32. Food": 1,
      "32. Hospital fees": 1,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": "provide glasses",
      "33. How long will the funded project last?": "We are given money depending on expenses (by the True Vineyard; our main headquarter) so, we are under them and do not have any time limit in the contract and about Vocational and Counseling project, we have got no plan to end it.",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 1,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Financial limitations, Limited number of staff comparing to a lot of responsibilities that we have",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "0",
      "35.  Other": "1",
      "Other,  please specify": "We are not aware of the barriers that people in community have as we do not do assessment rather we just organize public talks and no follow up",
      "36. What are your organizations’ primary unmet needs for mental health services?": "4. Supplies",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "0",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "0",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "we work with community health workers on certain projects to help us do mobilization",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "UMUZABIBU MWIZA ORGANIZATION, Musanze-Gisenyi road RN4 towards Gisenyi for 3.5km left (southbound) at INES Ruhengeri, bear right to 1.2km, MUSANZE DISTRICT, MUHOZA SECTOR, RUHENGERI CELL, SUSA VILLAGE",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda Other",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "We have employees or volunteers only in our headquarter/ main office",
      "6. In which regions does your organization have satellite offices?": null,
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": null,
      "12. What is your website?": "www.truevineyard.org",
      "13. What is your phone number?": "+250788400741",
      "14. Names of the contact persons": "Olive Muhawenimana",
      "15. Email address of contact persons": "olive.muhawenimana@truevineyard.org"
    },
    {
      "PVP ID": "A49",
      "Organization Name": "UR-SUPPORT ORGANIZATION (URUGERO RWIZA SUPPORT ORGANIZATION)",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial support through promoting rights of children, women and girls with disabilities, mental helath disabilities inclusive and also fight against violation of those beneficiaries.",
      "Category (PVP)": "Substance abuse prevention and management",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy",
      "Beneficiaries": 130,
      "Total employees (including volunteers)": 25,
      "Full time employees": 10,
      "2. What is your organization’s street address?": "Next to Kabuga bus station (Northbound), Gasabo district, Rusororo Sector, Kabuga I cell, Masango village",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "No postal address. Please reach to us via website: w.w.w.ursupport.org",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 0,
      "6. Southern Province - ALL MENTIONS": 0,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Rwagamana",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 0,
      "8. What other districts do you cover?/Nyarugenge": 0,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 0,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 0,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 0,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Capacity building for people with disability, helping them to overcome constraints for their better living.",
      "10. What type of mental health services does your organization provide? ": "e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 0,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "2013-12-03",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(4) Partial hospitalization/day treatment",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 1,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "We are affiliated to Masaka Health center and we work with them in case we have a got a person to refer for better treatment and hospitalization",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 1,
      "15.Health posts (6)": 0,
      "15.University (8)": 0,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 25,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 40,
      "17. # Full time (Calculated)": 10,
      "17. (# Full time employees coded)": 1,
      "17. % Part time": 0,
      "17. % Volunteers": 60,
      "18. Of your total staff, how many are health care workers?": 0,
      "18. (# health care workers coded)": 0,
      "19. How many total people does your organization currently provide mental health services for?": 130,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 3,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (3) Mothers needing mental health services/support (5) Sexual Abuse Survivors (6) Clients referred from the court/judicial system (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (11)  Orphans (13)  Persons with Alzheimer’s or dementia 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "1",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "9) Substance abuse disorders",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "0",
      "21. Other, please specify": 0,
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "20",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "80",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "70",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "15",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Our programs are keen to help in Social protection and provision of trainings about the consequences of drug abuse",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "help in Social protection and provision of trainings about the consequences of drug abuse",
      "28. What metrics does your organization use to measure success for your mental health programs?": "We do monitoring and evaluation , follow up on field to check on the progress and implementation of the activities that were created to empower our beneficiaries",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 1,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(7) Education services (8) Family psycho education (11)  Legal Advocacy (14) Supported employment (16)  Therapeutic foster care",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "0",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "We advocate for vulnerable people in the community to get Mutual Health Insurance as per government policy. particularly for Old people, teen mothers and people with disability",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 1,
      "30. Hire/pay lawyers for victims/ assist victims in court": 0,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 80,
      "31. Please characterize how your organization is funded? % NGOs": 20,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "20% from UNDP and JOC Rwanda",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "We pay for their Mutual health Insurance",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "UNDP fund is ending in this 2020 by December and JOC has already come to end",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 0,
      "Funded for 0-1 year": 1,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "Budget related constraints as we are limited of funds, we need more training for capacity building",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "0",
      "Other,  please specify": null,
      "36. What are your organizations’ primary unmet needs for mental health services?": "5. Community awareness for your services",
      "36. Additional staff": "0",
      "36. Additional training": "0",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "0",
      "36. Community awareness for your services": "1",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  4) Recommendations from Spiritual Leaders 5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "1",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "Recommendations from local leaders",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "N/A",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "N/A",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "They interact with them a lot by following up their beneficiaries and notification",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "UR-SUPPORT ORGANIZATION (URUGERO RWIZA SUPPORT ORGANIZATION), Gasabo district, Rusororo Sector, Kabuga I cell, Masango village",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": null,
      "9. In which districts do you have offices in the western province?": null,
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Rwamagana",
      "12. What is your website?": "None",
      "13. What is your phone number?": "+250788231811",
      "14. Names of the contact persons": "Hagenimana Gregoire",
      "15. Email address of contact persons": "grehagena@gmail.com"
    },
    {
      "PVP ID": "A04",
      "Organization Name": "UYISENGA NI IMANZI",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide education to vulnerable street children. They carry out psychotherapy. They respond and contribute to the rehabilitation of orphans of genocide and  respond to their needs through counselling.",
      "Category (PVP)": "Psychosocial support & rehabilitation",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": "a. Promotive  b. Preventive c. Curative e. Rehabilitative",
      "10. What type of mental health services does your organization provide?  - All Other Services": "f. Trainingg. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 400,
      "Total employees (including volunteers)": 36,
      "Full time employees": 36,
      "2. What is your organization’s street address?": "KG501 ST, KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "No",
      "3. If yes, when do you plan to move location and to where?": null,
      "4. What is the mailing address of your organization (if different than Q2)?": "UYISENGA NI IMANZI  PO BOX 7257 KIGALI",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 0,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Kicukiro Nyarugenge Kayonza Rwagamana Rubavu Kamonyi Nyanza",
      "8. What other districts do you cover?/Gasabo": 0,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 0,
      "8. What other districts do you cover?/Gatsibo": 0,
      "8. What other districts do you cover?/Kayonza": 1,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 0,
      "8. What other districts do you cover?/Nyagatare": 0,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 0,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 0,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 0,
      "8. What other districts do you cover?/Nyabihu": 0,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 0,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 0,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 0,
      "8. What other districts do you cover?/Nyamagabe": 0,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 0,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "UNM’s mission is to respond and contribute to the rehabilitation of orphans of genocide responding to their needs through counselling, capacity building and support to local initiatives in social economic development, direct assistance, medical care, education and training, empowering them to be involved in a process of solving their problems and participating in the country recovery and development.  The main activity is psychosocial empowerment and making vulnerable children and youth confident",
      "10. What type of mental health services does your organization provide? ": "a. Promotive: Promoting mental health awareness and mental well being  b. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.) c. Curative: Treating mental health conditions  e. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society f. Training: Educating other mental health workers g. Empowerment/Self-Help h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 1,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 1,
      "10. Curative: Treating mental health conditions ": 1,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 1,
      "10. Training: Educating other mental health workers": 1,
      "10. Empowerment/ Self-Help": 1,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 1,
      "10. Other, please explain": "Research on youth and child based trauma",
      "11. What month and year was your organization founded?": "2002-11-30",
      "12. How would you best describe the setting where you perform mental health services? ": "1) Home visit  (2) In community (3) Your offices (4) Outpatient or Inpatient facility (5) Residential",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 1,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 1,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 1,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 1,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(7) Residential treatment center for children",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 0,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 1,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "It is affiliated to University of Rwanda , University of RITZ ( England), University of Stanford ( US). The kind of collaboration is in Resaerch . They are also affiliated to Ndera Hospital ( CARAES) and Centre Ikizere . The kind of affliation here is to refer patients to caraes and centre ikizere for hospitalisation . They also make follow ups of the reffered patients and carry out psycho therapy at the hospital. They also o recieve experts at their rehabilitaion centre in Rubavu.",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 1,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": 0,
      "16. How many total people including volunteers does your organization employ?": 36,
      "(Total employees & volunteers coded)": 2,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 36,
      "17. (# Full time employees coded)": 2,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 8,
      "18. (# health care workers coded)": 1,
      "19. How many total people does your organization currently provide mental health services for?": 400,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 4,
      "20. What populations does your institution primarily serve with mental health services?": "(1) Transition age young adults (2) Seniors or older adults (3) Mothers needing mental health services/support (4) Domestic Abuse Survivors (5) Sexual Abuse Survivors (7) Persons with comorbid mental and substance use disorders (8) Persons with HIV or AIDS (9) Persons who have experienced trauma (10)  Persons with traumatic brain injury (11)  Orphans (13)  Persons with Alzheimer’s or dementia (14)  Persons with post-traumatic stress disorder (15)  Children /adolescents with serious emotional disturbance 18)  Ex-drug users ",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "1",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "1",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "1",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "1",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "1",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "1",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "1",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "1",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "1",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "1",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "1",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "1",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "0",
      "20. (Other, please specify)": null,
      "21. What types of mental health concerns does your organization address? ": "(1) Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias (2) Depression, bipolar disorder, and other mood disorders (4) Personality disorders (5) Post-traumatic stress disorder/Genocide-related MH disorders (6) Suicide  (7) Psychosomatic problems 9) Substance abuse disorders (12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "1",
      "21. Depression, bipolar disorder, and other mood disorders": "1",
      "21. Eating disorders": "0",
      "21. Personality disorders": "1",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "1",
      "21. Suicide ": "1",
      "21. Psychosomatic problems": "1",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "1",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "Children who get gripped with  extreme fear",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "100",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "Yes",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": "Depression that patients fail to identify the source, physical illnesses like (psychosomatic illness eg Insomona), people who come to seek counselling for social services eg they have failed to study or concerntrate on work.",
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 1,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": "failed to study or concentrate on work",
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "65",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "35",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "35",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "45",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "20",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "Yes;  They are in Six  sectors ie 1.  Diagnosis , 2.The use of tools and forms for patients, 3.Treatment plan ( Holistic way and tools) 4. Linking plan  ie linkage between family , HCPs, Community health Workers and Hospitals 5. Graduation plan ( Discharge of the Patients ) 6. Follow up ( This is done in the homes of patients )",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 0,
      "27. Other": "1.  Diagnosis , 2.The use of tools and forms for patients, 3.Treatment plan ( Holistic way and tools) 4. Linking plan  ie linkage between family , HCPs, Community health Workers and Hospitals 5. Graduation plan ( Discharge of the Patients ) 6. Follow up ( This is done in the homes of patients )",
      "28. What metrics does your organization use to measure success for your mental health programs?": "They use two systems ie Theraupatic out come management system. The tools used here are Theraupetic thermometer tool and therapautic outcome form .",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": "Theraupetic thermometer tool and therapautic outcome form",
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(1) Assertive community treatment (3) Case management (6) Diet and exercise counseling (7) Education services (8) Family psycho education (9) Housing services (10)  Illness management and recovery (11)  Legal Advocacy (12)  Peer-support groups (13) Psychosocial rehabilitation services (14) Supported employment (15)  Suicide prevention services (16)  Therapeutic foster care (17) Vocational rehabilitation services Other, please specify",
      "29. Assertive community treatment": "1",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "1",
      "29. Education services": "1",
      "29. Family psycho education": "1",
      "29.Housing services": "1",
      "29.Illness management and recovery": "1",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "1",
      "29. Psychosocial rehabilitation services": "1",
      "29. Supported employment": "1",
      "29. Suicide prevention services": "1",
      "29. Therapeutic foster care": "1",
      "29. Vocational rehabilitation services": "1",
      "29. Other, please specify": "1",
      "29. Other, please specify": "Empowering rehabilitation centres through provision of training and logistics",
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "Hire lawyers to help clients with legal advice. Teh organisation also does coaching for the cleints  ( accompanment during court sessions )",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 1,
      "31. Please characterize how your organization is funded? % Private Donations": 1,
      "31. Please characterize how your organization is funded? % NGOs": 98,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "3%  from UNICEF, European Union, DFID, CONCLIF, OWN INCOME",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "Yes. They provide medical, give additional financial support to access medication",
      "32. Medical/Health Insurance": 1,
      "32. Medicine": 1,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 0,
      "32. Other": 0,
      "33. How long will the funded project last?": "1. The first project is Arts and Centre Project  for psychological and healing exbition will end in 2024.  2. The second is the Sustaining re-intergration of street children into families and communities whic started in 2018 and will end in 2021. 3. The third one the Modeling family based intrgrated care for young people . the cureent will end in 2021 4. The fourth is  creating community based Mental Health support and healing species for teen mothers  will end in 2022. 2022.",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "1. Financial constraints 2. Technical limitaions ( lack of professional trainings)",
      "34. Limited resources/ funding": 1,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "1. Transportation 2. Financial 3. Awareness of your organization services 4. Awareness and understanding of Mental Health Illnesses and symptoms 5. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders 6. Poor perceptions or fear of Mental Health Services / Organizations 7. Other,  please specify",
      "35. Transportation": "1",
      "35. Financial": "1",
      "35. Awareness of your organization services": "1",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "1",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "0",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Stigma. They consider them to be mad and useless and therefore fear to",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 4. Supplies",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "0",
      "36. Supplies": "1",
      "36. Community awareness for your services": "0",
      "36. Other": "0",
      "36. Other,  please specify": null,
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community ",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "0",
      "37. Other, please specify": null,
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "We don't work with Traditional Healers",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "The help us in counselling and behaviour change",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 1,
      "39. NO interaction with Spiritual Healers": 0,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "We Provide mental health training for them, then they help us in daily follow up of our beneficiairies.",
      "40. Provide training to CHW ": 1,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 1,
      "40. Does not work with CHW": 0,
      "1.  What is the name and address of your organization?": "UYISENGA NI IMANZI, KG501 ST, KIGALI",
      " 2. Which of the following best describes your organization?": "A local organization providing mental health services to beneficiaries in Rwanda",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": null,
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Southern/ Districts Western/Districts  Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": null,
      "8. In which districts do you have offices in the southern province?": "Nyanza",
      "9. In which districts do you have offices in the western province?": "Rubavu",
      " 10. In which districts do you have offices in the northern province?": null,
      "11. In which districts do you have offices in the Eastern province?": "Rwamagana",
      "12. What is your website?": "www.uyisenganmanzi.org.rw",
      "13. What is your phone number?": "+250788305007",
      "14. Names of the contact persons": "Uwihoreye Chaste",
      "15. Email address of contact persons": "uyisenga@gmail.com / uwichaste@yahoo.fr"
    },
    {
      "PVP ID": "A47",
      "Organization Name": "WORLD VISION",
      "Category (RBC)": "MHS, treatment, Psychosocial support and Rehabilitation ",
      "Core business  / Basis for categorisation ": "They provide psychosocial services",
      "Category (PVP)": "Social development / health programs",
      "10. What type of mental health services does your organization provide?  - MH & Psychosocial Services": null,
      "10. What type of mental health services does your organization provide?  - All Other Services": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "Beneficiaries": 0,
      "Total employees (including volunteers)": 350,
      "Full time employees": 350,
      "2. What is your organization’s street address?": "KG 5 AVENUE KIGALI",
      "2. City": "Kigali",
      "2. Province  ": "Kigali City",
      "3. Do you have plans to move location? ": "Yes",
      "3. If yes, when do you plan to move location and to where?": "Not yet but they are planing to build their offices in near future in kacyiru still",
      "4. What is the mailing address of your organization (if different than Q2)?": "P.O BOX 1419 KIGALI RWANDA",
      "5. What province do you primarily operate in?": "City of Kigali",
      "6. What other provinces do you cover?": "Eastern Province Northern Province Western Province Southern Province",
      "6. City of Kigali - ALL MENTIONS": 1,
      "6. Eastern Province - ALL MENTIONS": 1,
      "6. Northern Province - ALL MENTIONS": 1,
      "6. Western Province - ALL MENTIONS": 1,
      "6. Southern Province - ALL MENTIONS": 1,
      "6. No ADDT'L PROVINCES - JUST THE PRIMARY": 0,
      "7. What district do you primarily operate in?": "Gasabo",
      "8. What other districts do you cover?": "Gasabo Kicukiro Nyarugenge Bugesera Gatsibo Ngoma Nyagatare Rwagamana Gicumbi Musanze Ngorero Nyabihu Rubavu Rutsiro Huye Kamonyi Muhanga Nyamagabe Nyanza Ruhango",
      "8. What other districts do you cover?/Gasabo": 1,
      "8. What other districts do you cover?/Kicukiro": 1,
      "8. What other districts do you cover?/Nyarugenge": 1,
      "8. What other districts do you cover?/Bugesera": 1,
      "8. What other districts do you cover?/Gatsibo": 1,
      "8. What other districts do you cover?/Kayonza": 0,
      "8. What other districts do you cover?/Kirehe": 0,
      "8. What other districts do you cover?/Ngoma": 1,
      "8. What other districts do you cover?/Nyagatare": 1,
      "8. What other districts do you cover?/Rwagamana": 1,
      "8. What other districts do you cover?/Burera": 0,
      "8. What other districts do you cover?/Gicumbi": 1,
      "8. What other districts do you cover?/Gakenke": 0,
      "8. What other districts do you cover?/Musanze": 1,
      "8. What other districts do you cover?/Rurindo": 0,
      "8. What other districts do you cover?/Karongi": 0,
      "8. What other districts do you cover?/Ngorero": 1,
      "8. What other districts do you cover?/Nyabihu": 1,
      "8. What other districts do you cover?/Nyamasheke": 0,
      "8. What other districts do you cover?/Rubavu": 1,
      "8. What other districts do you cover?/Rutsiro": 1,
      "8. What other districts do you cover?/Rusizi": 0,
      "8. What other districts do you cover?/Gisagara": 0,
      "8. What other districts do you cover?/Huye": 1,
      "8. What other districts do you cover?/Kamonyi": 1,
      "8. What other districts do you cover?/Muhanga": 1,
      "8. What other districts do you cover?/Nyamagabe": 1,
      "8. What other districts do you cover?/Nyanza": 1,
      "8. What other districts do you cover?/Nyaruguru": 0,
      "8. What other districts do you cover?/Ruhango": 1,
      "8. What other districts do you cover?/None-Only operate in primary district mentioned in Q.7": 0,
      "9. What is the primary mission of your organization?": "Is to follow our lord and Savior Jesus Christ in working with the poor and oppressed to promote humans transformation, seeking justice and bear witness to the good news of the Kingdom of God",
      "10. What type of mental health services does your organization provide? ": "h. Patient Advocacy i. Supportive Services (housing, economic/employment, etc.)",
      "10. Promotive: Promoting mental health awareness and mental well being ": 0,
      "10. Preventive: Prevention and intervention programs (i.e. reducing levels of anxiety, therapeutic listening, etc.)": 0,
      "10. Curative: Treating mental health conditions ": 0,
      "10. Palliative: Providing patients with other supportive care that does not necessarily address the cause of mental health conditions. It is often used in severe persistent mental illness to reduce harm and avoid burdensome psychiatric interventions.": 0,
      "10. Rehabilitative: Providing short- or long-term support for patients to improve their mental health wellness before returning home/ to society": 0,
      "10. Training: Educating other mental health workers": 0,
      "10. Empowerment/ Self-Help": 0,
      "10. Patient advocacy": 1,
      "10. Supportive Services (housing, economic/ employment, etc.)": 1,
      "10. Other, please explain": 0,
      "10. Other, please explain": null,
      "11. What month and year was your organization founded?": "1994-08-01",
      "12. How would you best describe the setting where you perform mental health services? ": "(2) In community",
      "12. How would you best describe the setting where you perform mental health services? 1) Home visit ": 0,
      "12. How would you best describe the setting where you perform mental health services? (2) In community": 1,
      "12. How would you best describe the setting where you perform mental health services? (3) Your offices": 0,
      "12. How would you best describe the setting where you perform mental health services? (4) Outpatient or Inpatient facility": 0,
      "12. How would you best describe the setting where you perform mental health services? (5) Residential": 0,
      "12. How would you best describe the setting where you perform mental health services? (6) Telemedicine/ telehealth": 0,
      "13. (If Q12=1-5) How would you best describe the type of facility? ": "(3) Other residential treatment facility",
      "13. How would you best describe the type of facility? (1) Community mental health center": 0,
      "13. How would you best describe the type of facility? (2) Multi-setting mental health facility": 0,
      "13. How would you best describe the type of facility? (3) Other residential treatment facility": 1,
      "13. How would you best describe the type of facility? (4) Partial hospitalization/day treatment": 0,
      "13. How would you best describe the type of facility? (5) Psychiatric hospitalization or psychiatric unit of a general hospital": 0,
      "13. How would you best describe the type of facility? (6) Residential treatment center for adults": 0,
      "13. How would you best describe the type of facility? (7) Residential treatment center for children": 0,
      "13. How would you best describe the type of facility?  (9) Training center": 0,
      "13. How would you best describe the type of facility?  (10) Home/ community based": 0,
      "13. How would you best describe the type of facility? (8) Other, please indicate": 0,
      "13. (Other, please indicate)": null,
      "14. Is your organization a public private organization, or an NGO?": "(3) NGO ",
      "15. Does your organization have an existing affiliation or relationship with a particular university, health center or hospital (district, provincial or referral)? If so, which one (s) and please characterize the relationship.": "1. The University of Rwanda. Research in Health and Internship for Final Year students doing their final projects. 2. The University of North Caroline, Drexel USA. They help them through capacity building in Health and WASH programs. 3. MOU with the ministry of health when there is a need to build a hospital or a health center",
      "15. Hospitals (non specific) (1)": 0,
      "15.Ndera/ referral hospitals (2)": 0,
      "15.Provincial hospitals (3)": 0,
      "15.District hospitals (4)": 0,
      "15.Health Centers (5)": 0,
      "15.Health posts (6)": 0,
      "15.University (8)": 1,
      "15.No Affiliation (9)": 0,
      "15.Other, specify (OE)": "ministry of health",
      "16. How many total people including volunteers does your organization employ?": 350,
      "(Total employees & volunteers coded)": 5,
      "17. % Full time ": 100,
      "17. # Full time (Calculated)": 350,
      "17. (# Full time employees coded)": 5,
      "17. % Part time": 0,
      "17. % Volunteers": 0,
      "18. Of your total staff, how many are health care workers?": 12,
      "18. (# health care workers coded)": 2,
      "19. How many total people does your organization currently provide mental health services for?": 0,
      "19. How many total people does your organization currently provide mental health services for? (Coded)": 0,
      "20. What populations does your institution primarily serve with mental health services?": "(19)  Other, please specify",
      "20. What populations does your institution primarily serve with mental health services?/(1) Transition age young adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(2) Seniors or older adults": "0",
      "20. What populations does your institution primarily serve with mental health services?/(3) Mothers needing mental health services/support": "0",
      "20. What populations does your institution primarily serve with mental health services?/(4) Domestic Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(5) Sexual Abuse Survivors": "0",
      "20. What populations does your institution primarily serve with mental health services?/(6) Clients referred from the court/judicial system": "0",
      "20. What populations does your institution primarily serve with mental health services?/(7) Persons with comorbid mental and substance use disorders": "0",
      "20. What populations does your institution primarily serve with mental health services?/(8) Persons with HIV or AIDS": "0",
      "20. What populations does your institution primarily serve with mental health services?/(9) Persons who have experienced trauma": "0",
      "20. What populations does your institution primarily serve with mental health services?/(10)  Persons with traumatic brain injury": "0",
      "20. What populations does your institution primarily serve with mental health services?/(11)  Orphans": "0",
      "20. What populations does your institution primarily serve with mental health services?/(12)  Refugees": "0",
      "20. What populations does your institution primarily serve with mental health services?/(13)  Persons with Alzheimer’s or dementia": "0",
      "20. What populations does your institution primarily serve with mental health services?/(14)  Persons with post-traumatic stress disorder": "0",
      "20. What populations does your institution primarily serve with mental health services?/(15)  Children /adolescents with serious emotional disturbance": "0",
      "20. What populations does your institution primarily serve with mental health services?/(16) Persons with serious mental illness": "0",
      "20. What populations does your institution primarily serve with mental health services?/(17)  Persons with epilepsy": "0",
      "20. What populations does your institution primarily serve with mental health services?/18)  Ex-drug users ": "0",
      "20. What populations does your institution primarily serve with mental health services?/(19)  Other, please specify": "1",
      "20. (Other, please specify)": "They deal with Health in general not specific in mental health",
      "21. What types of mental health concerns does your organization address? ": "(12)  Other, please specify",
      "21. Anxiety disorders, including panic disorder, obsessive-compulsive disorder, and phobias": "0",
      "21. Depression, bipolar disorder, and other mood disorders": "0",
      "21. Eating disorders": "0",
      "21. Personality disorders": "0",
      "21. Post-traumatic stress disorder/Genocide-related MH disorders": "0",
      "21. Suicide ": "0",
      "21. Psychosomatic problems": "0",
      "21. Psychotic disorders, including schizophrenia": "0",
      "21. Substance abuse disorders": "0",
      "21. Neurological problems": "0",
      "21. Persons with epilepsy": "0",
      "21. Other, please specify": "1",
      "21. Other, please specify": "In General not specific signs",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Short Term": "0",
      "22. Of your total population suffering from mental disorders, what is the split between people with acute (short-term) mental health concerns vs. long-term illness? Long Term": "0",
      "23. Is there occasionally a difference between the issue/symptom/or complaints that your population with mental health concerns say they suffer from versus what they get treated for? ": "No",
      "24. (If yes in Q23) What is the most common issue or symptom that people with mental health concerns say they are suffering from versus what are they actually treated for? ": null,
      "24. Headache/ stomachache": 0,
      "24. Lack of apetite/ fatigue/ stress": 0,
      "24. Insomnia/ nightmares": 0,
      "24. Insecurity/ fear/ paranoia": 0,
      "24. Depression/ axiety/ trauma/ anger": 0,
      "24. Hallucinations/ loss of memory/ demetia": 0,
      "24. Convulsion/ fainting": 0,
      "24. Body pains": 0,
      "24. Other (specify)": 0,
      "25. What is the split of your population suffering from mental health disorders by gender? % MH Male": "0",
      "25. What is the split of your population suffering from mental health disorders by gender? % Female": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 0-17": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 18-49": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 50-64": "0",
      "26. What is the split of your population suffering from mental health disorders by age groups? 65+": "0",
      "27. Do you have treatment/program protocols or guidelines in place for your mental health related programs and if so, please characterize them?": "N/A",
      "27. Ndera protocols": 0,
      "27. Government/ MoH/ RBC Portocol": 0,
      "27. WHO Protocols": 0,
      "27. Psychologist developed protocols": 0,
      "27. ARCR EHUKA Protocols": 0,
      "27. No protocols": 1,
      "27. Other": 0,
      "28. What metrics does your organization use to measure success for your mental health programs?": "N/A",
      "28. Clnical/ psycho-social assessment": 0,
      "28. Monitoring /follow up/ observation/ evaluation": 0,
      "28. Behavior/ attitude change": 0,
      "28. Score card/ questionnaire/ progress report": 0,
      "28. Testimonies/ interviews/ family and community reports": 0,
      "28. Other": 0,
      "29. What other services does your organization offer? (Please read aloud each service one at a time and ask if they have each of the following services)": "(3) Case management (11)  Legal Advocacy",
      "29. Assertive community treatment": "0",
      "29. Chronic disease/ illness management": "0",
      "29. Case management": "1",
      "29. Intensive case management": "0",
      "29. Court-ordered outpatient treatment": "0",
      "29. Diet and exercise counseling": "0",
      "29. Education services": "0",
      "29. Family psycho education": "0",
      "29.Housing services": "0",
      "29.Illness management and recovery": "0",
      "29. Legal Advocacy": "1",
      "29. Peer-support groups": "0",
      "29. Psychosocial rehabilitation services": "0",
      "29. Supported employment": "0",
      "29. Suicide prevention services": "0",
      "29. Therapeutic foster care": "0",
      "29. Vocational rehabilitation services": "0",
      "29. Other, please specify": "0",
      "29. Other, please specify": null,
      "30. (If yes for Q29-11, ask, please characterize the legal advocacy support that you offer?": "They do provide their own lawyers to assist the beneficiaries for free in legal matters.",
      "30. Advocate change of laws re: substance control": 0,
      "30. Advocate against violance and sexual abuse": 0,
      "30. Advocate for, educate and support vulnerable people's rights (People with MHI, children, the poor etc.)": 0,
      "30. Hire/pay lawyers for victims/ assist victims in court": 1,
      "30. Other": 0,
      "31. Please characterize how your organization is funded? % Government": 0,
      "31. Please characterize how your organization is funded? % Private Donations": 70,
      "31. Please characterize how your organization is funded? % NGOs": 30,
      "31. Please characterize how your organization is funded?  % Self-funded/ fees/ membership": 0,
      "31. Please characterize how your organization is funded? % Other": 0,
      "31. Explanations for funding": "30. USAID, UNHCR, UNICEF",
      "32. Do you provide payment assistance for your patients to access other mental health services with other organizations? If so, please characterize.": "NO",
      "32. Medical/Health Insurance": 0,
      "32. Medicine": 0,
      "32. Transport fees": 0,
      "32. Food": 0,
      "32. Hospital fees": 0,
      "32. Unspecified": 0,
      "32. No ": 1,
      "32. Other": 0,
      "33. How long will the funded project last?": "USAID in 2023 and UNHCR in 2020 but renews every year",
      "33. Self-funded": 0,
      "Faith based": 0,
      "33. Funded through service fees/ rent": 0,
      "No longer funded": 0,
      " >5 years / renewable/ continuouse funding": 0,
      "Funded for 1-5 years": 1,
      "Funded for 0-1 year": 0,
      "Other": 0,
      "34. If any, please characterize what constraints you have as an organization to deliver services as optimally as you would like to?": "No technical experts in mental health",
      "34. Limited resources/ funding": 0,
      "34. Limited tools/ lack of knowledge": 0,
      "34. Lack of infrustructure (office) and logistic support": 0,
      "34. Lack of experts in MH/ trained employees/ volunteers/ counselors/ staff": 1,
      "34. Lack of awareness/ social/ community support": 0,
      "34. Other": 0,
      "35. What are the primary barriers that you see your patients experience accessing your organization’s mental health related services? (Please read aloud each of the following, have them indicate all the apply)": "7. Other,  please specify",
      "35. Transportation": "0",
      "35. Financial": "0",
      "35. Awareness of your organization services": "0",
      "35. Awareness and understanding of Mental Health Illnesses and symptoms": "0",
      "35. Preference for alternative services such as those provided by Traditional Healers or Spiritual Leaders": "1",
      "35. Poor perceptions or fear of Mental Health Services / Organizations": "1",
      "35.  Other": "1",
      "Other,  please specify": "Mistreatment and Family violence",
      "36. What are your organizations’ primary unmet needs for mental health services?": "1. Additional staff 2. Additional training 3. Additional space to administer mental health services 4. Supplies 5. Community awareness for your services 6. Other,  please specify",
      "36. Additional staff": "1",
      "36. Additional training": "1",
      "36. Additional space to administer mental health services": "1",
      "36. Supplies": "1",
      "36. Community awareness for your services": "1",
      "36. Other": "1",
      "36. Other,  please specify": "Professional Supervision",
      "37. What is the primary source by which people seeking mental health services find out about your services?": "1) Direct outreach and awareness programs by your organization including advertisements 2) Recommendations from community health workers  5) Word of mouth / recommendations in the community  6) Other, please specify___________",
      "37. Direct outreach and awareness programs by your organization including advertisements": "1",
      "37. Recommendations from community health workers ": "1",
      "37. Recommendations from Traditional Leaders": "0",
      "37. Recommendations from Spiritual Leaders": "0",
      "37. Word of mouth/ recommendations in the community ": "1",
      "37.Other": "1",
      "37. Other, please specify": "We provide brochures during conferences and meetings",
      "38. If at all, please characterize your organizations’ interactions with Traditional Healers?": "NA",
      "38. Meeting/ training with Traditional Healers": 0,
      "38. NO interaction with Traditional Healers": 1,
      "39. If at all, please characterize your organizations’ interactions with Spiritual Healers?": "NA",
      "39. Meeting/ training with Spiritual Healers on MHI": 0,
      "39. Involve/ work with SH/ Churches": 0,
      "39. NO interaction with Spiritual Healers": 1,
      "40. If at all, please characterize your organizations’ interactions with Community Health Workers?": "NA",
      "40. Provide training to CHW ": 0,
      "40. Work with CHW on MH awarenesss/ advocacy/ referral / follow up": 0,
      "40. Does not work with CHW": 1,
      "1.  What is the name and address of your organization?": "WORLD VISION INTERNATIONAL Rwanda",
      " 2. Which of the following best describes your organization?": " An international organization providing  mental health services in the community",
      " 2. Which of the following best describes your organization?/A local organization providing mental health services to beneficiaries in Rwanda": "An international organization funding a local organization or institution to provide mental health services in the community. ",
      "5. Which of the following best describes the geography of your organization’s operations?": "In addition to the headquarter/ main office, we have satellite offices in other regions with employees or volunteers",
      "6. In which regions does your organization have satellite offices?": "Kigali  Southern/ Districts Western/Districts  Northern/ Districts Eastern/ Districts ",
      "7. In which districts do you have offices in Kigali?": "Kicukiro",
      "8. In which districts do you have offices in the southern province?": "Nyamagabe /Gisagara/ Huye ",
      "9. In which districts do you have offices in the western province?": "Karongi/ Ngororero /Nyamasheke/ Rusizi/ Rutsiro",
      " 10. In which districts do you have offices in the northern province?": "Gicumbi /Rulindo/ Gakenke ",
      "11. In which districts do you have offices in the Eastern province?": "Gatsibo/ Kayonza",
      "12. What is your website?": "www.wvi.org › rwanda",
      "13. What is your phone number?": "+250786110533",
      "14. Names of the contact persons": "Jemima Gatete",
      "15. Email address of contact persons": "jemima_gatete@wvi.org"
    }
  ]


	const [currentOrganition, setCurrentOrganition] = useState(data[0])


	const selectNewCurrent = (i) => {
		console.log(i)
		setCurrentOrganition(el => ({ ...data[i] }))
		console.log(data[i])
	}

	return (
		<>
			<Header />
			<div className="page-content bg-white" style={{ marginTop: 60 }}>
				<section className="section-area section-sp1">
					<div className="container no-padding-on-mobile-only">
						<div className="row" style={{marginRight: 0,}}>
							<div className="col-lg-4">
								<aside className="sticky-top pb-1">
									<div className="widget">

										<h5 className="title-head">Information about other mental health institutions in Rwanda</h5>
										<span><u>Courtes</u>y: <a href='https://www.rbc.gov.rw/index.php?id=188' target="_blank">Rwanda Biomedical Centre</a></span>
										<ul className="service-menu">
											{
												dataTitles?.map((el, index) =>
													<li onClick={() => selectNewCurrent(index)}><Link to="#"><span style={{textTransform: "uppercase"}}>{el.name}</span></Link></li>
												)
											}
										</ul>
									</div>
								</aside>
							</div>

							<div className="col-lg-8 mb-30" style={{ padding: 0, paddingLeft: 20}}>
								<div className="post-media">
								</div>
								<div className="clearfix">
									<div className="head-text mb-30">
										<h2 className="title mb-15" style={{textTransform: "uppercase"}}>{currentOrganition["Organization Name"]}</h2>
										<p style={{textAlign: "center"}}>
											<span>Information courtesy of <a href='https://www.rbc.gov.rw/index.php?id=188' target="_blank">Rwanda Biomedical Centre</a></span>
										
										</p>
									</div>
								</div>

								<div className='tableName'>

									<div className='tableRow' style={{background: "#C0C0C0"}}>
										 <div className='titles'>
											<b>Title</b>
</div>
										<div className='dataParty'>
											<b>Description</b>
										</div>
									</div>

									<div className='tableRow'>
										 <div className='titles'>
<i>
											Category (RBC)
										</i>
</div>
										<div className='dataParty'>
											{currentOrganition["Category (RBC)"]}
										</div>
									</div>

									<div className='tableRow'>
										 <div className='titles'>
<i>
											Core business/Basis for categorisation
										</i>
</div>
										<div className='dataParty'>
											{currentOrganition["Core business  / Basis for categorisation "]}
										</div>
									</div>

									<div className='tableRow'>
										 <div className='titles'>
<i>
											Category (PVP)
										</i>
</div>
										<div className='dataParty'>
											{currentOrganition["Category (PVP)"]}
										</div>
									</div>
									<div className='tableRow'>
										 <div className='titles'>
<i>
											What type of mental health services does your organization provide? - MH & Psychosocial Services
											
										</i>
</div>
										<div className='dataParty'>
											{currentOrganition["10. What type of mental health services does your organization provide?  - MH & Psychosocial Services"]}
										</div>
									</div>

									<div className='tableRow'>
										 <div className='titles'>
<i>
											What is your organization’s location&street address?
										</i>
</div>
										<div className='dataParty'>
											{currentOrganition["2. What is your organization’s street address?"]}
										</div>
									</div>

									<div className='tableRow'>
										<div className='titles'>
                      <i>
                        What is the mailing address of your organization (if different than Q2)?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["4. What is the mailing address of your organization (if different than Q2)?"]}
										</div>
									</div>
									<div className='tableRow'>
									  <div className='titles'>
                      <i>
                        What type of mental health services does your organization provide?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["10. What type of mental health services does your organization provide?  - All Other Services"]}
										</div>
									</div>

									<div className='tableRow'>
										<div className='titles'>
                      <i>
                        How would you best describe the setting where you perform mental health services?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["12. How would you best describe the setting where you perform mental health services? "]}
										</div>
									</div>

									<div className='tableRow'>
										<div className='titles'>
                      <i>
                        Is your organization a public private organization, or an NGO?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["14. Is your organization a public private organization, or an NGO?"]}
										</div>
									</div>
									<div className='tableRow'>
										<div className='titles'>
                      <i>
                        What types of mental health concerns does your organization address?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["21. What types of mental health concerns does your organization address? "]}
										</div>
									</div>

									<div className='tableRow'>
										<div className='titles'>
                      <i>
                        What is the name and address of your organization?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["1.  What is the name and address of your organization?"]}
										</div>
									</div>

									<div className='tableRow'>
										 <div className='titles'>
                      <i>
                        What is your website?
                      </i>
                    </div>
										<div className='dataParty'>
											
											<a target="_blank" href={"//" + currentOrganition["12. What is your website?"]}>{currentOrganition["12. What is your website?"]}</a>
										</div>
									</div>

									<div className='tableRow'>
										<div className='titles'>
                      <i>
                        What is your phone number?
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["13. What is your phone number?"]}
										</div>
									</div>
									<div className='tableRow'>
										 <div className='titles'>
                      <i>
                        Email address of contact persons
                      </i>
                    </div>
										<div className='dataParty'>
											{currentOrganition["15. Email address of contact persons"]}
										</div>
									</div>

								</div>




							</div>
						</div>
					</div>
				</section>
			</div >
			<Footer />
		</>

	);
}

export default ServiceDetail;
